import { connect } from 'react-redux';
import OrderHistory from "../";
import {clearOrders, closeModal, receiveOrders} from "modules/MyAccount/scenes/OrderHistory/actions/index";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveOrders: orders => dispatch(receiveOrders(orders)),
    clearOrders: () => dispatch(clearOrders()),
    closeModal: name => dispatch(closeModal(name))
  };
};

const OrderHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistory);

export default OrderHistoryContainer;