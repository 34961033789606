import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export default function ScrollToTop() {
  const location = useLocation();

  /**
   * Basically, scroll whenever the pathname changes.
   * Doesn't do it if the search query does.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}