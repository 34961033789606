import { connect } from 'react-redux';
import {receiveCategories, receiveTypes} from "components/Header/components/HeaderCategoriesDropdown/actions";
import HeaderCategoriesDropdown from "components/Header/components/HeaderCategoriesDropdown/index";

const mapStateToProps = (state) => {
  return {
    headerCategoriesReducer: state.headerCategoriesReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCategories: (categories) => dispatch(receiveCategories(categories)),
    receiveTypes: (types) => dispatch(receiveTypes(types))
  };
};

const HeaderCategoriesDropdownContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderCategoriesDropdown);

export default HeaderCategoriesDropdownContainer;