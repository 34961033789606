/* global dataLayer */

import React, {useEffect} from "react";
import LoadingCassette from "components/LoadingCassette";
import {PRODUCTS_LIST_AS_LIST} from "modules/store/scenes/ProductsList/services/products-interface/constants";
import Product from "modules/store/scenes/ProductsList/components/Product";
import {
  PRICE_GTE,
  PRICE_LTE,
  PRICE_NEW_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_GTE,
  PRICE_USED_LTE,
  QUERY_PARAM_CAST,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_FORMAT,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_GENRES,
  QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_NAME, QUERY_PARAM_ORDERING,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PAGE_SIZE,
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_PRODUCT_CONDITION, QUERY_PARAM_SEARCH,
  QUERY_PARAM_SKU, QUERY_PARAM_STORES,
  VIEW_TYPE
} from "modules/store/scenes/ProductsList/constants/query-params";
import vintageAxios from "services/api";
import {getPageSize} from "services/pagination";
import {useLocation} from "react-router-dom";
import QueryParams from "utils/QueryParams";
import {usePaginatedQuery} from "react-query"

export default function ProductsGrid({viewAs, setProductsCount}) {
  const location = useLocation();
  const queryParams = new QueryParams(location.search)
  const page = queryParams.find(QUERY_PARAM_PAGE) ? queryParams.find(QUERY_PARAM_PAGE) : 1
  let paginatedQueryParamsData = axiosQueryParams()
  if (paginatedQueryParamsData[QUERY_PARAM_PAGE])
    delete paginatedQueryParamsData[QUERY_PARAM_PAGE]
  const {
    isLoading,
    isError,
    resolvedData,
    latestData,
  } = usePaginatedQuery(['productsList', page, paginatedQueryParamsData], () =>
      vintageAxios.get('/products/all/', {params: axiosQueryParams()})
          .then(response => response.data)
  )

  /**
   * After data is fetched, let's setup some important state variables
   */
  useEffect(() => {
    if (!isLoading && !isError && resolvedData) {
      setProductsCount(resolvedData.count)
      dataLayer.push({
        "event": "productLoad",
        "ecommerce": {
          "currencyCode": "USD",
          "impressions": resolvedData.results.map(item => ({
            "id": item.sku,    //internal id or SKU
            "name": item.name,    //you should have at least Name and ID set up
            "list": "Products"  //custom name for the list of products
          }))
        }
      })
    }
  }, [isLoading, isError, resolvedData, setProductsCount])

  function axiosQueryParams() {
    const parsedParams = queryParams.asObj;
    let defaultQueryParams = {};
    const mediaType = parsedParams[QUERY_PARAM_MEDIA_TYPE];
    const categories = parsedParams[QUERY_PARAM_CATEGORIES];

    defaultQueryParams[QUERY_PARAM_PAGE] = parsedParams[QUERY_PARAM_PAGE];
    defaultQueryParams[QUERY_PARAM_SEARCH] = parsedParams[QUERY_PARAM_SEARCH];
    defaultQueryParams[QUERY_PARAM_ORDERING] = parsedParams[QUERY_PARAM_ORDERING];
    defaultQueryParams[QUERY_PARAM_PAGE_SIZE] = getPageSize();
    defaultQueryParams[QUERY_PARAM_CATEGORIES] = categories;
    defaultQueryParams[QUERY_PARAM_DESCRIPTION] = parsedParams[QUERY_PARAM_DESCRIPTION];
    defaultQueryParams[QUERY_PARAM_NAME] = parsedParams[QUERY_PARAM_NAME];
    defaultQueryParams[QUERY_PARAM_FORMAT] = parsedParams[QUERY_PARAM_FORMAT];
    defaultQueryParams[QUERY_PARAM_ESRB_RATING] = parsedParams[QUERY_PARAM_ESRB_RATING];
    defaultQueryParams[QUERY_PARAM_MPAA_RATING] = parsedParams[QUERY_PARAM_MPAA_RATING];
    defaultQueryParams[QUERY_PARAM_SKU] = parsedParams[QUERY_PARAM_SKU];
    defaultQueryParams[QUERY_PARAM_CAST] = parsedParams[QUERY_PARAM_CAST];
    defaultQueryParams[QUERY_PARAM_IN_STOCK] = parsedParams[QUERY_PARAM_IN_STOCK];
    defaultQueryParams[QUERY_PARAM_PICKUP_AVAILABLE] = parsedParams[QUERY_PARAM_PICKUP_AVAILABLE];

    defaultQueryParams[QUERY_PARAM_GENRES] = parsedParams[QUERY_PARAM_GENRES];
    defaultQueryParams[VIEW_TYPE] = parsedParams[VIEW_TYPE];
    defaultQueryParams[QUERY_PARAM_PRODUCT_CONDITION] = parsedParams[QUERY_PARAM_PRODUCT_CONDITION];
    defaultQueryParams[QUERY_PARAM_GAMING_PLATFORM] = parsedParams[QUERY_PARAM_GAMING_PLATFORM];
    defaultQueryParams[QUERY_PARAM_STORES] = parsedParams[QUERY_PARAM_STORES];

    // Why the media type reference to categories (DVD, Blu-ray, Games, etc)
    if (mediaType)
      defaultQueryParams[QUERY_PARAM_CATEGORIES] = [
        ...Array.isArray(mediaType) ? mediaType : [mediaType],
        categories
      ];

    if (parsedParams[PRICE_NEW_GTE])
      defaultQueryParams[PRICE_NEW_GTE] = parsedParams[PRICE_NEW_GTE];
    if (parsedParams[PRICE_USED_GTE])
      defaultQueryParams[PRICE_USED_GTE] = parseFloat(parsedParams[PRICE_USED_GTE]);
    if (parsedParams[PRICE_NEW_LTE])
      defaultQueryParams[PRICE_NEW_LTE] = parseFloat(parsedParams[PRICE_NEW_LTE]);
    if (parsedParams[PRICE_USED_LTE])
      defaultQueryParams[PRICE_USED_LTE] = parseFloat(parsedParams[PRICE_USED_LTE]);
    if (parsedParams[PRICE_GTE])
      defaultQueryParams[PRICE_GTE] = parsedParams[PRICE_GTE];
    if (parsedParams[PRICE_LTE])
      defaultQueryParams[PRICE_LTE] = parsedParams[PRICE_LTE];

    return defaultQueryParams;
  }

  return (
      <React.Fragment>
        {
          (isLoading || !latestData)
              ?
              <LoadingCassette/>
              :
              (
                  resolvedData.results.length > 0
                      ?
                      (
                          (viewAs && viewAs === PRODUCTS_LIST_AS_LIST)
                              ?
                              resolvedData.results.map((product, index) =>
                                  <Product
                                      key={index}
                                      product={product}
                                      asList/>)
                              :
                              <div className="products-grid">
                                {
                                  resolvedData.results.map((product, index) =>
                                      <React.Fragment key={product.id}>
                                        <div className="products-grid__product">
                                          <Product
                                              product={product}/>
                                        </div>
                                      </React.Fragment>
                                  )
                                }
                              </div>

                      )
                      :
                      (
                          <div className="empty-message search">
                            <h1>No Search Results.</h1>
                            <p>Sorry, we couldn’t find what you were looking for.</p>
                          </div>
                      )
              )
        }
      </React.Fragment>
  );
}