import {Component} from 'react';
import vintageAxios from "services/api/index";
import vintageHistory from "services/browser-history/index";

class OldProductsDetails extends Component {
  componentWillMount() {
    this.redirectToProductDetails();
  }

  redirectToProductDetails() {
    const promise = this.fetchProductBySlug();
    promise.then(response => {
      if (response.data.results.length > 0) {
        const product = response.data.results[0];
        vintageHistory.push(`/store/products/${product.slug}`);
      } else
        vintageHistory.push(`/store/products`);
    });

    promise.catch(err => {
      console.log(err);
      vintageHistory.push(`/store/products`);
    });
  }

  fetchProductBySlug() {
    const {productSlug} = this.props.match.params;
    // Remove .html extension
    const newProductSlug = productSlug.replace('.html', '');

    const queryParams = {
      "view_type": "search-seo",
      "search-seo-terms": newProductSlug
    };

    const promise = vintageAxios.get('/products/all/', {
      params: queryParams
    });

    return promise;
  }

  render() {
    return null;
  }
}

export default OldProductsDetails;