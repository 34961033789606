import { connect } from 'react-redux';
import AddAddressModal from "../";
import {closeAddressModal, createAddress, editAddress} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account,
    selectedAddress: state.account.pages.addressBook.selectedAddress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAddressModal: () => dispatch(closeAddressModal()),
    createAddress: data => dispatch(createAddress(data)),
    editAddress: data => dispatch(editAddress(data))
  };
};

const AddAddressModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAddressModal);

export default AddAddressModalContainer;