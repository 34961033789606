import React from 'react';
import "./styles/ProductsFiltersBar.scss";
import classNames from "classnames/bind";
import {
  QUERY_PARAM_LIMIT,
  QUERY_PARAM_ORDERING,
  QUERY_PARAM_PAGE,
  QUERY_PARAM_PRODUCT_CONDITION
} from "modules/store/scenes/ProductsList/constants/query-params";
import {getPageSize, savePageSize} from "services/pagination";
import {saveProductsViewAs} from "modules/store/scenes/ProductsList/services/products-interface";
import {
  PRODUCTS_LIST_AS_GRID,
  PRODUCTS_LIST_AS_LIST
} from "modules/store/scenes/ProductsList/services/products-interface/constants";
import PaginationFilterContainer
  from "modules/store/scenes/ProductsList/components/ProductsFiltersBar/components/PaginationFilter/container/index";
import {useHistory, useLocation} from "react-router-dom";
import QueryParams from "utils/QueryParams";
import {parseCondition} from "utils/products"

export default function ProductsFiltersBar({productsCount, productsList, toggleProductsViewAs}) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const sortBy = queryParams.find(QUERY_PARAM_ORDERING) || '';
  const pageSize = queryParams.find(QUERY_PARAM_LIMIT) || getPageSize();

  /**
   * Handles changes on the sort by <select>
   * @param {Object} event
   */
  function handleSortBySelectChange(event) {
    const {value} = event.target;
    queryParams.update({
      [QUERY_PARAM_ORDERING]: value || null,  // will be null if the value is an empty string
      [QUERY_PARAM_PAGE]: null,  // reset pagination
      [QUERY_PARAM_PRODUCT_CONDITION]: parseCondition(queryParams.find(QUERY_PARAM_PRODUCT_CONDITION))
    });
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    });
  }

  /**
   * Handles changes on the page size <select>
   * @param {Object} event
   */
  function handlePageSizeSelectChange(event) {
    const value = parseInt(event.target.value, 10);
    savePageSize(value);  // store in local storage
    queryParams.update({
      [QUERY_PARAM_LIMIT]: value,
      [QUERY_PARAM_PAGE]: null  // reset pagination to first one
    });
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    });
  }

  /**
   * Handles clicks on the view as buttons
   * @param {String} viewAs
   * @private
   */
  function handleViewAsButtonClick(viewAs) {
    saveProductsViewAs(viewAs);  // save in local storage
    toggleProductsViewAs(viewAs);
  }

  return (
      <div className="products-filters-bar">
        <div className="form-inline">
          <div className="form-group products-filter products-filter--sort-by">
            <label htmlFor="sortBy">Sort by:</label>
            <br className="sm-only"/>
            <select
                id="sortBy"
                value={sortBy}
                onChange={handleSortBySelectChange}
                className="form-control">
              <option value="">---</option>
              <option value="-created_at">Recent first</option>
              <option value="name">Name (asc)</option>
              <option value="-name">Name (desc)</option>
              <option value="release_date">Released (asc)</option>
              <option value="-release_date">Released (desc)</option>
              <option value="price_new">Price new (asc)</option>
              <option value="-price_new">Price new (desc)</option>
              <option value="price_used">Price used (asc)</option>
              <option value="-price_used">Price used (desc)</option>
            </select>
          </div>
          <div className="form-group products-filter products-filter--separator"/>
          <div className="form-group products-filter products-filter--view-as">
            <label htmlFor="viewAs">View as:</label>
            <br className="sm-only"/>
            <button
                type="button"
                className={classNames({
                  "btn": true,
                  "btn--active": productsList.viewAs === PRODUCTS_LIST_AS_GRID
                })}
                onClick={() => handleViewAsButtonClick(PRODUCTS_LIST_AS_GRID)}>
              <span className="icon icon-grid"/>
            </button>
            <button
                type="button"
                className={classNames({
                  "btn": true,
                  "btn--active": productsList.viewAs === PRODUCTS_LIST_AS_LIST
                })}
                onClick={() => handleViewAsButtonClick(PRODUCTS_LIST_AS_LIST)}>
              <span className="icon icon-list"/>
            </button>
          </div>
          <div className="form-group products-filter products-filter--separator"/>
          <div className="form-group products-filter products-filter--items-per-page">
            <label htmlFor="sortBy">Show:</label>
            <br className="sm-only"/>
            <select
                id="pageSize"
                value={pageSize}
                onChange={handlePageSizeSelectChange}
                className="form-control">
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <label className="hidden-sm">per page</label>
          </div>
          <div className="form-group products-filter products-filter--separator"/>
          <div className="form-group products-filter products-filter--pagination">
            <label>
              <strong>{productsCount || 0}</strong> items
            </label>
            <br className="sm-only"/>
            <PaginationFilterContainer/>
          </div>
        </div>
      </div>
  )
}