import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import ContactFormContainer from "./components/ContactForm/container";
import VintageAlert from "utils/components/VintageAlert/index";
import PropTypes from 'prop-types';
import "./styles/Contact.scss";
import SEO from "components/SEO/index";
import {Link} from "react-router-dom";

const Contact = ({createContactMessage, contactUs}) => {

  const handleFormSubmit = data => {
    if (data.message_type !== 'store' && data.store)
      delete data.store;

    createContactMessage(data)
  };

  return (
    <div>
      <section className="title">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Contact Us!</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row">
                  <div className="col-md-6">
                    {
                      contactUs.success ? (
                          <VintageAlert>
                            <p>Thanks for contacting us, your message has been sent.</p>
                            <p>We will get in touch with you as soon as possible.</p>
                          </VintageAlert>
                      ) : (
                          <ContactFormContainer
                              onSubmit={handleFormSubmit}
                              isLoading={contactUs.loading}
                              errors={contactUs.errors} id="contact_id"/>
                      )
                    }
                  </div>
                  <div className="col-md-6 text-section">
                    <p>
                      We love and encourage feedback! We strive to answer questions promptly.
                    </p>
                    <p>
                      Find store locations&nbsp;
                      <Link
                        className="here-link"
                        to="/locations">
                      here
                    </Link>.
                    </p>
                    <p>
                      Be sure to check our FAQ section to find answers quickly&nbsp;
                      <Link
                        className="here-link"
                        to="/about/faq">
                        here
                      </Link>.
                    </p>
                    <p>
                      If you are interested in job opportunities, please check&nbsp;
                      <Link
                          className="here-link"
                          to="/about/now-hiring">
                        here
                      </Link>.
                    </p>
                    <p>
                      Thank you,
                    </p>
                    <p>Vintage Stock Team</p>

                    <div className="contact-info">
                      {/*<p className="contact-item">
                        <a href="tel:+14176231550" className="clearfix">
                          <span className="icon icon-phone"/>
                          <span className="text">417-623-1550 ext 203</span>
                        </a>
                      </p>*/}
                      {/*<p className="contact-item">
                        <a href="mailto:support@vintagestock.com" className="clearfix">
                          <span className="icon icon-phone"/>
                          <span className="text">Online Orders - <a href="mailto:support@vintagestock.com">support@vintagestock.com</a></span>
                        </a>
                      </p>*/}
                      <p className="contact-item">
                        <a href="mailto:support@vintagestock.com" className="clearfix">
                          <span className="icon icon-mail"/>
                          <span className="text">support@vintagestock.com</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsletterSubscribeContainer/>
      <SEO url='contact'/>
    </div>
)};

Contact.propTypes = {
  createContactMessage: PropTypes.func.isRequired,
  contactUs: PropTypes.shape({
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired
  })
};

export default Contact;
