import React, {useEffect, useState} from "react"
import vintageAxios from "services/api"

export default function CategoriesSelect({onChange}) {
  const [loading, setLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [categories, setCategories] = useState(null)

  /**
   * Fetch categories the first time
   */
  useEffect(() => {
    if (categories === null)
      fetchCategories()
  }, [categories])

  /**
   * Call onChange callback everytime the selected category changes
   */
  useEffect(() => {
    if (selectedCategory)
      onChange(JSON.parse(selectedCategory))
  }, [selectedCategory])

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  function fetchCategories() {
    setLoading(true)
    const promise = vintageAxios.get('/products/categories/')
    promise.then(({data}) => {
      const categories = data
          .filter(item => item.parent === null || item.parent.name === "Products")
          .sort((a, b) => a.product_type - b.product_type)
          .map(category => {
            if (category.name === "Products")
              category.name = "All"
            return category
          })
      setCategories(categories)
      setSelectedCategory(JSON.stringify(categories[0]))
      setLoading(false)
    })
  }

  /**
   * Handles changes on the <select> and updates selectedCategory with its value.
   * @param {Object} event
   */
  function handleSelectChange(event) {
    const value = event.target.value
    setSelectedCategory(value)
  }

  return (
      <select
          name="category"
          onChange={handleSelectChange}
          value={selectedCategory ? selectedCategory : ""}
          disabled={loading}>
        {
          !loading && categories.map(category => (
              <option
                  key={category.id}
                  value={JSON.stringify(category)}>
                {category.name}
              </option>
          ))
        }
      </select>
  )
}