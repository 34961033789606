import React from 'react';
import {NavLink} from "react-router-dom";
import "./styles/MainHeaderNav.scss";

const MainHeaderNav = props => {
  return (
    <div className="navbar-collapse nav__main-header_container">
      <ul className="nav navbar-nav navbar-right">
        <li className="nav__item">
          <NavLink to="/" activeClassName="active" exact>
            <span className="nav__text">Home</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to="/about" activeClassName="active" exact>
            <span className="nav__text">About</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to="/store/products" activeClassName="active">
            <span className="nav__text">Products</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink to="/contact" activeClassName="active" exact>
            <span className="nav__text">Contact</span>
            <span className="nav__vline"/></NavLink>
        </li>
        <li className="nav__item nav__item_bordered">
          <NavLink to="/locations" activeClassName="active" exact>
            <span className="icon icon-Pin"/>
            <span className="nav__text">Store Locations</span>
            <span className="nav__vline"/>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default MainHeaderNav;
