import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import validate from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/validate";
import {renderCheckbox, renderSelect} from "utils/forms/renderers";
import OrderItemData from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/components/OrderItemData";
import {ORDER_ITEM_RETURN_REASONS} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/return-reasons";
import PropTypes from "prop-types";
import {
  CANCELLED, NORMAL,
  RETURNED, SHIPMENT
} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/order-item-type";
import {MODAL_CANCEL, MODAL_RETURN_ITEMS} from "modules/MyAccount/scenes/OrderHistory/constants/modal-names";
import {ORDER_ITEM_CANCELLATION_REASONS} from "./constants/return-reasons";

const OrderItemList = ({itemList, type}) => {
  return(
      itemList.map((item, index) =>
          <tbody className="readOnly" key={index}>
          <tr>
            <td>
              <div className="custom-checkbox">
                <Field
                    name="check"
                    component={renderCheckbox} disabled={true}
                    readOnly={true}/>
              </div>
            </td>
            <td colSpan="2">
              <OrderItemData
                  type={type}
                  item={item.order_item_data}/>
            </td>
          </tr>
          </tbody>
      )
  );
}

const renderItems = ({ fields, returnItemsForm, order, modalName, meta: { error, submitFailed } }) => {
  const reasons = modalName === MODAL_RETURN_ITEMS ? ORDER_ITEM_RETURN_REASONS : ORDER_ITEM_CANCELLATION_REASONS;
  return (
    <table className="table">
      <thead>
      <tr>
        <th>ITEM</th>
        <th>DESCRIPTION</th>
        <th className="right">AMOUNT</th>
      </tr>
      </thead>
      <OrderItemList
          itemList={order.items.returned}
          type={RETURNED}/>
      <OrderItemList
          itemList={order.items.cancelled}
          type={CANCELLED}/>
      {
        fields.map((member, index) => (
            <tbody key={index}>
            <tr className="table-reason__item">
              <td>
                <div className="custom-checkbox">
                  <Field
                      name={`${member}.will_be_returned`}
                      component={renderCheckbox}/>
                </div>
              </td>
              <td colSpan="2">
                <OrderItemData
                    type={modalName === MODAL_RETURN_ITEMS ? SHIPMENT : NORMAL}
                    item={order.items[modalName === MODAL_RETURN_ITEMS ? SHIPMENT : NORMAL][index].order_item_data}/>
              </td>
            </tr>
            {
              (returnItemsForm && returnItemsForm.values.items[index].will_be_returned) &&
              <tr className="table-reason__row">
                <td colSpan="3">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xs-5">
                        <label htmlFor={`${member}.return_reason`}>
                          What is your reason for&nbsp;
                          {modalName === MODAL_RETURN_ITEMS ? 'returning' : 'canceling'}?
                        </label>
                      </div>
                      <div className="col-xs-7">
                        <Field
                            name={`${member}.return_reason`}
                            component={renderSelect}
                            className="form-control">
                          <option value="">Select an option</option>
                          {
                            reasons.map((reason, index) =>
                                <option
                                    key={`returnReason${index}`}
                                    value={reason[0]}>
                                  {reason[1]}
                                </option>
                            )
                          }
                        </Field>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            }
            </tbody>
        ))
      }
    </table>
)};

const ReturnItemsForm = ({handleSubmit, pristine, reset, submitting, returnItemsForm, orderHistory, modalName, loading, done, closeModal}) => (
    <form onSubmit={handleSubmit}>
      <FieldArray
          name="items"
          component={renderItems}
          // Send returnItemsForm coming from the redux store in order to have context of the value in the
          // will_be_returned field, so we can show and hide the <select> input.
          returnItemsForm={returnItemsForm}
          // Send the selected order from the redux store in order to have information of the items.
          order={orderHistory.selectedOrder} modalName={modalName}/>
      <div className="text-right">
        <button className="btn-custom cancel" type="button" disabled={submitting} onClick={closeModal}>
          Close
        </button>
        <button type="submit" disabled={submitting || loading || done}>
          {
            !loading && !done ? (
                <span>{modalName === MODAL_CANCEL ? 'Cancel ' : 'Return '} items</span>
            ) : (
              loading && !done ?
                  <span>{modalName === MODAL_CANCEL? 'Canceling ': 'Returning '} items ...</span>
              :
                <span>
                  <i className="fa fa-check"/>&nbsp;{modalName === MODAL_CANCEL? 'Canceled ': 'Returned '} items
                </span>
            )
          }
        </button>
      </div>
    </form>
);

ReturnItemsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  done: PropTypes.bool,
  modalName: PropTypes.oneOf([MODAL_CANCEL, MODAL_RETURN_ITEMS])
};

export default reduxForm({
  form: 'returnItemsForm',
  validate
})(ReturnItemsForm)
