import * as types from '../actions/types';
import {
  MODAL_CANCEL,
  MODAL_INVOICE, MODAL_RETURN_ITEMS,
  MODAL_TRACKING
} from "modules/MyAccount/scenes/OrderHistory/constants/modal-names";
import ReturnItemsModalContainer from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/container/index";
import InvoiceModalContainer from "modules/MyAccount/scenes/OrderHistory/components/InvoiceModal/container/index";
import TrackingModalContainer from "modules/MyAccount/scenes/OrderHistory/components/TrackingModal/container/index";
import {
  ORDER_AWAITING_PAYMENT,
  ORDER_CONFIRMED
} from "modules/MyAccount/scenes/OrderHistory/components/Order/constants/status";

export const initialState = {
  orders: null,
  selectedOrder: null,
  modals: [
    {
      name: MODAL_RETURN_ITEMS,
      component: ReturnItemsModalContainer,
      show: false,
      buttonClassName: 'btn-custom cancel',
      props: {
        modalName: MODAL_RETURN_ITEMS
      },
      showButtonIf: []
    },
    {
      name: MODAL_TRACKING,
      component: TrackingModalContainer,
      show: false,
      buttonClassName: 'btn-custom tracking',
      props: {},
      showButtonIf: []
    },
    {
      name: MODAL_INVOICE,
      component: InvoiceModalContainer,
      show: false,
      buttonClassName: 'btn-custom',
      props: {},
      showButtonIf: []
    },
    {
      name: MODAL_CANCEL,
      component: ReturnItemsModalContainer,
      show: false,
      buttonClassName: 'btn-custom cancel',
      props: {
        modalName: MODAL_CANCEL
      },
      showButtonIf: [
        ORDER_AWAITING_PAYMENT,
        ORDER_CONFIRMED
      ]
    },
  ]
};

export const orderHistory = (state = initialState, action) => {
  switch (action.type){
    case types.ORDERS_RECEIVE:
      return {
        ...state,
        orders: action.orders
        /*orders: action.orders.map(order => {
          return {
            ...order,
            order_items: order.order_items.map(item => {
              item.willBeReturned = false;
              item.returnReason = '';
              item.hasReturnError = false;
              return item;
            })
          };
        })*/
      };
    case types.ORDERS_CLEAR:
      return {
        ...state,
        orders: null
      };
    case types.MODAL_OPEN:
      return {
        ...state,
        selectedOrder: action.order,
        modals: state.modals.map(modal => {
          if( modal.name === action.name )
            modal.show = true;
          return modal;
        })
      };
    case types.MODAL_CLOSE:
      return {
        ...state,
        // Here is no need to reset the selectedOrder because will
        // be overridden when MODAL_OPEN is called again.
        selectedOrder: null,
        modals: state.modals.map(modal => {
          if( modal.name === action.name )
            modal.show = false;
          return modal;
        })
      };
    case types.ORDER_ITEM_TOGGLE_RETURN_FLAG:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order_items: state.selectedOrder.order_items.map(item => {
            if( item.id === action.item.id )
              item.willBeReturned = !item.willBeReturned;
            return item;
          })
        }
      };
    case types.SELECTED_ORDER_UPDATE:
      return {
        ...state,
        orders: state.orders.map(item => {
          if( item.id === action.order.id )
            item = action.order;
          return item;
        })
      };
    default:
      return state;
  }
};