import {PAGINATION_DEFAULT_PAGE_SIZE, PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY} from "services/pagination/constants";

/**
 * Stores the page size in local storage.
 * @param {Number} pageSize
 */
export const savePageSize = pageSize => {
  localStorage.setItem(PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY, pageSize);
};

/**
 * Get the page size stored in local storage, fallbacks to default page size.
 * @returns {Number} pageSize
 */
export const getPageSize = () => {
  const userPageSize = localStorage.getItem(PAGINATION_PAGE_SIZE_LOCAL_STORAGE_KEY);
  return userPageSize ? parseInt(userPageSize, 10) : PAGINATION_DEFAULT_PAGE_SIZE;
};