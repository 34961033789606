import {connect} from "react-redux";
import CheckoutShippingAddress from "../index";
import {selectShippingAddress, createAddress} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectShippingAddress: addressBook => dispatch(selectShippingAddress(addressBook)),
    createAddress: data => dispatch(createAddress(data))
  };
};

const CheckoutShippingAddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutShippingAddress);

export default CheckoutShippingAddressContainer;