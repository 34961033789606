import {useHistory} from "react-router"
import useQueryParams from "hooks/useQueryParams"
import {
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_STORES
} from "modules/store/scenes/ProductsList/constants/query-params"
import SelectedStores
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/SelectedStores"
import React from "react"

export default function AvailabilityStores({toggleModal}) {
  const history = useHistory()
  const queryParams = useQueryParams()

  function handleClearBtnClick() {
    // This is resetting all the params, but the problem is not here, queryParams.asSearchString
    // outputs the right value here.
    // TODO fix this problem
    queryParams.update({
      [QUERY_PARAM_STORES]: null
    })
    history.push({
      pathname: "/store/products",
      params: queryParams.asSearchString
    })
  }

  if (!queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE))
    return null

  return (
      <>
        <hr/>
        <p className="stores-title">
          In store pickup at:
          {
            queryParams.find(QUERY_PARAM_STORES) &&
            <button
                type="button"
                className="clear-stores-btn"
                onClick={handleClearBtnClick}>
              Clear
            </button>
          }
        </p>
        <SelectedStores
            emptyComponent={<p><strong>All stores</strong></p>}/>
        <p>
          <button className="choose-your-stores-btn" onClick={toggleModal}>Choose your store</button>
        </p>
      </>
  )
}