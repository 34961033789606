import React from 'react';
import {Link} from "react-router-dom";
import {
  PRODUCT_CATEGORIES
} from "modules/store/scenes/ProductsList/constants/product-categories";
import FooterCategories from "components/Footer/components/FooterCategories/index";
import AppLogo from "components/AppLogo";
import "./styles/Footer.scss";
import TrustElements from "utils/components/TrustElements";
import {shouldDisplayLiteHeader} from "components/Header"
import {useLocation} from "react-router"

const Footer = () => {
  const location = useLocation()
  const liteFooter = shouldDisplayLiteHeader(location.pathname)

  return (
      <footer className="app-footer">
        {
          !liteFooter &&
          <React.Fragment>
            <div className="footer-trust-section">
              <div className="container">
                <TrustElements direction="row"/>
              </div>
            </div>
            <div className="footer-links-section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <p>
                      <AppLogo/>
                    </p>
                    <p>
                      Follow us for deals and promotions
                    </p>
                    <div className="footer-social-links">
                      <a
                          href="https://twitter.com/Vintage_Stock"
                          className="footer-social-link"
                          target="_blank"
                          rel="noopener noreferrer">
                        <span className="footer-social-link__icon footer-social-link__icon--twitter icon-twitter"/>
                      </a>
                      <a
                          href="https://www.facebook.com/VintageStock/"
                          className="footer-social-link"
                          target="_blank"
                          rel="noopener noreferrer">
                        <span className="footer-social-link__icon footer-social-link__icon--facebook icon-facebook"/>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-8 footer__links-list">
                    <div className="row">
                      <div className="col-md-3 col-sm-3">
                        <h6 className="links-category__title">POPULAR PRODUCTS</h6>
                        <FooterCategories
                            categories={PRODUCT_CATEGORIES.slice(0, Math.ceil(PRODUCT_CATEGORIES.length / 2))}/>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <h6 className="links-category__title">&nbsp;</h6>
                        <FooterCategories
                            categories={PRODUCT_CATEGORIES.slice(Math.ceil(PRODUCT_CATEGORIES.length / 2), PRODUCT_CATEGORIES.length)}/>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <h6 className="links-category__title">COMPANY</h6>
                        <ul className="links-category__container">
                          <li><Link to="/about" id="#about">About Us</Link></li>
                          <li><Link to="/contact">Contact Us</Link></li>
                          <li><Link to="/locations">Store Locations</Link></li>
                        </ul>
                      </div>
                      <div className="col-md-3 col-sm-3">
                        <h6 className="links-category__title">CUSTOMER SERVICE</h6>
                        <ul className="links-category__container">
                          <li><Link to="/about/privacy-policy">Privacy Policy</Link></li>
                          <li><Link to="/about/terms-of-use">Terms Of Use</Link></li>
                          <li><Link to="/about/faq">FAQs</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  © {(new Date("2008").getFullYear())}–{new Date().getFullYear()} {process.env.REACT_APP_SITE_NAME}.
                  All Rights Reserved.&nbsp;
                  <a href="https://www.liveventures.com/" target="_blank" rel="noopener noreferrer" style={{color: "white", textDecoration: "underline"}}>A Live Ventures Incorporated Company</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
