import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"

let timeout;

export default function QueryInput({disabled, onChange}) {
  const [query, setQuery] = useState('')

  /**
   * Update search query after some time to prevent making too many requests
   * to the server with every single keypress
   */
  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(
        () => {
          if (query.length > 2)
            onChange(query)
        },
        300)

    return () => clearTimeout(timeout)
  }, [query, onChange])

  return (
      <input
          id="search"
          autoComplete="off"
          className="form-control"
          value={query}
          autoFocus
          disabled={disabled}
          onChange={e => setQuery(e.target.value)}
          placeholder="Enter your Address or Zipcode"/>
  )
}

QueryInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

QueryInput.defaultProps = {
  disabled: false,
}