import React from 'react';
import "./styles/CareerOpportunities.scss";
import SEO from "components/SEO/index";

const CareerOpportunities = () => {
  return(
      <div className="career-opportunities">
        <h1>Career Opportunities</h1>
        <p>
          Want a great job full of fun, tons of opportunities and product discounts?
          Find it here! We're frequently looking for motivated, dynamic employees in all
          areas of our company
        </p>
        <strong>3 easy ways to apply:</strong><br/>
        <ul className="career-opportunities__ways-apply">
          <li>-Download, complete and email your application to <a href="mailto:employment@vintagestock.com"><span
              className="career-opportunities__ways-apply__email">employment@vintagestock.com.</span></a></li>
          <li>-Print, complete and deliver your application directly to the store.</li>
          <li>-Print and fax your application to our corporate office@417-782-0024.</li>
        </ul>
        <p>
          <a
              href="https://media.vintagestock.com/media/employment-application.pdf"
              rel="noopener noreferrer"
              target="_blank">
            <span className="btn-custom">Download Form</span>
          </a>
        </p>
        <SEO url='career-opportunities'/>
      </div>
  );
}

export default CareerOpportunities
