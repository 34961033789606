import { connect } from 'react-redux';
import CartTotals from "../";
import {receiveCartTotals} from "modules/store/actions/index";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCartTotals: totals => dispatch(receiveCartTotals(totals))
  };
};

const CartTotalsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartTotals);

export default CartTotalsContainer;