import React from 'react';
import MainHeaderNav from "components/Header/components/MainHeader/components/MainHeaderNav";

import "./styles/MainHeader.scss";
import AppLogo from "components/AppLogo";

const MainHeader = props => {
  return (
    <nav className="navbar main__header">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-3 col-xs-3">
            <div className="navbar-brand header__logo">
              <AppLogo/>
            </div>
          </div>
          <div className="col-md-8 col-sm-9 col-xs-9">
            <MainHeaderNav/>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainHeader;
