import { connect } from 'react-redux';
import AddressBook from "../";
import {receiveAddresses, openAddressModal} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAddresses: (data) => dispatch(
        receiveAddresses(data)
    ),
    openAddressModal: () => dispatch(
        openAddressModal()
    )
  };
};

const AddressBookContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressBook);

export default AddressBookContainer;