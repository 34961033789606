import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {required} from "utils/forms/validators";
import PropTypes from "prop-types";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import validateResetPasswordForm from "modules/auth/scenes/ResetPassword/components/ResetPasswordForm/validate";
import "./styles/ResetPasswordForm.scss";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export const ResetPasswordForm = ({status, handleSubmit, errors, checkedRecaptcha, afterCheckedRecaptcha, onExpiredRecaptcha}) => (
    <form
        className="form reset-password"
        onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="new_password">Password <span className="required">*</span></label>
        <Field id="new_password"
               name="new_password"
               component={renderInput}
               type="password"
               className="form-control"
               validate={[required]}
               placeholder="Type your password"/>
      </div>
      <div className="form-group">
        <label htmlFor="re_new_password">Confirm password <span className="required">*</span></label>
        <Field id="re_new_password"
               name="re_new_password"
               component={renderInput}
               type="password"
               className="form-control"
               validate={[required]}
               placeholder="Confirm your password"/>
        {errors && errors.map((error, index) => (
            <span
                key={`resetPasswordError${index}`}
                className="help-block">
              {error}
            </span>
        ))}
        {
          (errors && errors.length > 0) &&
          <span className="help-block">
            If you need to generate a new reset password link, please <Link to="/auth/forgot-password">click
            here</Link>
          </span>
        }
      </div>
      <Recaptcha
          afterCheckedRecaptcha={afterCheckedRecaptcha}
          onExpiredRecaptcha={onExpiredRecaptcha}/>
      <br/>
      <div className="form-group">
        <button type="submit"
                disabled={
                  status === LOADING_STATUS
                  || status === SUCCESSFUL_STATUS
                  || !checkedRecaptcha}>
          {
            status === LOADING_STATUS ? (
                <span>SENDING...</span>
            ) : (
                status === SUCCESSFUL_STATUS ? (
                    <span>
                      <i className="fa fa-check"/>&nbsp;SENT
                    </span>
                ) : (
                    <span>SEND</span>
                )
            )
          }
        </button>
      </div>
      <div className="options">
        <p>
          <Link to="/auth/login"><span className="green">Log In</span></Link>
          <span className="vline"/>
          <Link to="/auth/signup"><span>Create an Account</span></Link>
        </p>
      </div>
    </form>
)

ResetPasswordForm.propTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default withRecaptcha(reduxForm({
  form: 'authResetPasswordForm',
  validate: validateResetPasswordForm
})(ResetPasswordForm));
