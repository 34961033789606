import React, {useState} from 'react';
import classNames from "classnames/bind";
import {Dropdown} from "react-bootstrap";
import HeaderCartContainer from "components/Header/components/HeaderCartDropdown/components/HeaderCart/container";
import "./styles/HeaderCartDropdown.scss";
import {Link} from "react-router-dom";

export default function HeaderCartDropdown({store}) {
  const [open, setOpen] = useState(false);
  const {cart} = store;

  return (
      <Dropdown
          id="header-cart-dropdown"
          open={open}
          onToggle={() => setOpen(!open)}>
        <Dropdown.Toggle
            id="header-cart-dropdown"
            className="header-cart-dropdown">
          <span className={classNames({
            "header-cart-dropdown__icon icon-Cart": true,
            "header-cart-dropdown__icon--with-counter": cart.cart_items.length > 0
          })}>
            CART
            <span className="header-cart-dropdown__counter">
              {cart.cart_items.length}
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
            style={{
              left: "auto",
              right: 0
            }}>
          <HeaderCartContainer/>
          <div className="options">
            <p>
              <Link to="/store/cart" onClick={() => setOpen(false)}><span>View Cart</span></Link>
              <span className="vline"/>
              <Link to="/store/checkout" onClick={() => setOpen(false)}><span className="green">Checkout</span></Link>
            </p>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
};