import React from "react"
import useLocations
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/hooks/useLocations"

export default function LocationName({id}) {
  const {locations} = useLocations()

  if (!locations || locations.length === 0) return null

  const location = locations.find(location => location.id === parseInt(id))
  return <span>{location.name}</span>
}