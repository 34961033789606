import { connect } from 'react-redux';
import WishListItem from "../";
import {deleteWishListItem, updateWishListItem} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteWishListItem: wishListItem => dispatch(deleteWishListItem(wishListItem)),
    updateWishListItem: wishListItem => dispatch(updateWishListItem(wishListItem))
  };
};

const WishListItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WishListItem);

export default WishListItemContainer;