import {connect} from "react-redux";
import SingleCreditCard from "../index";
import {selectCreditCard, openCreditCardModal} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard)),
    openCreditCardModal: () => dispatch(openCreditCardModal())
  };
};

const SingleCreditCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleCreditCard);

export default SingleCreditCardContainer;