import { connect } from 'react-redux';
import CreditCardsInfo from "../";
import {openCreditCardModal, receiveCreditCards} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCreditCards: (data) => dispatch(
        receiveCreditCards(data)
    ),
    openCreditCardModal: () => dispatch(
        openCreditCardModal()
    ),
  };
};

const CreditCardsInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardsInfo);

export default CreditCardsInfoContainer;