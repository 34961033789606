import React from 'react';
import aboutImg from "assets/img/about-img.jpg";
import SEO from "components/SEO/index";
import "./styles/AboutUs.scss";
import {PureComponent} from "react/cjs/react.production.min";

class AboutUs extends PureComponent {
  render() {
    return (
        <div className="about-us">
          <h1>About Us</h1>
          <p><img className="img-responsive" src={aboutImg} alt=""/></p>
          <p>{process.env.REACT_APP_SITE_NAME} is your <span className="underline">entertainment superstore!</span></p>
          <p>From the newest hit movies, music, and video games to the rarest comics, toys,
            and memorabilia, {process.env.REACT_APP_SITE_NAME} is the only store to have it all! Everything you want,
            all in one store.
          </p>
          <h3>What do we buy and sell?</h3>
          <p>
            <span>Online & In Stores:</span><br/><br/>
            <span>Movies:</span> Blu-ray, DVDs, 4K UHD<br/>
            <span>Video Games:</span> Both retro & new games.<br/>
            <span>Collectibles:</span> new Toys and Posters<br/>
          </p>
          <p>
            <span>In Stores only:</span><br/><br/>
            <span>Music:</span> CDs & LPs<br/>
            <span>Video Games:</span> Retro & new consoles, accessories, & guides.<br/>
            <span>Collectibles:</span> comic books, graphic novels, used toys, board games, & memorabilia<br/>
            <span>Cards:</span> sports cards & collectible game cards, like Magic the Gathering, Pokemon, &
            Yu-Gi-Oh!<br/>
            <span>Books:</span> We carry books in our Joplin, MO, V·Stock, and EntertainMART locations, both classic and
            new best-sellers.<br/>
          </p>
          <h3>We also offer other great services to help our customers</h3>
          <p>
            <span>Rentals:</span> Movies & Video Games can be rented, with the option to
            buy after you try!<br/>
            <span>Special Orders:</span> Every location has access to the massive inventory of the
            entire company, and will ship directly to you.<br/>
            <span>Disc Repair:</span> Got a scratched disc that skips or won’t play at all? Vintage
            Stock can repair most damaged DVDs or CDs<br/>
            <span>Knowledgeable staff:</span> Our sales associates have a wide range of knowledge
            about the products we carry!
          </p>
          <h3>Management Team</h3>
          <p>The Vintage Stock and Movie Trading Company executive team has extensive experience and vision to ensure
            that
            our company maintains its growth potential and leadership in pre-owned diversified product lines. That is
            the
            concept behind Vintage Stock: everything you want in one store.</p>
          <SEO url='about'/>
        </div>
    )
  }
}

export default AboutUs;
