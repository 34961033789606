import React from 'react';
import {Field, reduxForm} from "redux-form";
import {email, onlyText, required} from "utils/forms/validators";
import {renderInput} from "utils/forms/renderers";
import PropTypes from "prop-types";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

const NewsletterSubscribeForm = ({errors, isLoading, handleSubmit, closeNewsLetterModal, checkedRecaptcha, afterCheckedRecaptcha, onExpiredRecaptcha}) => {
  return (
      <form
          className="form"
          onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field
                  id="name"
                  name="name"
                  component={renderInput}
                  type="text"
                  className="form-control"
                  validate={[required, onlyText]}
                  placeholder="Type your name"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                  id="email"
                  name="email"
                  component={renderInput}
                  type="email"
                  className="form-control"
                  validate={[required, email]}
                  placeholder="Type your email address"/>
              {errors.email && errors.email.map((msj, index) => (
                  <span className="help-block" key={index}>{msj}</span>
              ))}
            </div>
          </div>
        </div>
        <Recaptcha
            afterCheckedRecaptcha={afterCheckedRecaptcha}
            onExpiredRecaptcha={onExpiredRecaptcha}/>
        <br/>
        <div className="align-right">
          <div className="form-group">
            <button
                type="button"
                className="cancel"
                onClick={closeNewsLetterModal}>CANCEL</button>
            <button
                className="btn-custom"
                type="submit"
                disabled={isLoading || !checkedRecaptcha}>
              {
                isLoading ? (
                    <span>SUBSCRIBING...</span>
                ) : (
                    <span>SUBSCRIBE</span>
                )
              }
            </button>
          </div>
        </div>
      </form>
  )
};

NewsletterSubscribeForm.propTypes = {
  errors: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  closeNewsLetterModal: PropTypes.func.isRequired
};

export default withRecaptcha(reduxForm({
  form: 'vintageNewsletterForm'
})(NewsletterSubscribeForm));
