import { connect } from 'react-redux';
import {change} from 'redux-form';
import AddressForm from "../index";
import {closeAddressModal} from "modules/MyAccount/actions";
import {
  DEFAULT_BY_BILLING,
  DEFAULT_BY_BILLING_AND_SHIPPING,
  DEFAULT_BY_SHIPPING
} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";

const mapStateToProps = (state) => {
  return {
    account: state.account,
    selectedAddress: state.account.pages.addressBook.selectedAddress,
    initialValues: (state => {
      const {selectedAddress} = state.account.pages.addressBook;

      return selectedAddress
          ? {
            ...selectedAddress,
            byDefaultAsBilling: selectedAddress.default && (
                selectedAddress.address_type === DEFAULT_BY_BILLING
                || selectedAddress.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
            ),
            byDefaultAsShipping: selectedAddress.default && (
                selectedAddress.address_type === DEFAULT_BY_SHIPPING
                || selectedAddress.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
            )
          }
          : null
    })(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAddressModal: () => dispatch(
        closeAddressModal()
    ),
    changeFieldValue: (field, value) => dispatch(
        change('accountAddressForm', field, value)
    )
  };
};

const AddressFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressForm);
export default AddressFormContainer;
