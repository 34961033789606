import { connect } from 'react-redux';
import InvoiceModal from "../index";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const InvoiceModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceModal);

export default InvoiceModalContainer;