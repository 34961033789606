import React, {Component} from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container/index";
import vintageAxios from "services/api/index";
import OrderContainer from "modules/MyAccount/scenes/OrderHistory/components/Order/container/index";
import LoadingCassette from "components/LoadingCassette/index";
import "./styles/GuestOrderDetails.scss";

class GuestOrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null
    };

    this.fetchOrder = this.fetchOrder.bind(this);
    this.closeModal = name => this._closeModal.bind(this, name);
  }

  componentDidMount() {
    this.fetchOrder();
  }

  /**
   * Fetch order from the API.
   * @returns {AxiosPromise}
   */
  fetchOrder() {
    const {orderUUID} = this.props.match.params;

    const promise = vintageAxios.get(`/management/orders/${orderUUID}/`);

    promise.then(response => {
      this.setState({
        order: response.data
      });
    });

    return promise;
  }

  /**
   * Close modal in the redux store by its name
   * @param {String} name
   * @private
   */
  _closeModal(name) {
    this.props.closeModal(name);
  }

  render() {
    const {order} = this.state
    const {modals} = this.props.orderHistory

    return (
        <div>
          <section className="title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>Order Details</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="page-content">
            <div className="container">
              <div className="wrapper">
                {
                  !order ? (
                      <LoadingCassette/>
                  ) : (
                      <OrderContainer
                          order={order}/>
                  )
                }
              </div>
            </div>
            {
              modals.map((modal, index) => {
                const ComponentToRender = modal.component;
                return (
                    <span
                        key={`orderModal${index}`}>
                    {
                      modal.show && (
                          <ComponentToRender
                              show={modal.show}
                              onClose={this.closeModal(modal.name)}
                              {...modal.props}/>
                      )
                    }
                  </span>
                );
              })
            }
          </section>
          <NewsletterSubscribeContainer/>
          </div>
    )
  }
}

export default GuestOrderDetails;
