import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import NotifyMeWhenAvailableModal from "utils/components/NotifyMeWhenAvailableButton/components/NotifyMeWhenAvailableModal/index";
import "./styles/NotifyMeWhenAvailableButton.scss";

class NotifyMeWhenAvailableButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifyMeWhenAvailableModalIsOpen: false
    };

    this.openNotifyMeWhenAvailableModal = this.openNotifyMeWhenAvailableModal.bind(this);
    this.closeNotifyMeWhenAvailableModal = this.closeNotifyMeWhenAvailableModal.bind(this);
  }

  /**
   * Open NotifyMeWhenAvailableModal component
   */
  openNotifyMeWhenAvailableModal() {
    this.setState({notifyMeWhenAvailableModalIsOpen: true});
  }

  /**
   * Close NotifyMeWhenAvailableModal component
   */
  closeNotifyMeWhenAvailableModal() {
    this.setState({notifyMeWhenAvailableModalIsOpen: false});
  }

  render() {
    const {notifyMeWhenAvailableModalIsOpen} = this.state;
    const {productId, condition, onlyIcon} = this.props;

    return (
        <div
            className="notify-me-when-available">
          <button
              type="button"
              className={classNames({
                "btn-custom notify-me-when-available__button": true,
                "btn-custom--only-icon": onlyIcon
              })}
              onClick={this.openNotifyMeWhenAvailableModal}>
            <span className="icon icon-mail"/>{onlyIcon ? "" : "ALERT ME WHEN AVAILABLE"}
          </button>
          <NotifyMeWhenAvailableModal
              closeNotifyMeWhenAvailableModal={this.closeNotifyMeWhenAvailableModal}
              notifyMeWhenAvailableModalIsOpen={notifyMeWhenAvailableModalIsOpen}
              productId={productId}
              condition={condition}/>
        </div>
    )
  }
}

NotifyMeWhenAvailableButton.defaultProps = {
  condition: 0, // By default condition for selected product,
  onlyIcon: false
};

NotifyMeWhenAvailableButton.propTypes = {
  productId: PropTypes.number.isRequired, // Product to subscribe,
  condition: PropTypes.oneOf([0, 1]), // 0: new, 1: used,
  onlyIcon: PropTypes.bool
};

export default NotifyMeWhenAvailableButton;
