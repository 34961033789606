export const CUSTOMER_RECEIVE = 'CUSTOMER_RECEIVE';
export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';
export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';
export const ADDRESSES_RECEIVE = 'ADDRESSES_RECEIVE';
export const ADDRESS_SELECT = 'ADDRESS_SELECT';
export const ADDRESS_CREATE_REQUEST = 'ADDRESS_CREATE_REQUEST';
export const ADDRESS_CREATE_SUCCESS = 'ADDRESS_CREATE_SUCCESS';
export const ADDRESS_CREATE_ERROR = 'ADDRESS_CREATE_ERROR';
export const ADDRESS_EDIT_REQUEST = 'ADDRESS_EDIT_REQUEST';
export const ADDRESS_EDIT_SUCCESS = 'ADDRESS_EDIT_SUCCESS';
export const ADDRESS_EDIT_ERROR = 'ADDRESS_EDIT_ERROR';
export const ADDRESS_DELETE_REQUEST = 'ADDRESS_DELETE_REQUEST';
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS';
export const ADDRESS_DELETE_ERROR = 'ADDRESS_DELETE_ERROR';
export const ADDRESS_MODAL_OPEN = 'ADDRESS_MODAL_OPEN';
export const ADDRESS_MODAL_CLOSE = 'ADDRESS_MODAL_CLOSE';
export const CREDIT_CARDS_RECEIVE = 'CREDIT_CARDS_RECEIVE';
export const CREDIT_CARD_SELECT = 'CREDIT_CARD_SELECT';
export const CREDIT_CARD_CREATE_REQUEST = 'CREDIT_CARD_CREATE_REQUEST';
export const CREDIT_CARD_CREATE_SUCCESS = 'CREDIT_CARD_CREATE_SUCCESS';
export const CREDIT_CARD_CREATE_ERROR = 'CREDIT_CARD_CREATE_ERROR';
export const CREDIT_CARD_EDIT_REQUEST = 'CREDIT_CARD_EDIT_REQUEST';
export const CREDIT_CARD_EDIT_SUCCESS = 'CREDIT_CARD_EDIT_SUCCESS';
export const CREDIT_CARD_EDIT_ERROR = 'CREDIT_CARD_EDIT_ERROR';
export const CREDIT_CARD_DELETE_REQUEST = 'CREDIT_CARD_DELETE_REQUEST';
export const CREDIT_CARD_DELETE_SUCCESS = 'CREDIT_CARD_DELETE_SUCCESS';
export const CREDIT_CARD_DELETE_ERROR = 'CREDIT_CARD_DELETE_ERROR';
export const CREDIT_CARD_SET_AS_DEFAULT = 'CREDIT_CARD_SET_AS_DEFAULT';
export const ADDRESS_BOOK_SET_AS_DEFAULT = 'ADDRESS_BOOK_SET_AS_DEFAULT';
export const CREDIT_CARD_MODAL_OPEN = 'CREDIT_CARD_MODAL_OPEN';
export const CREDIT_CARD_MODAL_CLOSE = 'CREDIT_CARD_MODAL_CLOSE';
export const WISH_LIST_ITEMS_RECEIVE = 'WISH_LIST_ITEMS_RECEIVE';
export const WISH_LIST_ITEM_UPDATE = 'WISH_LIST_ITEM_UPDATE';
export const WISH_LIST_ITEM_DELETE_REQUEST = 'WISH_LIST_ITEM_DELETE_REQUEST';
export const WISH_LIST_ITEM_DELETE_SUCCESS = 'WISH_LIST_ITEM_DELETE_SUCCESS';
export const WISH_LIST_ITEM_DELETE_ERROR = 'WISH_LIST_ITEM_DELETE_ERROR';
export const WISH_LIST_ITEM_ADD = 'WISH_LIST_ITEM_ADD';
export const WISH_LIST_ITEM_REMOVE = 'WISH_LIST_ITEM_REMOVE';
export const EMPTY_ACCOUNT_INFORMATION = 'EMPTY_ACCOUNT_INFORMATION';
export const ADDRESS_ADD_OR_UPDATE = 'ADDRESS_ADD_OR_UPDATE';
export const SELECT_SHIPPING_ADDRESS = 'SELECT_SHIPPING_ADDRESS';
export const SELECT_BILLING_ADDRESS = 'SELECT_BILLING_ADDRESS';