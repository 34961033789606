import React from 'react';
import {Link} from "react-router-dom";
import "./styles/AppLogo.scss";

const AppLogo = () => {
  return (
    <Link className="app-logo" to="/">
      <img src={process.env.REACT_APP_SITE_LOGO} alt="App logo" className="app-logo__img"/>
    </Link>
  );
};


export default AppLogo;
