import {connect} from 'react-redux';
import SignUp from "../";
import {loginSuccess, signUp} from "modules/auth/actions";
import {fetchCartItemsSuccess} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: data => dispatch(signUp(data)),
    fetchCartItemsSuccess: cart => dispatch(fetchCartItemsSuccess(cart)),
    loginSuccess: (data, response) => dispatch(loginSuccess(data, response))
  };
};

const SignUpContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUp);

export default SignUpContainer;