import { connect } from 'react-redux';
import Order from "../index";
import {openModal} from "modules/MyAccount/scenes/OrderHistory/actions/index";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name, order) => dispatch(openModal(name, order))
  };
};

const OrderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);

export default OrderContainer;