import { connect } from 'react-redux';
import {closeModal} from "modules/MyAccount/scenes/OrderHistory/actions";
import GuestOrderDetails from "modules/MyAccount/scenes/GuestOrderDetails/index";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: name => dispatch(closeModal(name))
  };
};

const GuestOrderDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestOrderDetails);

export default GuestOrderDetailsContainer;