import React from "react"
import _ from "lodash"
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_STORES} from "modules/store/scenes/ProductsList/constants/query-params"
import arrayify from "services/browser-history/arrayify"
import mapMarker from "./img/map-pin.svg"
import "./styles/ProductAvailableIn.scss"
import LocationName
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/LocationName"

export default function ProductAvailableIn({stores}) {
  const queryParams = useQueryParams()
  // I'm converting by default into an array because currently there's only one result
  // as the client asked to change it so the user can select only 1
  // TODO SINGULAR STORES Fix this problem
  let storesFromParams = queryParams.find(QUERY_PARAM_STORES) ? [queryParams.find(QUERY_PARAM_STORES)] : []
  storesFromParams = storesFromParams ? arrayify(storesFromParams).map(store => parseInt(store)) : []
  const intersection = _.intersection(storesFromParams, stores)

  if (intersection.length === 0) return null

  return (
      <div className="product-available-in">
        <div>
          <img src={mapMarker} alt=""/>
        </div>
        <div>
          <strong>
            <LocationName id={intersection[0]}/>
          </strong>
          {
            stores.length > 1 &&
            <span> and <strong>{stores.length - intersection.length}+</strong></span>
          }
        </div>
      </div>
  )
}