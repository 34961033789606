import React from 'react';
import PropTypes from "prop-types";
import "./styles/CartCoupon.scss";
import CouponFormContainer from "modules/store/scenes/Cart/components/CartCouponBox/components/CouponForm/container";
import CouponDetailsContainer from "modules/store/scenes/Cart/components/CartCouponBox/components/CouponDetails/container";

export default function CartCouponBox({store}) {
  const {cart} = store;

  return (
      <div className="cart-coupon">
        {
          cart.discount_code_data
              ?
              <CouponDetailsContainer couponData={cart.discount_code_data}/>
              :
              <CouponFormContainer total={store.cartTotals.total}/>
        }
      </div>
  );
}

CartCouponBox.propTypes = {
  store: PropTypes.object
};
