import React from 'react';
import PropTypes from 'prop-types';

const Location = ({location}) => (
    <div>
      <p>
        {location.address_1}{location.address_2 && `, ${location.address_2}`}
      </p>
      <p>
        {location.city_name}, {location.region_name} {location.zip_code}
      </p>
      {
        location.email &&
        <p>Email: <a href={`mailto:${location.email}`}>{location.email}</a></p>
      }
      {
        location.phone_number_1 &&
        <p>Phone: <a href={`tel:${location.phone_number_1}`}>{location.phone_number_1}</a></p>
      }
      {
        location.phone_number_2 &&
        <p>Phone: <a href={`tel:${location.phone_number_2}`}>{location.phone_number_2}</a></p>
      }
      {/*<LocationHours
          hours={location.store_hours}/>*/}
    </div>
);

Location.propTypes = {
  location: PropTypes.shape({
    address_1: PropTypes.string.isRequired,
    city_name: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone_number_1: PropTypes.string.isRequired,
    store_hours: PropTypes.array.isRequired
  })
};

export default Location;