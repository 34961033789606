import React from 'react';
import PropTypes from 'prop-types';
import {NEW} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";

const CartItemCondition = ({condition}) => (
    <span className="item-condition">{condition === NEW ? ('New') : ('Used')}</span>
);

CartItemCondition.propTypes = {
  condition: PropTypes.number.isRequired
};

export default CartItemCondition;