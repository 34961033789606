import { connect } from 'react-redux';
import Logout from "../";
import {logout} from "modules/auth/actions";
import {emptyCart} from "modules/store/actions";
import {emptyAccountInformation} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: data => dispatch(logout(data)),
    emptyCart: () => dispatch(emptyCart()),
    emptyAccountInformation: () => dispatch(emptyAccountInformation()),
  };
};

const LogoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);

export default LogoutContainer;