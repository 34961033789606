import React, {Component} from 'react';
import ChangePasswordForm from "./components/ChangePasswordForm";
import {toastr} from 'react-redux-toastr';
import diskette from "assets/img/diskette.png";
import ToastContent from "utils/components/ToastContent/index";
import SEO from "components/SEO/index";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.updatePassword = this.updatePassword.bind(this);
  }

  updatePassword(data) {
    const promise = this.props.changePassword(data);
    promise.then(action => {
      toastr.success('', '', {
        icon: (<span className="icon-circle-check"/>),
        component: (
            <ToastContent
                image={diskette}
                text={"Saved"}/>
        )
      });
    })
  }

  render() {
    const {account} = this.props;

    return (
        <div>
          <div className="wrapper">
            <h2><span className="icon icon-padlock"/><span className="text">Password</span></h2>
            <ChangePasswordForm
                onSubmit={this.updatePassword}
                isLoading={account.pages.password.loading}
                success={account.pages.password.success}
                errors={account.pages.password.errors}/>
          </div>
          <SEO url='my-account/password' />
        </div>
    )
  }
}

export default ChangePassword