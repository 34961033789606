import {connect} from "react-redux";
import HeaderUserDropdown from "../index";
import {receiveUser} from "modules/auth/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveUser: user => dispatch(receiveUser(user))
  };
};

const HeaderUserDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderUserDropdown);

export default HeaderUserDropdownContainer;