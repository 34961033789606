import {connect} from 'react-redux';
import Login from "../index";
import {login, loginSuccess} from "modules/auth/actions";
import {fetchCartItems, fetchCartItemsSuccess} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: data => dispatch(login(data)),
    fetchCartItemsSuccess: cart => dispatch(fetchCartItemsSuccess(cart)),
    fetchCartItems: () => dispatch(fetchCartItems()),
    loginSuccess: (data, response) => dispatch(loginSuccess(data, response))
  };
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default LoginContainer;