const validate = values => {
  const errors = {};
  if (!values.items || !values.items.length) {
    errors.items = { _error: 'At least one item must be entered' }
  } else {
    const itemsArrayErrors = [];
    values.items.forEach((member, memberIndex) => {
      const memberErrors = {};
      if (member.will_be_returned && !member.return_reason) {
        memberErrors.return_reason = 'Please select a reason from the list.';
        itemsArrayErrors[memberIndex] = memberErrors
      }
    });
    if (itemsArrayErrors.length) {
      errors.items = itemsArrayErrors
    }
  }
  return errors
};

export default validate;