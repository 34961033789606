import React, {Component} from 'react';
import {Link} from "react-router-dom";
import vintageAxios from "services/api";
import OrderContainer from "./components/Order/container";
import LoadingCassette from "components/LoadingCassette";
import queryString from "query-string";
import OrderHistoryFilters from "modules/MyAccount/scenes/OrderHistory/components/OrderHistoryFilters/index";
import "./styles/OrderHistory.scss";
import SEO from "components/SEO/index";

class OrderHistory extends Component {
  constructor(props){
    super(props);

    this.closeModal = name => this._closeModal.bind(this, name);
  }

  componentDidMount(){
    this.fetchOrders();
  }

  componentDidUpdate(prevProps){
    if( this.props.location.search !== prevProps.location.search )  // Query params updated
      this.fetchOrders();
  }

  /**
   * Get parsed version of the query params
   */
  get parsedParams(){
    return queryString.parse(this.props.location.search);
  }

  /**
   * Get orders from the API.
   * @returns {AxiosPromise}
   */
  fetchOrders(){
    this.props.clearOrders();
    const promise = vintageAxios.get('/management/orders/', {
      params: this.parsedParams
    });
    promise.then(response => {
      this.props.receiveOrders(response.data);
    });
    return promise;
  }

  /**
   * Close modal in the redux store by its name
   * @param {String} name
   * @private
   */
  _closeModal(name){
    this.props.closeModal(name);
  }

  render(){
    const {orders, modals} = this.props.orderHistory;

    return(
        <div className="wrapper orders">
          <div>
            <h2>
              <span className="icon icon-shopping-bag"/>
              <span className="text">Order History</span>
            </h2>
          </div>
          <OrderHistoryFilters/>
          <hr/>
          {
            orders === null ? (
                <LoadingCassette/>
            ) : (
                orders.length > 0 ? (
                    orders.map((order) => (
                        <OrderContainer
                            key={`accountOrder${order.id}`}
                            order={order}/>
                    ))
                ) : (
                    <div className="empty-message">
                      {
                        this.parsedParams['search'] || this.parsedParams['year'] ? (
                            <div>
                              <h1>No orders found.</h1>
                              <p>There are no results matching your search criteria.</p>
                            </div>
                        ) : (
                            <div>
                              <h1>Your Order History is Empty.</h1>
                              <p>Looks like you haven’t made any order yet.</p>
                            </div>
                        )
                      }
                      <div className="footer">
                        <div className="bg orders"/>
                        <p><Link to="/store/products"><span className="btn-custom">GO TO STORE</span></Link></p>
                      </div>
                    </div>
                )
            )
          }
          {
            modals.map((modal, index) => {
              const ComponentToRender = modal.component;
              return (
                  <span
                    key={`orderModal${index}`}>
                    {
                      modal.show && (
                        <ComponentToRender
                            show={modal.show}
                            onClose={this.closeModal(modal.name)}
                            {...modal.props}/>
                      )
                    }
                  </span>
              );
            })
          }
          <SEO url='my-account/order-history' />
        </div>
    )
  }
}

export default OrderHistory
