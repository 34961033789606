export const SHIPMENT_STATUS_PROCESSING = 0;
export const SHIPMENT_STATUS_SHIPPED = 1;
export const SHIPMENT_STATUS_IN_TRANSIT = 2;
export const SHIPMENT_STATUS_DELIVERED = 3;
export const SHIPMENT_STATUS_RETURNED = 4;
export const SHIPMENT_STATUS_UNKNOWN = 5;
export const SHIPMENT_STATUS_OUT_FOR_DELIVERY = 6;

export const SHIPMENT_STATUSES = [
  [SHIPMENT_STATUS_PROCESSING, 'Processing'],
  [SHIPMENT_STATUS_SHIPPED, 'Shipped'],
  [SHIPMENT_STATUS_IN_TRANSIT, 'In transit'],
  [SHIPMENT_STATUS_DELIVERED, 'Delivered'],
  [SHIPMENT_STATUS_RETURNED, 'Returned'],
  [SHIPMENT_STATUS_UNKNOWN, 'Unknown'],
  [SHIPMENT_STATUS_OUT_FOR_DELIVERY, 'Out For Delivery'],
];