import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import WishlistShareModalForm from "modules/MyAccount/scenes/Wishlist/components/WishlistShareModal/components/WishlistShareModalForm/index";
import {getWishListId} from "services/store/wishlist/index";
import vintageAxios from "services/api/index";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";

class WishlistShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   *
   * @param {Object} email
   * @returns {AxiosPromise<any>}
   */
  static sendWishlistToEmail(email) {
    const promise = vintageAxios
        .post(`/management/wish-lists/share/${getWishListId()}/email/`, email);
    return promise;
  }

  /**
   * Handle submit email form.
   * @param {Object} data
   */
  handleSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      WishlistShareModal
          .sendWishlistToEmail(data).then(response => {
        this.setState({
          status: SUCCESSFUL_STATUS
        });
      });
    });
  }

  render() {
    const {status} = this.state;
    const {onClose, wishlistShareModalIsOpen} = this.props;

    return (
        <Modal
            show={wishlistShareModalIsOpen}
            onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Share Wish List
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <WishlistShareModalForm
                onClose={onClose}
                onSubmit={this.handleSubmit}
                status={status}/>
          </Modal.Body>
        </Modal>
    )
  }
}

export default WishlistShareModal;