import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {required} from "utils/forms/validators";

export class ChangePasswordForm extends Component {
  render(){
    const {isLoading, errors, handleSubmit} = this.props;
    const fields = [
      {
        label: 'Current Password',
        placeholder: 'Type your current password',
        name: 'current_password'
      },
      {
        label: 'New Password',
        placeholder: 'Type your new password',
        name: 'new_password'
      },
      {
        label: 'Confirm Password',
        placeholder: 'Type your confirm password',
        name: 're_new_password'
      },
    ];

    return(
        <form className="form" onSubmit={handleSubmit}>
          {
            fields.map((field, index) => (
                <div className="form-group" key={`changePasswordFormField${index}`}>
                  <label htmlFor={field.name}>{field.label} <span className="required">*</span></label>
                  <Field id={field.name}
                         name={field.name}
                         component={renderInput}
                         type="password"
                         className="form-control"
                         validate={[required]}
                         autoComplete={field.name}
                         placeholder={field.placeholder}/>
                  {errors[field.name] && errors[field.name].map((msj, index) => (
                      <span className="help-block" key={`changePasswordFormField-${field.name}-error-${index}`}>{msj}</span>
                  ))}
                  {errors.non_field_errors && index === (fields.length - 1) && errors.non_field_errors.map((msj, index) => (
                      <span className="help-block" key={`changePasswordFormNonFieldError${index}`}>{msj}</span>
                  ))}
                </div>
            ))
          }
          <div className="bottom">
            <div className="form-group">
                {
                    isLoading ? (
                        <input type="submit" value="SENDING..." disabled={true}/>
                    ) : (
                        <input type="submit" value="CHANGE PASSWORD"/>
                    )
                }
            </div>
          </div>
        </form>
    )
  }
}

ChangePasswordForm = reduxForm({
  form: 'authChangePasswordForm'
})(ChangePasswordForm);

export default ChangePasswordForm