import { connect } from 'react-redux';
import Address from "../";
import {
  deleteAddress, selectAddress, openAddressModal
} from "modules/MyAccount/actions";
import {setAddressBookAsDefault} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAddress: address => dispatch(deleteAddress(address)),
    selectAddress: address => dispatch(selectAddress(address)),
    openAddressModal: () => dispatch(openAddressModal()),
    setAddressBookAsDefault: addressBook => dispatch(setAddressBookAsDefault(addressBook))
  };
};

const AddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Address);

export default AddressContainer;