import React from 'react';
import PropTypes from 'prop-types';
import "./styles/HomePromo.scss";
import {Link} from "react-router-dom";

const HomePromo = ({promo}) => {
  return (
      <Link
          to={promo.url}
          className="home-promo">
        <div className="promo-category-box" style={{
          backgroundImage: `url(${promo.background})`
        }}>
          <div className="promo-category-box__text">
            <h3 dangerouslySetInnerHTML={{__html: promo.description}}/>
          </div>
          <img
              className="promo-category-box__image"
              src={promo.image_over}
              alt="VintageStock"/>
        </div>
      </Link>
  );
};

HomePromo.propTypes = {
  promo: PropTypes.shape({
    background: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image_over: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })
};

export default HomePromo;
