import {connect} from "react-redux";
import ProductsGrid from "../index";
import {setProductsCount} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {
    viewAs: state.productsList.viewAs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProductsCount: productsCount => dispatch(setProductsCount(productsCount))
  };
};

const ProductsGridContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsGrid);

export default ProductsGridContainer;