import {connect} from "react-redux";
import CheckoutSummary from "../index";

const mapStateToProps = (state) => {
  return {
    store: state.store,
    auth: state.auth,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const CheckoutSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutSummary);

export default CheckoutSummaryContainer;