import React, {Component} from 'react';
import vintageAxios from 'services/api';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import "./styles/ReturnItemsModal.scss";
import {MODAL_CANCEL} from "modules/MyAccount/scenes/OrderHistory/constants/modal-names";
import ReturnItemsFormContainer from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/container";
import OrderModalHeading from "modules/MyAccount/scenes/OrderHistory/components/OrderModalHeading/index";
import {Link} from "react-router-dom";
import {
  NORMAL,
  SHIPMENT
} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/order-item-type";

class ReturnItemsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nothingSelected: false,
      loading: false,
      /*Activated when the items are returned or cancelled. Show messagge 'Returned' or 'Cancelled'*/
      done: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.closeModal(this.props.modalName)
  }

  handleFormSubmit(data) {
    const {modalName} = this.props;
    const {selectedOrder} = this.props.orderHistory;
    const itemsToBeReturned = data.items.filter(item => {
      return item.will_be_returned
    }).map(item => ({
      order_item: item.item,
      reasons: item.return_reason
    }));
    if (itemsToBeReturned.length > 0) {
      this.setState({
        loading: true
      }, () => {
        const urlPath = modalName === MODAL_CANCEL ? 'management/orders/cancellations/items/' : 'management/orders/returns/items/';
        vintageAxios.post(urlPath, itemsToBeReturned).then(postResponse => {
          vintageAxios.get(`management/orders/${selectedOrder.uuid}`).then(getResponse => {
            this.props.updateSelectedOrder(getResponse.data);
            this.setState({done: true, loading: false});
          }).catch(error => {
            this.props.closeModal(modalName);
          });
        }).catch(error => {
          console.log(error);
        });
      });
    } else {
      this.setState({
        nothingSelected: true
      })
    }
  }

  render() {
    const {show, onClose, modalName} = this.props;
    const {selectedOrder} = this.props.orderHistory;
    const {nothingSelected, loading, done} = this.state;

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="invoice cancel-items">
          <Modal.Header closeButton>
            {
              !done &&
              <Modal.Title>
                Choose items to {modalName === MODAL_CANCEL ? 'cancel' : 'return'}
              </Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            {
              !done ? (
                  <div>
                    <p>
                      Select the items that you want to {modalName === MODAL_CANCEL ? 'cancel' : 'return'}:
                    </p>
                    {
                      nothingSelected && (
                          <div className="alert alert-danger">
                            You have not selected any product yet
                          </div>
                      )
                    }
                    <OrderModalHeading
                        uuid={selectedOrder.uuid}
                        date={selectedOrder.created_at}/>
                    <ReturnItemsFormContainer
                        closeModal={this.closeModal}
                        modalName={modalName}
                        loading={loading}
                        done={done}
                        onSubmit={this.handleFormSubmit}
                        initialValues={{
                          items: selectedOrder.items[modalName === MODAL_CANCEL ? NORMAL : SHIPMENT].map(item => ({
                            will_be_returned: false,  // by default for all of them
                            item: item.order_item_data.id,
                            return_reason: null
                          }))
                        }}/>
                  </div>
              ) : (
                  <div className="modal-body__request_successful">
                    <i className="fa fa-check-circle"/>
                    <h1>Your {modalName.toLowerCase()} request is being reviewed</h1>
                    <p>We will contact you shortly regarding your request.</p>
                    <Link to="/store/products">
                      <span className="btn-custom">CONTINUE SHOPPING</span>
                    </Link>
                  </div>
              )
            }

          </Modal.Body>
        </Modal>
    )
  }
}

ReturnItemsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired
};

export default ReturnItemsModal
