import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toastr} from 'react-redux-toastr';
import {addToWishList} from "services/store/wishlist";
import {isAuthenticated} from "services/auth/index";
import {Link, withRouter} from "react-router-dom";
import ToastContent from "utils/components/ToastContent/index";
import classNames from 'classnames/bind';

export class AddToWishListButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingToWishList: false
    };

    this.handleAddToWishList = this.handleAddToWishList.bind(this);
  }

  handleAddToWishList() {
    const {productId, contentType, selectedCondition, disabled} = this.props;
    if (!disabled) {
      this.setState({addingToWishList: true}, () => {
        let promise = addToWishList({
          productId: productId,
          contentType: contentType,
          condition: selectedCondition
        });
        promise.then(response => {
          // TODO TOASTR check if all notifications will have this same look and feel. If so, let's make this global
          toastr.success('', '', {
            component: (
                <ToastContent
                    image={response.data.profile_picture}
                    text="Added to wish list"/>
            ),
            icon: (<span className="icon-circle-check"/>),
            closeButton: (<span className="icon-circle-close"/>),
          });
          this.setState({addingToWishList: false}, () => {
            // Exists an callback function
            if(this.props.onAfterAddToWishList)
              this.props.onAfterAddToWishList();
          });
        });
        promise.catch(error => {
          this.setState({addingToWishList: false});
        })
      });
    }
  }

  render() {
    const {addingToWishList} = this.state;
    const {location, context, onlyIcon} = this.props;

    if (context) {
      if (!isAuthenticated())
        return (
            <Link
                to={`/auth/login?wishlist=1&from=${location.pathname}`}>
              <span className="icon icon-wishlist-book"/>
              {onlyIcon ? "" : "Move to wishlist"}
            </Link>
        );

      return (
          <button
              style={{
                padding: '0 10px',
                border: 'none'
              }}
              onClick={this.handleAddToWishList}>
            <span className="icon icon-wishlist-book"/>
            {
              addingToWishList ? (
                  <span>{onlyIcon ? "" : "Moving..."}</span>
              ) : (
                  <span>{onlyIcon ? "" : "Move to wishlist"}</span>
              )
            }
          </button>
      );
    }

    if (!isAuthenticated())
      return (
          <Link
              to={`/auth/login?wishlist=1&from=${location.pathname}`}
              className={classNames({
                "btn-custom btn-custom--gray text-center": true,
                "btn-custom--only-icon": onlyIcon
              })}>
            <span className="icon icon-wishlist-book"/>
            <span>{onlyIcon ? "" : "ADD TO WISH LIST"}</span>
          </Link>
      );

    return (
        <button
            type="button"
            className={classNames({
              "btn-custom btn-custom--gray text-center": true,
              "btn-custom--only-icon": onlyIcon
            })}
            disabled={addingToWishList}
            onClick={this.handleAddToWishList}>
          <span className="icon icon-wishlist"/>
          {
            addingToWishList ? (
                <span>{onlyIcon ? "" : "ADDING..."}</span>
            ) : (
                <span>{onlyIcon ? "" : "ADD TO WISH LIST"}</span>
            )
          }
        </button>
    );
  }
}

AddToWishListButton.defaultProps = {
  onlyIcon: false,
  disabled: false,
};

AddToWishListButton.propTypes = {
  productId: PropTypes.number.isRequired,
  contentType: PropTypes.number.isRequired,
  selectedCondition: PropTypes.number.isRequired,
  onAfterAddToWishList: PropTypes.func,
  context: PropTypes.string,
  onlyIcon: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withRouter(AddToWishListButton)
