import React from "react"
import {useHistory} from "react-router"
import useQueryParams from "hooks/useQueryParams"
import {
  QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_STORES
} from "modules/store/scenes/ProductsList/constants/query-params"

const options = [
  {
    name: "In Store",
    queryParam: QUERY_PARAM_PICKUP_AVAILABLE
  },
  {
    name: "Online",
    queryParam: QUERY_PARAM_IN_STOCK
  }
]

export default function AvailabilityCheckboxes() {
  const history = useHistory()
  const queryParams = useQueryParams()

  /**
   * Handles change event on the checkboxes
   * @param event {Object}
   * @param option {Object}
   */
  function handleChange(event, option) {
    const {checked} = event.target;
    const newParams = {
      [option.queryParam]: event.target.checked ? true : null
    }
    if (option.queryParam === QUERY_PARAM_PICKUP_AVAILABLE && !checked)
      newParams[QUERY_PARAM_STORES] = null
    queryParams.update(newParams)
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  return options.map((option, index) =>
      <div key={index}>
        <input
            type="checkbox"
            id={index}
            onChange={event => handleChange(event, option)}
            value={option}
            checked={queryParams.find(option.queryParam) !== undefined}/>
        <label htmlFor={index}>
          <span className="text">&nbsp;&nbsp;{option.name}</span>
        </label>
      </div>
  )
}