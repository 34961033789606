import React from 'react';
import PropTypes from "prop-types";
import CartItemCondition from "modules/store/scenes/Cart/components/CartItem/components/CartItemCondition/index";
import {
  CANCELLED,
  RETURNED
} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/order-item-type";
import classNames from "classnames/bind";

const OrderItemData = ({item, type}) => (
    <div>
      <div className={classNames({
        "item": true,
        "readOnly": type === RETURNED || type === CANCELLED
      })}>
        <div className="photo">
          <img
              src={item.list_image_thumbnail}
              alt={item.product_name}/>
        </div>
        <div className="content">
          <p>{item.product_name}</p>
          <p>
            <span className="cond">
              <CartItemCondition
                  condition={item.condition}/>
            </span>
            <span className="qty">Qty: {item.quantity}</span>
          </p>
        </div>
        <div className="price">
          <p>${item.price}</p>
        </div>
        <br/>
      </div>
      {
        (type === CANCELLED || type === RETURNED) && (
            <p className="marked">
              {type.toUpperCase()}
            </p>
        )
      }
    </div>
);

OrderItemData.propTypes = {
  item: PropTypes.object.isRequired
};

export default OrderItemData;