import React, {useEffect} from 'react';
import LoadingCassette from "components/LoadingCassette/index";
import CartItemContainer from "modules/store/scenes/Cart/components/CartItem/container/index";
import CartTotalsContainer from "modules/store/scenes/Cart/components/CartTotals/container/index";
import {HEADER} from "modules/store/scenes/Cart/components/CartTotals/constants/contexts";
import {CART_ITEM_IN_HEADER} from "modules/store/scenes/Cart/components/CartItem/constants/contexts";
import EmptyCart from "modules/store/scenes/Cart/components/EmptyCart/index";
import {getCartSession} from "services/store";

export default function HeaderCart({store, fetchCartItems}) {
  const cartSession = getCartSession();

  useEffect(() => {
    if (cartSession !== null && (!store.loadingCart && !store.cart.id))
      fetchCartItems();
  });

  return (
      <div className="header-cart">
        <div className="header-cart__items">
          {
            store.loadingCart ? (
                <LoadingCassette/>
            ) : (
                store.cart.cart_items.length > 0 ? (
                    store.cart.cart_items.map(item => (
                        <CartItemContainer
                            context={CART_ITEM_IN_HEADER}
                            key={`cartItem${item.id}`}
                            item={item}/>
                    ))
                ) : (
                    <EmptyCart/>
                )
            )
          }
        </div>
        {
          (store.cart !== null && store.cart.cart_items.length > 0) && (
              <div className="subtotal">
                <CartTotalsContainer
                    context={HEADER}/>
              </div>
          )
        }
      </div>
  )
}