import React, {Suspense, useCallback, useEffect} from "react"
import PropTypes from "prop-types"
import {Modal} from "react-bootstrap"
import LoadingCassette from "@bit/vintagestock.components.loading-cassette"
import "./styles/ChooseYourStoresModal.scss"

// Code-split StoresTab component because it contains an import of react-window library which is being used only there
const StoresTab = React.lazy(() => import("modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/ChooseYourStoresModal/components/StoresTab"))

export default function ChooseYourStoresModal({show, toggle}) {
  return (
      <Modal
          show={show}
          onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>
            Choose your store
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="choose-your-stores-modal-body">
          {
            show &&
            <Suspense fallback={<LoadingCassette/>}>
              <StoresTab/>
            </Suspense>
          }
          <div className="text-center" style={{'paddingTop': '30px'}}>
            <button
                className="btn-custom"
                onClick={toggle}>
              Search!
            </button>
          </div>
        </Modal.Body>
      </Modal>
  )
}

ChooseYourStoresModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}