import React, {useState} from "react";
import PropTypes from "prop-types";
import StoreAvailabilityModal
  from "modules/store/scenes/ProductDetails/components/SingleProductStoreAvailability/components/StoreAvailabilityModal";
import "./styles/SingleProductStoreAvailability.scss";

export default function SingleProductStoreAvailability() {
  const [showModal, setShowModal] = useState(false);

  /**
   * Handles check availability button click to open the modal
   * @param event {Object}
   */
  function handleCheckBtnClick(event) {
    setShowModal(true);
  }

  /**
   * Handles modal onHide callback
   */
  function handleModalOnHide() {
    setShowModal(false);
  }

  return (
      <React.Fragment>
        <div className="single-product-store-availability">
          <button
              type="button"
              className="add-to-cart-button add-to-cart-button--style-button add-to-cart-button--size-medium"
              onClick={handleCheckBtnClick}>
            FIND IN STORE
          </button>
        </div>
        <StoreAvailabilityModal
            show={showModal}
            onHide={handleModalOnHide}/>
      </React.Fragment>
  );
}

SingleProductStoreAvailability.propTypes = {
  stockByStores: PropTypes.array.isRequired
};