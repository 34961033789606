import React from 'react';
import PropTypes from 'prop-types';

const OrderModalHeading = ({uuid, date}) => (
    <div className="row">
      <div className="col-md-12">
        <div className="details">
          <p>Order No.<span className="value">{uuid}</span></p>
        </div>
        <div className="details right">
          <p>Date:<span id="order-number" className="value">{`${new Date(date).toDateString()}`}</span></p>
        </div>
      </div>
    </div>
);

OrderModalHeading.propTypes = {
  uuid: PropTypes.string,
  date: PropTypes.string
};

export default OrderModalHeading;