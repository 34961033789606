import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import TrustItem from "utils/components/TrustElements/components/TrustItem";
import "./styles/TrustElements.scss";

const TrustElements = ({direction}) => (
    <div className={classNames({
      "trust-elements": true,
      "trust-elements--as-row": direction === "row",
      "trust-elements--as-column": direction === "column"
    })}>
      <div className="trust-elements__item">
        <TrustItem
            align={direction === "row" ? "center" : "left"}
            iconClassName="icon-circle-check" text="Product Guarantee"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align={direction === "row" ? "center" : "left"}
            iconClassName="icon-safe-purchase" text="100% Safe & Secure Checkout"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align={direction === "row" ? "center" : "left"}
            iconClassName="icon-returns" text="Easy Returns"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align={direction === "row" ? "center" : "left"}
            iconClassName="icon-customer-service" text={
          <span>Customer service: <a href="mailto:support@vintagestock.com">support@vintagestock.com</a></span>
        }/>
      </div>
    </div>
);

TrustElements.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]).isRequired
};

export default TrustElements;
