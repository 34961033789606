import { connect } from 'react-redux';
import Cart from "../";
import {receiveCartTotals} from "modules/store/actions/index";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCartTotals: totals => dispatch(receiveCartTotals(totals))
  };
};

const CartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);

export default CartContainer;