import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderInput, renderSelect, renderTextarea} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";
import Select from "react-select";
import vintageAxios, {apiList} from "services/api";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCity: '',
      cityPlaceholder: 'Type and select your city...',
      locations: [],
      showLocationInput: false
    };

    this.onChange = this.onChange.bind(this);
    this.handleMessageTypeChange = this.handleMessageTypeChange.bind(this);
  }

  componentDidMount() {
    this.fetchLocations();
  }

  /**
   * Fetches locations from the API in order to render the select
   */
  fetchLocations() {
    const promise = vintageAxios.get('/misc/locations/');
    promise.then(response => {
      this.setState({
        locations: response.data.results
      });
    });
    return promise;
  }


  onChange(city) {
    this.setState({
      selectedCity: city
    });
    this.props.changeFieldValue('city', city.id);
  }

  handleMessageTypeChange(event) {
    this.setState({
      showLocationInput: event.target.value === 'store'
    })
  }

  getCities(input) {
    if (!input) {
      return Promise.resolve({options: []});
    }

    const queryParams = {'search': input};
    return apiList('cities', queryParams)
        .then((response) => {
          return {options: response.data}
        });
  }

  render() {
    const {
      errors, isLoading, handleSubmit, afterCheckedRecaptcha,
      onExpiredRecaptcha, checkedRecaptcha
    } = this.props;
    const {locations, showLocationInput} = this.state;

    return (
        <form id="contact-form" className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Name <span className="required">*</span></label>
                <Field id="name"
                       name="name"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       validate={[required, onlyText]}
                       placeholder="Type your name"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">Email <span className="required">*</span></label>
                <Field id="email"
                       name="email"
                       component={renderInput}
                       type="email"
                       className="form-control"
                       validate={[required, email]}
                       placeholder="Type your email address"/>
                {errors.email && errors.email.map((msj, index) => (
                    <span className="help-block" key={`vintageContactFormEmailError${index}`}>{msj}</span>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone_number">Phone Number <span className="required">*</span></label>
                <Field id="phone_number"
                       name="phone_number"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       validate={[required]}
                       placeholder="Type your phone number"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="city">City <span className="required">*</span></label>
                <Select.Async
                    ref="cityField"
                    multi={false}
                    name="city_autocomplete"
                    value={this.state.selectedCity}
                    onChange={this.onChange}
                    valueKey="id"
                    inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off'}}
                    placeholder={this.state.cityPlaceholder}
                    labelKey="autocomplete_text"
                    loadOptions={this.getCities}/>
                <Field id="city"
                       name="city"
                       component={renderInput}
                       type="number"
                       className="form-control hidden"
                       validate={[required]}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="message_type">Message Type <span className="required">*</span></label>
                <Field
                    name="message_type"
                    component={renderSelect}
                    onChange={this.handleMessageTypeChange}
                    className="form-control"
                    validate={[required]}>
                  <option value="">Select an option</option>
                  <option value="purchase">Questions about a Website Purchase</option>
                  <option value="store">Store Comment/Question</option>
                  <option value="general">General Comment/Question</option>
                </Field>
              </div>
            </div>
            <div className="col-md-6">
              {
                showLocationInput &&
                <div className="form-group">
                  <label htmlFor="message_type">Location <span className="required">*</span></label>
                  <Field
                      name="store"
                      component={renderSelect}
                      className="form-control"
                      validate={[required]}
                      disabled={locations.length === 0}>
                    <option value="">{locations.length === 0 ? 'Loading locations...' : 'Select a location'}</option>
                    {
                      locations.map(location =>
                          <option key={`location${location.id}`} value={location.id}>
                            {location.name}
                          </option>
                      )
                    }
                  </Field>
                </div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="message">Comment</label>
                <Field id="message"
                       name="message"
                       component={renderTextarea}
                       type="number"
                       className="form-control"
                       validate={[required]}/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <Recaptcha
                afterCheckedRecaptcha={afterCheckedRecaptcha}
                onExpiredRecaptcha={onExpiredRecaptcha}/>
          </div>
          <br/>
          {/* TODO there's nothing in the API to deal with these fields */}
          {/*<div className="row">
            <div className="col-md-12">
              <div className="custom-checkbox">
                <input type="checkbox" value="1" checked id="news" name=""/>
                <label htmlFor="news"/>
                <span className="text">New Releases (weekly)</span>
              </div>
              <div className="custom-checkbox">
                <input type="checkbox" value="1" checked id="coupons" name=""/>
                <label htmlFor="coupons"/>
                <span className="text">Coupons/Sales (monthly)</span>
              </div>
            </div>
          </div>*/}
          <div className="form-group">
            {
              isLoading ? (
                  <input
                      type="submit"
                      value="SENDING..."
                      disabled={true}/>
              ) : (
                  <input
                      disabled={!checkedRecaptcha}
                      type="submit"
                      value="SEND"/>
              )
            }
          </div>
        </form>
    )
  }
}

const ContactReduxForm = reduxForm({
  form: 'vintageContactForm'
})(ContactForm);

export default withRecaptcha(ContactReduxForm);
