import { connect } from 'react-redux';
import {change} from 'redux-form';
import CreditCardReduxForm from "../";
import {closeCreditCardModal} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account,
    selectedCreditCard: state.account.pages.creditCards.selectedCreditCard,
    initialValues: state.account.pages.creditCards.selectedCreditCard,
    selectedAddress: state.account.pages.addressBook.selectedAddress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeCreditCardModal: () => dispatch(
        closeCreditCardModal()
    ),
    changeFieldValue: (field, value) => {
      dispatch(
          change('accountCreditCardForm', field, value)
      );
    },
    changeFieldValueForAddress: (field, value) => {
      dispatch(
          change('accountCreditCardForm', `address.${field}`, value)
      );
    },
    changeReCaptcha: (value) => dispatch(change('accountCreditCardForm', 'recaptcha', value))
  };
};

const CreditCardFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardReduxForm);

export default CreditCardFormContainer;