import React, {Component} from 'react';
import {apiList} from "services/api";
import HomePromo from "modules/Home/components/HomePromos/components/HomePromo";

class HomePromos extends Component {
  // TODO put these promos in the global reducer's state
  constructor(props) {
    super(props);

    this.state = {
      promos: null
    }
  }

  componentDidMount() {
    this.fetchPromos();
  }

  fetchPromos() {
    const promise = apiList('sitePromos');
    return promise.then(response => {
      this.setState({
        promos: response.data
      });
    });
  }


  render() {
    const {promos} = this.state;

    return (
        <div className="home-promos__container col-md-12">
          <div className="row">
            {
              promos && promos.map((promo, index) => (
                  <div
                      key={index}
                      className="col-md-4">
                    <HomePromo
                        promo={promo}
                        key={index}/>
                  </div>
              ))
            }
          </div>
        </div>
    )
  }
}

export default HomePromos
