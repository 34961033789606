import {connect} from "react-redux";
import ValidateEmail from "../index";
import {closeModal} from "modules/MyAccount/scenes/OrderHistory/actions"

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: name => dispatch(closeModal(name))
  };
};

const ValidateEmailContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidateEmail);

export default ValidateEmailContainer;