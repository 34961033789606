import {connect} from "react-redux";
import CouponForm from "../index";
import {receiveDiscountCode} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveDiscountCode: discountCode => dispatch(receiveDiscountCode(discountCode))
  };
};

const CouponFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponForm);

export default CouponFormContainer;
