import { connect } from 'react-redux';
import Wishlist from "../";
import {receiveWishListItems} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveWishListItems: wishListItems => dispatch(receiveWishListItems(wishListItems))
  };
};

const WishlistContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Wishlist);

export default WishlistContainer;