import { connect } from 'react-redux';
import Contact from "../";
import {createContactMessage} from "modules/contact/actions";

const mapStateToProps = (state) => {
  return {
    contactUs: state.contactUs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createContactMessage: (data) => dispatch(createContactMessage(data))
  };
};

const ContactContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);

export default ContactContainer;