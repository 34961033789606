/* global dataLayer */

import React, {useEffect, useState} from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import ProductTabs from "./components/ProductTabs";
import AddToCartButtonContainer from "./components/AddToCartButton/container";
import QuantityInput from "utils/components/QuantityInput/index";
import LoadingCassette from "components/LoadingCassette/index";
import "./styles/ProductDetails.scss";
import SEO from "components/SEO/index";
import {PRODUCTS_DETAILS_CONTEXT} from "components/SEO/constants/contexts";
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton/index";
import ProductImage from "@bit/vintagestock.components.product-image";
import TrustElements from "utils/components/TrustElements";
import ProductContext from '@bit/vintagestock.components.product-context';
import {NEW, USED} from "../ProductsList/components/Product/constants/conditions";
import moment from "moment";
import Moment from "react-moment";
import _ from "lodash";
import ProductIcon from "modules/store/scenes/ProductsList/components/Product/components/ProductIcon";
import {Link} from "react-router-dom";
import SingleProductStoreAvailability
  from "modules/store/scenes/ProductDetails/components/SingleProductStoreAvailability";
import WishlistHeartBtnContainer from "modules/store/components/WishlistHeartBtn/container"
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "modules/store/components/PriceTable/constants/radios"
import PriceTable from "modules/store/components/PriceTable"
import {QUERY_PARAM_PICKUP_AVAILABLE} from "modules/store/scenes/ProductsList/constants/query-params"
import QueryParams from "utils/QueryParams"
import vintageAxios from "@bit/vintagestock.services.vintage-axios/index"
import {useQuery} from "react-query"

export default function ProductDetails({match, location}) {
  const queryParams = new QueryParams(location.search)
  const [product, setProduct] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [condition, setCondition] = useState(null)
  const [activeRadio, setActiveRadio] = useState(null)
  const [platforms, setPlatforms] = useState(null)
  const {data} = useQuery(
      `${match.params.productSlug}`, () =>
          vintageAxios.get(`/products/all/${match.params.productSlug}/`)
              .then(response => response.data)
  )

  /**
   * Keep an eye out on activeRadio changes to set the proper condition
   */
  useEffect(() => {
    setCondition((activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_OFFLINE_USED) ? USED : NEW)
  }, [activeRadio])

  /**
   * Reset the product when the slug changes
   */
  useEffect(() => {
    setProduct(null)
  }, [match.params.productSlug])

  /**
   * After the product is fetched, let's setup this component's state variables
   */
  useEffect(() => {
    if (!product && data) {
      const product = data
      const activeRadioValue = product.total_stock_used > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
          ?
          RADIO_ONLINE_USED
          :
          product.is_pre_order || (product.total_stock_new > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined)
              ?
              RADIO_ONLINE_NEW
              :
              product.pickup_available_used
                  ?
                  RADIO_OFFLINE_USED
                  :
                  RADIO_OFFLINE_NEW
      setActiveRadio(activeRadioValue)
      setCondition((activeRadioValue === RADIO_ONLINE_USED || activeRadioValue === RADIO_OFFLINE_USED) ? USED : NEW)
      setProduct(product)

      // Platforms
      let productsByPlatform = [];
      if (product.products_by_platforms) {
        productsByPlatform = product.products_by_platforms;
        productsByPlatform.push(product);
        productsByPlatform = _.uniqBy(productsByPlatform, product => product.icon);
        productsByPlatform = _.sortBy(productsByPlatform, product => product.icon);
        productsByPlatform = _.filter(productsByPlatform, product => product.icon.indexOf("icon-game") === -1);
      }
      setPlatforms(productsByPlatform)

      // Datalayer
      dataLayer.push({
        "event": "productDetail",
        "ecommerce": {
          "currencyCode": "USD",
          "detail": {
            "products": [{
              "id": product.sku,
              "name": product.name
            }]
          }
        }
      })
    }
  }, [data, queryParams])

  /**
   * Handles click on platform options
   * @param event {Object}
   */
  function handlePlatformBtnClick(event) {
    // to reset this component because the url changes but the product keeps being the same until the api fetches the new one
    setProduct(null);
  }

  return (
      <div>
        <section className="product-details">
          <div className="container">
            <div className="row wrapper">
              <ProductContext.Provider
                  value={{
                    product,
                    condition,
                    setCondition,
                    activeRadio,
                    setActiveRadio
                  }}>
                <div className="col-md-4 left">
                  {
                    product &&
                    <React.Fragment>
                      <WishlistHeartBtnContainer/>
                      <ProductIcon icon={product.icon}/>
                      <ProductImage
                          productName={product.name}
                          productImageUrl={product.details_image_thumbnail}/>
                    </React.Fragment>
                  }
                </div>
                <div className="col-md-8 right">
                  {
                    product ? (
                        <div>
                          <div className="top">
                            <h1 className="title">
                              {product.name}
                              {
                                product.artist &&
                                <span className="title__artist">{product.artist}</span>
                              }
                            </h1>
                            {
                              (activeRadio === RADIO_OFFLINE_NEW || activeRadio === RADIO_OFFLINE_USED) &&
                              <div className="call-us-to-reserve">
                                Call us to reserve and <strong>pick up</strong> in store or <strong>ship</strong> to
                                your door. <strong>*pay over the phone maximum is $100</strong>
                              </div>
                            }
                            {
                              (
                                  moment(product.release_date) > moment(new Date()) &&
                                  product.is_pre_order === true
                              ) &&
                              <p className="release-date">
                                Release date:&nbsp;
                                <Moment format="MMM D, YYYY">
                                  {product.release_date}
                                </Moment>
                                &nbsp;-&nbsp;Pre order now!
                              </p>
                            }
                            <span className="hline"/>
                            <form className="form-inline">
                              <div style={{
                                maxWidth: "280px"
                              }}>
                                <PriceTable/>
                              </div>
                              {
                                (
                                    (platforms && platforms.length > 0) &&
                                    (product.in_stock || (product.pickup_available_new || product.pickup_available_used))
                                ) &&
                                <div className="products-by-platform">
                                  <p className="section-title">
                                    <strong>Platform:</strong>
                                  </p>
                                  {
                                    platforms.map((p, index) =>
                                        <Link
                                            key={index}
                                            to={`/store/products/${p.slug}`}
                                            onClick={handlePlatformBtnClick}>
                                          <ProductIcon
                                              icon={p.icon}
                                              withoutWrapper/>
                                        </Link>
                                    )
                                  }
                                </div>
                              }
                              {
                                product.is_pre_order &&
                                <div className="product-shipping-date">
                                  <p className="section-title"><strong>Shipping:</strong></p>
                                  <p>
                                    Processing time: <strong><i>After the release date</i></strong> - Ships in 24
                                    hours<span className="separator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>Shipping time: (5-8
                                    business days)
                                  </p>
                                </div>
                              }
                              <div className="form-group product-actions-buttons">
                                {
                                  // Show Add to cart button
                                  (
                                      product.in_stock ||
                                      (product.pickup_available_new || product.pickup_available_used) ||
                                      product.is_pre_order
                                  )
                                      ?
                                      <React.Fragment>
                                        {
                                          (activeRadio === RADIO_ONLINE_NEW || activeRadio === RADIO_ONLINE_USED)
                                              ?
                                              <React.Fragment>
                                                <div className="form-group__quantity">
                                                  <label htmlFor="productDetailsQuantity">Qty:&nbsp;&nbsp;</label>
                                                  <QuantityInput
                                                      quantity={quantity}
                                                      condition={condition}
                                                      onQuantityChanges={setQuantity}
                                                      stockNew={product.total_stock_new}
                                                      stockUsed={product.total_stock_used}/>
                                                </div>
                                                <AddToCartButtonContainer
                                                    contentTypeId={product.content_type}
                                                    productId={product.id}
                                                    quantity={quantity}
                                                    productData={{
                                                      sku: product.sku,
                                                      name: product.name
                                                    }}
                                                    condition={condition}
                                                    customLabel={product.is_pre_order ? "PRE-ORDER" : null}/>
                                              </React.Fragment>
                                              :
                                              <SingleProductStoreAvailability/>
                                        }
                                      </React.Fragment>
                                      :
                                      <NotifyMeWhenAvailableButton
                                          condition={condition}
                                          productId={product.id}/>
                                }
                              </div>
                              <div className="product-trust-elements">
                                <TrustElements direction="row"/>
                              </div>
                            </form>
                          </div>
                          <ProductTabs
                              product={product}/>
                        </div>
                    ) : (
                        <div className="product-details__loading">
                          <LoadingCassette/>
                        </div>
                    )
                  }
                </div>
              </ProductContext.Provider>
            </div>
          </div>
        </section>
        <NewsletterSubscribeContainer/>
        {
          product &&
          <SEO url='store/products/productSlug'
               context={PRODUCTS_DETAILS_CONTEXT}
               ogImage={product.details_image_thumbnail}
               ogImageWidth={322}
               ogImageHeight={455}
               information={{
                 title: product.name
               }}/>
        }
      </div>
  );
}
