import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles/CheckoutAddressList.scss";
import Address from "modules/MyAccount/scenes/AddressBook/components/Address/index";
import {DEFAULT_BY_SHIPPING} from "modules/MyAccount/scenes/AddressBook/components/Address/constants";
import AddAddressModalContainer from "modules/MyAccount/scenes/AddressBook/components/AddAddressModal/container";

class CheckoutAddressList extends Component {
  constructor() {
    super();

    this.handleRadioButtonChange = address => this._handleRadioButtonChange.bind(this, address);
    this.handleEditAddressButtonClick = address => this._handleEditAddressButtonClick.bind(this, address);
    this.handleUseThisAddressButtonClick = this.handleUseThisAddressButtonClick.bind(this);
  }

  /**
   * Handles Radio button change to dispatch selectShippingAddress action.
   * @param address
   * @param event
   * @private
   */
  _handleRadioButtonChange(address, event) {
    this.props.selectShippingAddress(address)
  }

  /**
   * Handles EditAddress button to dispatch openAddressModal and selectAddress actions to edit that address in the
   * modal
   * @param address
   * @private
   */
  _handleEditAddressButtonClick(address) {
    this.props.openAddressModal();
    this.props.selectAddress(address);
  }

  /**
   * Handles USE THIS ADDRESS button to calls Address.setAddressAsDefault method, sending DEFAULT_BY_SHIPPING constant
   * and calls onAfterSelectAddress prop function.
   */
  handleUseThisAddressButtonClick() {
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    Address.setAddressAsDefault(selectedShippingAddress, DEFAULT_BY_SHIPPING);
    this.props.onAfterSelectAddress();
  }

  render () {
    const {addresses} = this.props.account;
    const {selectedShippingAddress} = this.props.account.pages.addressBook;

    return (
        <div className="checkout-address-list">
          {
            addresses.map(address =>
                <div
                    key={`checkoutAddress${address.id}`}
                    className="radio">
                  <label>
                    <input
                        type="radio"
                        name="selectedAddress"
                        value={address.id}
                        onChange={this.handleRadioButtonChange(address)}
                        checked={selectedShippingAddress === address}/>
                    <span className="bold">{address.first_name} {address.last_name}</span> <span>{address.address_1}, {address.city_name}, {address.region_name} {address.country_code} {address.zip_code}</span>
                  </label>
                  <button style={{
                    border: 'none',
                    padding: '0 10px'
                  }} onClick={this.handleEditAddressButtonClick(address)}><span>Edit</span></button>
                </div>
            )
          }
          <hr/>
          <div className="checkout-step__body--actions">
            <button
                onClick={this.props.openAddressModal}
                className="btn-custom btn-custom--gray">
              <span>ADD NEW ADDRESS</span>
            </button>
            <button
                onClick={this.handleUseThisAddressButtonClick}
                className="btn-custom">
              <span>USE THIS ADDRESS</span>
            </button>
          </div>
          <AddAddressModalContainer/>
        </div>
    )
  }
}

CheckoutAddressList.propTypes = {
  onAfterSelectAddress: PropTypes.func.isRequired
};

export default CheckoutAddressList
