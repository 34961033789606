import * as types from '../actions/types';
import {getProductsViewAs} from "modules/store/scenes/ProductsList/services/products-interface/index";
import {getParsedQueryParams} from "services/browser-history/index";
import {QUERY_PARAM_PAGE} from "modules/store/scenes/ProductsList/constants/query-params";
import {getPageSize} from "services/pagination/index";

export const initialState = {
  viewAs: getProductsViewAs(),
  page: parseInt(getParsedQueryParams()[QUERY_PARAM_PAGE], 10) || 1,
  pageSize: getPageSize(),
  platforms: null,
  price: null
};

// TODO move products from store reducer to here

export const productsList = (state=initialState, action) => {
  switch(action.type){
    case types.PRODUCTS_VIEW_AS_TOGGLE:
      return {
        ...state,
        viewAs: action.viewAs
      };
    case types.PRODUCTS_LIST_UPDATE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case types.RECEIVE_PLATFORMS:
      return {
        ...state,
        platforms: action.platforms
      };
    case types.RECEIVE_PRICE:
      return {
        ...state,
        price: action.price
      };
    case types.RECEIVE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize
      };
    default:
      return state;
  }
};