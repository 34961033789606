import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import "./styles/CreditCardList.scss";
import SingleCreditCardContainer from "modules/store/scenes/CheckoutPage/components/CheckoutPaymentMethod/components/SingleCreditCard/container/index";

class CreditCardList extends Component {
  constructor() {
    super();

    this.handleCreditCardChange = creditCard => this._handleCreditCardChange.bind(this, creditCard);
  }

  _handleCreditCardChange(creditCard) {
    this.props.setCreditCardAsDefault(creditCard)
  }

  render() {
    const {creditCards, selectedCreditCard} = this.props;

    return (
        <div className="credit-card-list">
          <div className="row radio">
            {
              creditCards.map(creditCard => (
                  <div
                      className="col-md-6"
                      key={`checkoutPaymentMethod${creditCard.id}`}>
                    <div
                        className={classNames({
                          "credit-card-list__card payment-method__credit-card--details": true,
                          "credit-card-list__selected": selectedCreditCard === creditCard
                        })}>
                      <label>
                        <div className="credit-card-list__card--input">
                          <input
                              type="radio"
                              name="creditCard"
                              value={0}
                              onChange={this.handleCreditCardChange(creditCard)}
                              checked={creditCard.id === selectedCreditCard.id}/>
                        </div>
                        <SingleCreditCardContainer
                            creditCard={creditCard}/>
                      </label>
                    </div>
                  </div>
              ))
            }
          </div>
          <hr/>
          <div className="checkout-step__body--actions">
            <button
                onClick={this.props.openCreditCardModal}
                className="btn-custom btn-custom--gray"><span>ADD NEW CREDIT CARD</span>
            </button>
            <button
                onClick={this.props.onUseThisCreditCardButtonClick}
                className="btn-custom"><span>USE THIS CREDIT CARD</span>
            </button>
          </div>
        </div>
    )
  }
}

CreditCardList.propTypes = {
  onUseThisCreditCardButtonClick: PropTypes.func.isRequired,
  creditCards: PropTypes.array,
  selectedCreditCard: PropTypes.object
};

export default CreditCardList
