import * as types from '../actions/types';
import {NEW, USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";

export const initialState = {
  // TODO remove this from here
  products: null,
  productsCount: 0,
  selectedProduct: null,
  cart: {
    cart_items: [],
  },
  advancedSearch: null,
  cartTotals: {},
  loadingCart: false,
  searchByCategory: null
};

export const store = (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCTS_RECEIVE:
      return {
        ...state,
        products: action.products
      };
    case types.PRODUCTS_RECEIVE_REQUEST:
      return {
        ...state,
        products: null
      };
    case types.PRODUCTS_COUNT_SET:
      return {
        ...state,
        productsCount: action.productsCount
      };
    case types.CART_ITEMS_FETCH_REQUEST:
      return {
        ...state,
        cart: initialState.cart,
        loadingCart: true
      };
    case types.CART_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        // This conditional prevents setting cart to an empty array. That's what is being returned by the API when
        // the user does not have a cart.
        cart: action.cart.cart_items ? action.cart : initialState.cart,
        loadingCart: false
      };
    case types.CART_ITEMS_FETCH_ERROR:
      return {
        ...state,
        cart: initialState.cart,
        loadingCart: false
      };
    case types.CART_ITEM_UPDATE_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.map(item => {
            if (item.id === action.item.id)
              item.quantity = action.newQuantity;
            return item;
          })
        }
      };
    case types.CART_ITEM_EMPTY_STOCK:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.map(item => {
            if (item.id === action.item.id) {
              if (item.condition === NEW)
                item.product_total_stock_new = 0;
              if (item.condition === USED)
                item.product_total_stock_used = 0;
            }
            return item;
          })
        }
      };
    case types.CART_SINGLE_ITEM_RECEIVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items === [] ? [action.item] : [
            // Keep all items excluding action.item
            ...state.cart.cart_items.filter(cartItem => cartItem.id !== action.item.id),
            // Append action.item (with updated quantity)
            action.item
          ]
        }
      };
    case types.CART_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.filter(cartItem => cartItem.id !== action.cartItem.id)
        }
      };
    case types.CART_TOTALS_RECEIVE:
      return {
        ...state,
        cartTotals: action.totals
      };
    case types.DISCOUNT_CODE_RECEIVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          discount_code_data: action.discountCode
        }
      };
    case types.CART_EMPTY:
      return {
        ...state,
        cart: initialState.cart
      };
    case types.ADVANCED_SEARCH_SELECT:
      return {
        ...state,
        advancedSearch: action.advancedSearch
      };
    case types.PRODUCTS_SEARCH_BY_CATEGORY:
      return {
        ...state,
        searchByCategory: action.category
      };
    default:
      return state;
  }
};