import React from 'react';
import PropTypes from 'prop-types';
import {NEW} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";
import {
  CANCELLED,
  NORMAL,
  RETURNED
} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/order-item-type";
import classNames from "classnames/bind";

const InvoiceModalItem = ({item, type}) => (
    <div className={classNames({
      "item": true,
      "readOnly": type === RETURNED || type === CANCELLED
    })}>
      <div className="photo">
        <img
            src={item.profile_picture}
            alt={item.product_name}/>
      </div>
      <div className="content">
        <p>{item.product_name}</p>
        <p>
          <span className="cond">
            {item.condition === NEW ? 'NEW' : 'USED'}
          </span>
          <span className="qty">
            Qty: {item.quantity}
          </span>
        </p>
        <p>
          {
            type && (type === RETURNED || type === CANCELLED) && (
                <span className="cond">
                {type.toUpperCase()}
              </span>
            )
          }
        </p>
      </div>
      <div className="price">
        <p>${item.price}</p>
      </div>
    </div>
);

InvoiceModalItem.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.oneOf([NORMAL, RETURNED, CANCELLED])
};

export default InvoiceModalItem;