import { connect } from 'react-redux';
import CartItem from "../";
import {deleteCartItem, updateItemQuantity} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCartItem: cartItem => dispatch(deleteCartItem(cartItem)),
    updateItemQuantity: (cartItem, newQuantity) => dispatch(updateItemQuantity(cartItem, newQuantity)),
  };
};

const CartItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartItem);

export default CartItemContainer;