import React from 'react';
import PropTypes from 'prop-types';
import "./styles/OrderTotals.scss";

const OrderTotals = ({order}) => (
    <table className="table order-totals">
      <colgroup>
        <col className="col-md-6 col-xs-6"/>
        <col className="col-md-6 col-xs-6"/>
      </colgroup>
      <tbody>
      <tr className="order-totals__section order-totals__section--subtotal">
        <td>SUBTOTAL</td>
        <td/>
        <td className="text-right">${order.sub_total}</td>
      </tr>
      {
        (order.discount_code_data && parseFloat(order.discount_fee) > 0) &&
        <tr className="order-totals__section order-totals__section--discount">
          <td>COUPON APPLIED: {order.discount_code_data.code}</td>
          <td/>
          <td className="text-right">-${order.discount_fee}</td>
        </tr>
      }
      <tr className="order-totals__section order-totals__section--shipping">
        <td>SHIPPING</td>
        <td/>
        <td className="text-right">${order.shipping_fee}</td>
      </tr>
      <tr className="order-totals__section order-totals__section--taxes">
        <td>TAXES</td>
        <td/>
        <td className="text-right">${order.sale_tax}</td>
      </tr>
      <tr className="order-totals__section order-totals__section--total">
        <td/>
        <td>TOTAL</td>
        <td className="text-right">${order.total}</td>
      </tr>
      </tbody>
    </table>
);

OrderTotals.propTypes = {
  order: PropTypes.object.isRequired
};

export default OrderTotals;
