import { connect } from 'react-redux';
import AdvancedSearch from "modules/store/scenes/AdvancedSearch/index";
import {receiveAdvancedSearch} from "modules/store/actions/index";

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAdvancedSearch: advancedSearch => dispatch(receiveAdvancedSearch(advancedSearch))
  };
};

const AdvancedSearchContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearch);

export default AdvancedSearchContainer;