import React, {Component} from 'react';

class CheckoutShippingMethod extends Component {
  constructor() {
    super();

    this.state = {
      // TODO by now this is the only option, handle when more options are available...
      selectedShippingMethod: 1
    };

    this.handleChangeShippingMethod = this.handleChangeShippingMethod.bind(this);
  }

  handleChangeShippingMethod() {
    // do nothing by now...
  }

  render() {
    const {cartTotals} = this.props.store;
    const {selectedShippingMethod} = this.state;
    
    return (
        <div className="checkout-step checkout-step--shipping-method">
          <div className="checkout-step__header">
            <h2>
              <span className="circle">2</span><span>Shipping Method</span>
            </h2>
          </div>
          <div className="checkout-step__body">
            <form method="post" action=".">
              <div className="radio">
                <label>
                  <input
                      type="radio"
                      name="selectedShippingMethod"
                      value={1}
                      onChange={this.handleChangeShippingMethod}
                      checked={selectedShippingMethod === 1}/>
                  <span className="bold">Standard Shipping</span>
                  {
                    !cartTotals.shipping_fee ? (
                        <span className="price">$4.95</span>
                    ) : (
                        parseFloat(cartTotals.shipping_fee) > 0 && (
                            <span className="price">${cartTotals.shipping_fee}</span>
                        )
                    )
                  }
                </label>
              </div>
            </form>
          </div>
        </div>
    )
  }
}

CheckoutShippingMethod.propTypes = {};

export default CheckoutShippingMethod