import React from 'react'
import queryString from "query-string"
import {GIFT_CARDS, PRODUCT_CATEGORIES} from "modules/store/scenes/ProductsList/constants/product-categories"
import {Link} from "react-router-dom"
import "./styles/HeaderBottomNav.scss"
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_CATEGORIES} from "modules/store/scenes/ProductsList/constants/query-params"

export default function HeaderBottomNav() {
  const queryParams = useQueryParams()

  return (
      <div className="header-bottom-section">
        <div className="container">
          <hr/>
          <ul className="header-bottom-nav">
            {
              PRODUCT_CATEGORIES.map((productCategory, index) => (
                  <React.Fragment key={index}>
                    <li className="header-bottom-nav__item">
                      {
                        productCategory.name === GIFT_CARDS ? (
                            <Link
                                to={productCategory.to}>
                              <span>{productCategory.name.toUpperCase()}</span>
                            </Link>
                        ) : (
                            <Link
                                to={{
                                  pathname: "/store/products",
                                  search: queryParams
                                      .update({
                                        ...productCategory.reactQueryParams
                                      }).asSearchString
                                }}>
                              <span>{productCategory.name.toUpperCase()}</span>
                            </Link>
                        )
                      }
                    </li>
                    {
                      index + 1 !== PRODUCT_CATEGORIES.length &&
                      <li className="header-bottom-nav__item header-bottom-nav__item--separator">
                        <span/>
                      </li>
                    }
                  </React.Fragment>
              ))
            }
          </ul>
        </div>
      </div>
  )
}