import { connect } from 'react-redux';
import MyProfileForm from "../";

const mapStateToProps = (state) => {
  return {
    initialValues: state.account.customer.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const MyProfileFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfileForm);
export default MyProfileFormContainer;
