import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import HomeFeaturedProducts from "./components/HomeFeaturedProducts";
import HomePromos from "./components/HomePromos/index";
import HomeSlider from "modules/Home/components/HomeSlider/index";
import SEO from "components/SEO/index";
import HomeFeaturedAd from "modules/Home/components/HomeFeaturedAd";
import HomeFeaturedCategories from "modules/Home/components/HomeFeaturedCategories";
import {Link} from "react-router-dom";
import "./styles/Home.scss";

const Home = () => {
  return (
    <div className="vintagestock-app__homepage">
      <HomeSlider/>
      <section id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="centered">
                Join our team. <Link to="about/now-hiring" className="home-invite-link">Apply here.</Link>
              </h2>
            </div>
            <div className="clearfix"/>
            <HomePromos/>
            <div className="clearfix"/>
          </div>
        </div>
      </section>
      <HomeFeaturedProducts/>
      <HomeFeaturedAd/>
      <HomeFeaturedCategories/>
      <NewsletterSubscribeContainer/>
      <SEO url=''/>
    </div>
  );
};

export default Home;
