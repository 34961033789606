import React from 'react';
import "./styles/LoadingCassette.scss";

const LoadingCassette = () => {
  return (
      <div className="cassette"/>
  );
}

export default LoadingCassette;
