import React, {useState} from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import LoadingCassette from "components/LoadingCassette";
import SEO from "components/SEO/index";
import {STORE_TYPES} from "modules/Locations/constants/constants"
import StoreFinder from "modules/Locations/components/StoreFinder"
import {Accordion, Panel} from "react-bootstrap"
import Location from "modules/Locations/components/Location"
import AsyncGoogleMap from "modules/Locations/components/AsyncGoogleMap"
import "./styles/Locations.scss"

export function getStoreTypeDisplay(status) {
  for (let [key, value] of STORE_TYPES) {
    if (key === status) return value;
  }
}

export default function Locations() {
  const [activeLocation, setActiveLocation] = useState(null)

  function getHeader(storeType, name) {
    return (
        <div>
          <span
              dangerouslySetInnerHTML={
                {
                  __html: getStoreTypeDisplay(storeType)
                }
              }/>
          &nbsp;-&nbsp;{name}
        </div>
    )
  }

  /**
   * Scrolls to a card inside of the list
   */
  function scrollToCard() {
    const card = document.getElementsByClassName(`location-pane-${activeLocation.id}`)[0]
    const offsetTop = card.offsetTop
    // 214 is top part's height
    document.getElementById("locations-list-accordion").scrollTop = offsetTop - 214 - 30
  }

  return (
      <div className="locations-page">
        <section className="title">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Find your nearest store</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="wrapper locations">
                  <StoreFinder>
                    {
                      (locations, isLoading) => (
                          <div className="row">
                            <div className="col-md-4 left" style={{height: "611px", overflow: "auto"}}>
                              <div className="results">
                                {
                                  isLoading
                                      ?
                                      <LoadingCassette/>
                                      :
                                      (locations && locations.length > 0)
                                          ?
                                          <Accordion
                                              id="locations-list-accordion"
                                              style={{
                                                height: "100%",
                                                overflowY: "scroll"
                                              }}
                                              defaultActiveKey={locations[0].id}
                                              activeKey={activeLocation && activeLocation.id}>
                                            {
                                              locations.map((location, index) => (
                                                  <Panel
                                                      key={index}
                                                      id={`location-pane-${location.id}`}
                                                      eventKey={location.id}
                                                      bsClass={`location-pane-${location.id} panel`}
                                                      header={getHeader(location.store_type, location.name)}
                                                      onClick={() => setActiveLocation(location)}
                                                      onEntered={scrollToCard}>
                                                    <Location
                                                        key={location.id}
                                                        location={location}/>
                                                  </Panel>
                                              ))
                                            }
                                          </Accordion>
                                          :
                                          <p className="text-center">
                                            No locations found for your search.
                                          </p>
                                }
                              </div>
                            </div>
                            <div className="col-md-8 right">
                              {
                                locations &&
                                <AsyncGoogleMap
                                    locations={locations}
                                    activeLocation={activeLocation}
                                    setActiveLocation={setActiveLocation}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8D-unY-MvXdWLGgNqZeNll3K7ohCaelw"
                                    loadingElement={
                                      <LoadingCassette/>
                                    }
                                    containerElement={
                                      <div id="map"/>
                                    }
                                    mapElement={
                                      <div style={{height: `100%`, width: `100%`}}/>
                                    }/>
                              }
                            </div>
                          </div>
                      )
                    }
                  </StoreFinder>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsletterSubscribeContainer/>
        <SEO url='locations'/>
      </div>
  )
}