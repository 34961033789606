import {connect} from 'react-redux';
import ProductsFilterBar from "../";
import {toggleProductsViewAs} from "modules/store/scenes/ProductsList/actions";

const mapStateToProps = (state) => {
  return {
    productsList: state.productsList,
    productsCount: state.store.productsCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleProductsViewAs: viewAs => dispatch(toggleProductsViewAs(viewAs))
  };
};

const ProductsFiltersBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsFilterBar);

export default ProductsFiltersBarContainer;