import React from "react";
import SidebarCategories
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarCategories";
import SidebarConditions
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarConditions";
import SidebarPrice from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarPrice";
import SidebarAvailability
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability";
import SidebarPlatforms
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarPlatforms"
import "./styles/ProductsListSidebar.scss"

export default function ProductsListSidebarV2() {
  return (
      <React.Fragment>
        <SidebarAvailability/>
        <div className="filters">
          <SidebarCategories/>
          <SidebarConditions/>
          <SidebarPlatforms/>
          <SidebarPrice/>
        </div>
      </React.Fragment>
  );
}