import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container/index";
import GiftCardsPublicity from "modules/GiftCards/components/GiftsCardsPublicity/index";
import "./styles/GiftCards.scss";
import GiftCardsItem from "modules/GiftCards/components/GiftCardsItem/index";
import SEO from "components/SEO/index";

const GiftCards = ( ) => (
    <div>
      <section className="page-content">
        <div className="container">
          <div className="gift-cards-disclaimer">
            <h2>
              GIFT CARDS CAN ONLY BE USED FOR IN-STORE PURCHASES, NOT ONLINE.
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <GiftCardsPublicity/>
            </div>
            <div className="col-sm-4">
              <GiftCardsItem
                  price={10}
                  productId={parseInt(process.env.REACT_APP_GIFT_CARD_10_USD_ID, 10)}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <GiftCardsItem
                  price={25}
                  productId={parseInt(process.env.REACT_APP_GIFT_CARD_25_USD_ID, 10)}/>
            </div>
            <div className="col-sm-4">
              <GiftCardsItem
                  price={50}
                  productId={parseInt(process.env.REACT_APP_GIFT_CARD_50_USD_ID, 10)}/>
            </div>
            <div className="col-sm-4">
              <GiftCardsItem
                  price={100}
                  productId={parseInt(process.env.REACT_APP_GIFT_CARD_100_USD_ID, 10)}/>
            </div>
          </div>
        </div>
      </section>
      <NewsletterSubscribeContainer/>
      <SEO url='gift-cards' />
    </div>
);

export default GiftCards;
