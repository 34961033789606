import React from 'react';
import {Link} from "react-router-dom";
import "./styles/HomeFeaturedAd.scss";

const HomeFeaturedAd = props => {
  return (
    <section className="featured-ad__section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="featured-ad__box">
              <span className="box__ad_background"/>
              <div className="row">
                <div className="col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6">
                  <h3 className="box__ad_title">Gift Cards Available</h3>
                  <p className="box__ad_content-text">Give the perfect gift of choice. Gift cards are redeemable in store only.</p>
                  <span className="featured-ad__line"/>
                  <div className="box__ad_link">
                    <Link to="/gift-cards">
                      <span className="box__ad_action_button">BUY NOW!</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFeaturedAd;
