import {createBrowserHistory} from 'history';
import queryString from 'query-string';
import {QUERY_PARAM_PRODUCT_CONDITION} from "modules/store/scenes/ProductsList/constants/query-params";

const vintageHistory = createBrowserHistory();

// Taken from https://stackoverflow.com/a/38340730/923323
const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === undefined || obj[key] === '') && delete obj[key]);
  return obj;
};

/**
 * Retrieves parsed query params in current location
 * @return {Object} queryParams
 */
export const getParsedQueryParams = () => {
  let parsedParams = queryString.parse(vintageHistory.location.search);
  // As condition will be present in the query params as an string, but it's a number, I need to make sure
  // this function returns it as a number always for comparison purposes in the whole app as this function
  // is being used widely to get current query params.
  if (parsedParams[QUERY_PARAM_PRODUCT_CONDITION])
    parsedParams[QUERY_PARAM_PRODUCT_CONDITION] = parseInt(parsedParams[QUERY_PARAM_PRODUCT_CONDITION], 10);
  return parsedParams;
};

/**
 * Update query params in current path.
 * @param {Object} queryParams
 */
export const updateQueryParams = (queryParams, redirectUrl = null) => {
  const currentParams = getParsedQueryParams();
  const newParams = Object.assign(currentParams, queryParams);
  const cleanedParams = removeEmpty(newParams);
  const paramsAsString = queryString.stringify(cleanedParams);
  vintageHistory.push({
    pathname: redirectUrl ? redirectUrl : vintageHistory.location.pathname,
    search: `?${paramsAsString}`
  });
};

/**
 * Clear the query params of url that don't matched with parameters
 * @param {Array} parameters
 * @return {Object} queryParams
 */
export const clearQueryParamFromUrl = (parameters) => {
  let queryParams = getParsedQueryParams();

  Object.keys(queryParams).forEach(queryParam => {
    if (!parameters.find(parameter => parameter === queryParam))
      delete queryParams[queryParam];
  });

  return queryParams;
};

export default vintageHistory;
