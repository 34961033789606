import React, { Component } from 'react';
import logo from "assets/img/logo-black-and-white.png";
import "./styles/PlainInvoice.scss";
import vintageAxios from "services/api/index";
import CreditCardInfo from "components/CreditCardInfo/index";
import InvoiceModalAddress from "modules/MyAccount/scenes/OrderHistory/components/InvoiceModal/components/InvoiceModalAddress/index";
import OrderTotals from "modules/MyAccount/scenes/OrderHistory/components/Order/components/OrderTotals/index";
import LoadingCassette from "components/LoadingCassette/index";

class PlainInvoice extends Component {
  constructor(props){
    super(props);

    this.state = {
      order: null
    };
  }

  fetchOrder(){
    const {orderUUID} = this.props.match.params;
    const promise = vintageAxios.get(`/management/orders/${orderUUID}/`);
    promise.then(response => {
      this.setState({
        order: response.data
      });
    });
    return promise;
  }

  componentDidMount(){
    this.fetchOrder();
  }

  render(){
    const {order} = this.state;

    if( !order )
      return <LoadingCassette/>;

    return(
        <div className="plain-invoice">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2">
                <div className="plain-invoice__logo">
                  <img src={logo} alt="VintageStock"/>
                </div>
                <table className="table">
                  <tbody className="plain-invoice__details">
                  <tr>
                    <td colSpan={2}>
                      Order No.
                      <br/>
                      {order.uuid}
                    </td>
                    <td colSpan={2}>
                      Placed on:
                      <br/>
                      {`${new Date(order.created_at).toDateString()}`}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Shipping Method:
                      <br/>
                      Best Way - Standard
                    </td>
                    <td colSpan={2}>
                      Payment Method:
                      <br/>
                      <CreditCardInfo
                          creditCard={order.credit_card_data}/>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Shipping Address:
                      <br/>
                      <InvoiceModalAddress
                          address={order.shipping_address_data}/>
                    </td>
                    <td colSpan={2}>
                      Billing Address:
                      <br/>
                      <InvoiceModalAddress
                          address={order.billing_address_data}/>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <p className="text-center text-uppercase">
                        Invoice Summary
                      </p>
                    </td>
                  </tr>
                  </tbody>
                  <tbody className="plain-invoice__items">
                  <tr>
                    <td>ITEM</td>
                    <td>SKU</td>
                    <td>QTY</td>
                    <td className="text-right">PRICE</td>
                  </tr>
                  {
                    order.items.normal.map(item => (
                        <tr key={`orderItem${item.order_item_data.id}`}>
                          <td>{item.order_item_data.product_name}</td>
                          <td>{item.order_item_data.product_sku}</td>
                          <td>{item.order_item_data.quantity}</td>
                          <td className="text-right">{item.order_item_data.price}</td>
                        </tr>
                    ))
                  }
                  </tbody>
                  <tbody className="plain-invoice__totals">
                  <tr>
                    <td/>
                    <td/>
                    <td colSpan={2}>
                      <OrderTotals
                          order={order}/>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <footer className="plain-invoice__footer">
                  <p className="text-center">
                    Thank you, {process.env.REACT_APP_SITE_NAME}.
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default PlainInvoice;
