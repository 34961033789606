import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import "./styles/ProductImage.scss";

const ProductImage = ({productName, productImageUrl, productUrl, onClick}) => {
  return (
    <div className="product-image__container">
      <div className="product-image__link">
        {
          productUrl ?
            <Link
              to={productUrl}
              onClick={onClick}>
              <img
                src={productImageUrl}
                alt={productName}
                className="img-responsive"/>
            </Link>
            :
            <img
              src={productImageUrl}
              alt={productName}
              className="img-responsive"/>

        }
      </div>
    </div>
  )
};

ProductImage.propTypes = {
  productName: PropTypes.string.isRequired,
  productImageUrl: PropTypes.string.isRequired,
  productUrl: PropTypes.string,
  onClick: PropTypes.func
};

export default ProductImage
