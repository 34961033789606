import React, {Component} from 'react';
import AddressContainer from "./components/Address/container";
import {apiList} from "services/api";
import LoadingCassette from "components/LoadingCassette";
import AddAddressModalContainer from "./components/AddAddressModal/container";
import SEO from "components/SEO/index";

class AddressBook extends Component {
  componentDidMount() {
    this.fetchAddresses();
  }

  fetchAddresses() {
    const addressesPromise = apiList('addresses');
    addressesPromise.then((response) => {
      this.props.receiveAddresses(response);
    });
    return addressesPromise;
  }


  render() {
    const {account, openAddressModal} = this.props;

    return (
        <div>
          <div className="wrapper addresses">
            <div className="filters">
              <button className="btn-custom" onClick={openAddressModal}><span
                  className="icon icon-plus"/><span>ADD</span></button>
            </div>
            <h2><span className="icon icon-address-book"/><span className="text">Address Book</span></h2>
            {
              account.addresses === null ? (
                  <LoadingCassette/>
              ) : (
                  account.addresses.length > 0 ? (
                      <div className="row">
                        {
                          account.addresses.map((address) => (
                              <div
                                  className="col-md-6 col-sm-12"
                                  key={`accountsAddress${address.id}`}>
                                <AddressContainer
                                    address={address}/>
                              </div>
                          ))
                        }
                      </div>
                  ) : (
                      <div className="empty-message">
                        <h1>Your Address Book is Empty.</h1>
                        <p>Looks like you haven’t add any address yet.</p>
                        <div className="footer">
                          <div className="bg addresses"/>
                          <p><button className="btn-custom" onClick={() => openAddressModal()}>ADD AN ADDRESS</button></p>
                        </div>
                      </div>
                  )
              )
            }
          </div>
          <AddAddressModalContainer/>
          <SEO url='my-account/address-book' />
        </div>
    )
  }
}

export default AddressBook