import { connect } from 'react-redux';
import ReturnItemsModal from "../index";
import {
  closeModal, updateSelectedOrder,
} from "modules/MyAccount/scenes/OrderHistory/actions/index";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: name => dispatch(closeModal(name)),
    updateSelectedOrder: order => dispatch(updateSelectedOrder(order)),
  };
};

const ReturnItemsModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReturnItemsModal);

export default ReturnItemsModalContainer;