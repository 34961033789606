import {
  PRODUCTS_LIST_AS_LIST,
  PRODUCTS_LIST_VIEW_AS_LOCAL_STORAGE_KEY
} from "modules/store/scenes/ProductsList/services/products-interface/constants";

/**
 * Stores the view as in local storage.
 * @param {String} viewAs
 */
export const saveProductsViewAs = viewAs => {
  localStorage.setItem(PRODUCTS_LIST_VIEW_AS_LOCAL_STORAGE_KEY, viewAs);
};

/**
 * Get the view as stored in local storage, fallbacks to default view as.
 * @returns {String} viewAs
 */
export const getProductsViewAs = () => {
  const viewAs = localStorage.getItem(PRODUCTS_LIST_VIEW_AS_LOCAL_STORAGE_KEY);
  return viewAs || PRODUCTS_LIST_AS_LIST;
};