import React from 'react';
import PropTypes from 'prop-types';
import OrderItem from "../OrderItem";


const OrderItemList = ({itemList}) => {
  /**
   * Return Item List (normal, shipment, cancelled or returned) of an order
   * @param {Array} itemList
   */
  return itemList.map(item =>
      <OrderItem
          key={`orderItem${item.order_item_data.id}`}
          item={item.order_item_data}/>
  );
};

OrderItemList.propTypes = {
  itemList: PropTypes.array.isRequired
};

export default OrderItemList;