import React from 'react'
import "./styles/SubHeader.scss"
import {RecentlyViewedDropdown} from "components/Header/components/RecentlyViewedDropdown"
import HeaderCategoriesDropdownContainer from "components/Header/components/HeaderCategoriesDropdown/container"
import HeaderUserDropdownContainer from "components/Header/components/HeaderUserDropdown/container"
import HeaderCartDropdownContainer from "components/Header/components/HeaderCartDropdown/container"
import HeaderBottomNav from "components/Header/components/HeaderBottomNav"
import HeaderSearchV2 from "components/Header/components/HeaderSearchV2"

export default function SubHeader() {
  return (
    <div className="sub-header__container">
      <div className="top__section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="sub-header__menu top__left-menu">
                <li className="menu__item">
                  <RecentlyViewedDropdown/>
                </li>
                <li className="menu__item">
                  <HeaderCategoriesDropdownContainer/>
                </li>
              </ul>
              <HeaderSearchV2/>
              <ul className="sub-header__menu top__right-menu">
                <HeaderUserDropdownContainer/>
                <li>
                  <span className="menu_vline"/>
                </li>
                <HeaderCartDropdownContainer/>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <HeaderBottomNav/>
    </div>
  )
}