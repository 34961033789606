import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/TrustItem.scss";

const TrustItem = ({iconClassName, text, align}) => (
    <div className={classNames({
      "trust-item": true,
      "trust-item--align-center": align === "center",
      "trust-item--align-left": align === "left"
    })}>
      <div className="trust-item__icon">
        <i className={iconClassName}/>
      </div>
      <div className="trust-item__text">
        {text}
      </div>
    </div>
);

TrustItem.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  align: PropTypes.oneOf(["left", "center"]).isRequired
};

TrustItem.defaultProps = {
  align: "center"
};

export default TrustItem;
