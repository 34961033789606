import React, {Component} from 'react';

import {CATEGORIES_SHOW} from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/constants/constants";
import vintageAxios from "services/api";

import LoadingCassette from "components/LoadingCassette";
import FeaturedCategoryItem from "modules/Home/components/HomeFeaturedCategories/components/FeaturedCategoryItem";

import "./styles/HomeFeaturedCategories.scss";

class HomeFeaturedCategories extends Component {
  constructor(props){
    super(props);

    this.state = {
      categories: null
    }
  }

  componentDidMount() {
    this.fetchCategories();
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const promise = vintageAxios.get('/products/categories/');
    promise.then(response => {
      /*Categories list to show*/
      const categories = response.data.filter(item => {
        return [...CATEGORIES_SHOW, "Consoles & Hardware"].find(
          category => category === item.name
        ) ? item : undefined;
      });
      this.setState({
        categories
      });
    });
    return promise;
  }

  render() {
    const {categories} = this.state;

    if (!categories)
      return (
        <LoadingCassette/>
      );
    else
      return(
        <section className="featured-categories">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="featured-categories__title">SEARCH FOR YOUR FAVORITE PRODUCTS NOW!</h3>
              </div>
              <div className="clearfix"/>
              <div className="col-md-12">
                <div className="featured-categories__grid">
                  <FeaturedCategoryItem
                    category={categories.find(e => e.name === 'Movies')}
                    containerClassNames="background--is-red"
                    iconClassNames="movies"
                  />
                  <FeaturedCategoryItem
                    category={categories.find(e => e.name === 'Games')}
                    containerClassNames="background--is-green"
                    iconClassNames="games"
                  />
                  <FeaturedCategoryItem
                    category={categories.find(e => e.name === 'Music')}
                    containerClassNames="background--is-yellow"
                    iconClassNames="music"
                  />
                  <FeaturedCategoryItem
                    category={categories.find(e => e.name === 'Toys')}
                    containerClassNames="background--is-pink"
                    iconClassNames="toys"
                  />
                  <FeaturedCategoryItem
                    category={categories.find(e => e.name === 'Posters')}
                    containerClassNames="background--is-navy"
                    iconClassNames="posters"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );
  }
}

export default HomeFeaturedCategories;
