import * as types from '../actions/types';

export const initialState = {
  loading: false,
  success: null,
  errors: {},
  modalIsOpen: false
};

export const newsletter = (state = initialState, action) => {
  switch(action.type){
    case types.NEWSLETTER_SUBSCRIBE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.NEWSLETTER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        modalIsOpen: false
      };
    case types.NEWSLETTER_SUBSCRIBE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.response,
        modalIsOpen: false
      };
    case types.NEWSLETTER_MODAL_OPEN:
      return {
        ...state,
        modalIsOpen: true
      };
    case types.NEWSLETTER_MODAL_CLOSE:
      return {
        ...state,
        modalIsOpen: false
      };
    case types.NEWSLETTER_RESET:
      return {
        ...state,
        loading: false,
        success: null,
        errors: {},
        modalIsOpen: false
      };
    default:
      return state;
  }
};