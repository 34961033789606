import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import "./styles/ProductsList.scss";
import CategoryTitleContainer from "modules/store/scenes/ProductsList/components/CategoryTitle/container";
import ProductsListSidebarV2 from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2";
import ProductsGridContainer from "modules/store/scenes/ProductsList/components/ProductsGrid/container";
import ProductsFiltersBarContainer from "modules/store/scenes/ProductsList/components/ProductsFiltersBar/container";

export default function ProductsList() {

  return (
      <React.Fragment>
        <section className="products">
          <div className="container">
            <div>
              <CategoryTitleContainer/>
            </div>
            <div className="row">
              <div className="products__products-list-sidebar col-md-3 col-sm-3">
                <ProductsListSidebarV2/>
              </div>
              <div className="col-md-9 col-sm-9">
                <ProductsFiltersBarContainer/>
                <hr/>
                <ProductsGridContainer/>
                <ProductsFiltersBarContainer/>
              </div>
            </div>
          </div>
        </section>
        <NewsletterSubscribeContainer/>
      </React.Fragment>
  )
}