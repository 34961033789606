import * as types from '../actions/types';

export const initialState = {
  categories : null,
  types: null
};

export const headerCategoriesReducer = (state=initialState, action) => {
  switch(action.type){
    case types.CATEGORIES_RECEIVE:
      return {
        ...state,
        categories: action.categories
      };
    case types.TYPES_RECEIVE:
      return {
        ...state,
        types: action.types
      };
    default:
      return state;
  }
};