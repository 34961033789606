import React from 'react';
import PropTypes from "prop-types";

const ToastContent = ({image, text, remove}) => (
    <div className="content">
      <div className="col">
        {
          image &&
          <div className="img" style={{
            backgroundImage: `url(${image})`
          }}/>
        }
        <h6>{text}</h6>
      </div>
    </div>
);

ToastContent.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string.isRequired,
  remove: PropTypes.func
};

export default ToastContent;