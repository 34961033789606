import React from 'react';
import "./styles/Header.scss";
import _ from "lodash";
import MainHeader from "components/Header/components/MainHeader";
import SubHeader from "components/Header/components/SubHeader";
import FetchWishListContainer from "components/Header/components/FetchWishList/container";
import {useLocation} from "react-router"
import AppLogo from "components/AppLogo"

export const liteHeaderWhitelist = [
  /\/auth\/validate-email\/[a-zA-Z0-9-]+/,
  "/auth/validate-email/expired"
];

export function shouldDisplayLiteHeader(pathname) {
  return _.filter(liteHeaderWhitelist, url => {
    if(typeof url === "object")
      return pathname.match(url) !== null
    else
      return url === pathname
  }).length > 0
}

export default function Header() {
  const location = useLocation()
  const liteHeader = shouldDisplayLiteHeader(location.pathname)

  if (liteHeader)
    return (
        <header className="lite-header">
          <div className="container text-center">
            <AppLogo/>
          </div>
        </header>
    )

  return (
      <header>
        <MainHeader/>
        <SubHeader/>
        <FetchWishListContainer/>
      </header>
  )
}
