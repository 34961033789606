import React, {Component} from 'react';
import CreditCardContainer from "./components/CreditCard/container";
import {apiList} from "services/api";
import LoadingCassette from "components/LoadingCassette";
import AddCreditCardModalContainer from "./components/AddCreditCardModal/container";
import SEO from "components/SEO/index";
import {MY_ACCOUNT} from "modules/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/constants";

class CreditCardsInfo extends Component {
  constructor(props) {
    super(props);
    this.openCreditCardModal = this.openCreditCardModal.bind(this);
  }

  openCreditCardModal() {
    this.props.openCreditCardModal()
  }

  fetchCreditCards() {
    const promise = apiList('creditCards');
    promise.then((response) => {
      this.props.receiveCreditCards(response);
    });
    return promise;
  }

  componentDidMount() {
    this.fetchCreditCards();
  }

  render() {
    const {account} = this.props;

    return (
        <div>
          <div className="wrapper payments">
            <div className="filters">
              <button className="btn-custom" onClick={this.openCreditCardModal}>
                <span className="icon icon-plus"/><span>ADD</span>
              </button>
            </div>
            <h2><span className="icon icon-card"/><span className="text">Credit Card Info</span></h2>
            {
              account.creditCards === null ? (
                  <LoadingCassette/>
              ) : (
                  account.creditCards.length > 0 ? (
                      <div className="row">
                        {
                          account.creditCards.map((creditCard) => (
                              <div
                                  className="col-md-6 col-sm-12"
                                  key={`accountsCreditCard${creditCard.id}`}>
                                <CreditCardContainer
                                    creditCard={creditCard}/>
                              </div>
                          ))
                        }
                      </div>
                  ) : (
                      <div className="empty-message">
                        <h1>Your Credit Card Info is Empty.</h1>
                        <p>Looks like you haven’t add any credit card yet.</p>
                        <div className="footer">
                          <div className="bg payments"/>
                          <p><button className="btn-custom" onClick={this.openCreditCardModal}>ADD A CREDIT CARD</button>
                          </p>
                        </div>
                      </div>
                  )
              )
            }
          </div>
          <AddCreditCardModalContainer
              context={MY_ACCOUNT}/>
          <SEO url='my-account/credit-cards-info'/>
        </div>
    )
  }
}

export default CreditCardsInfo