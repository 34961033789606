import React, {Component} from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container/index";
import AdvancedSearchReduxContainer from "modules/store/scenes/AdvancedSearch/components/AdvancedSearchForm/container/index";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history/index";
import SEO from "components/SEO/index";
import {
  PRICE_GTE,
  PRICE_LTE,
  PRICE_NEW_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_GTE,
  PRICE_USED_LTE,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_GENRES, QUERY_PARAM_FORMAT,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_NAME,
  QUERY_PARAM_PLATFORM, QUERY_PARAM_MEDIA_TYPE, QUERY_PARAM_SKU, QUERY_PARAM_CAST
} from "modules/store/scenes/ProductsList/constants/query-params";

class AdvancedSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryParams: null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.resetInitialValues = this.resetInitialValues.bind(this);
  }

  componentDidMount() {
    this.getInitialValues();
  }

  getInitialValues() {
    const esrbRating = getParsedQueryParams()[QUERY_PARAM_ESRB_RATING];
    const mpaaRating = getParsedQueryParams()[QUERY_PARAM_MPAA_RATING];
    const mediaType = getParsedQueryParams()[QUERY_PARAM_MEDIA_TYPE];
    const priceGte = getParsedQueryParams()[PRICE_NEW_GTE] || getParsedQueryParams()[PRICE_USED_GTE];
    const priceLte = getParsedQueryParams()[PRICE_NEW_LTE] || getParsedQueryParams()[PRICE_USED_LTE];

    this.setState({
      queryParams: {
        [QUERY_PARAM_NAME]: getParsedQueryParams()[QUERY_PARAM_NAME],
        [QUERY_PARAM_DESCRIPTION]: getParsedQueryParams()[QUERY_PARAM_DESCRIPTION],
        [QUERY_PARAM_SKU]: getParsedQueryParams()[QUERY_PARAM_SKU],
        [PRICE_GTE]: priceGte,
        [PRICE_LTE]: priceLte,
        [QUERY_PARAM_CAST]: getParsedQueryParams()[QUERY_PARAM_CAST],
        [QUERY_PARAM_GENRES]: getParsedQueryParams()[QUERY_PARAM_GENRES],
        [QUERY_PARAM_MEDIA_TYPE]: typeof mediaType === "object" ? mediaType : [mediaType],
        [QUERY_PARAM_PLATFORM]: getParsedQueryParams()[QUERY_PARAM_PLATFORM],
        [QUERY_PARAM_ESRB_RATING]: typeof esrbRating === "object" ? esrbRating : [esrbRating],
        [QUERY_PARAM_MPAA_RATING]: typeof mpaaRating === "object" ? mpaaRating : [mpaaRating],
        [QUERY_PARAM_FORMAT]: getParsedQueryParams()[QUERY_PARAM_FORMAT],
        [QUERY_PARAM_CATEGORIES]: getParsedQueryParams()[QUERY_PARAM_CATEGORIES]
      }
    });
  }

  /**
   * Reset initial values for AdvancedSearch form
   */
  resetInitialValues() {
    this.setState({
      queryParams: {
        [QUERY_PARAM_NAME]: null,
        [QUERY_PARAM_DESCRIPTION]: null,
        sku: null,
        [PRICE_GTE]: null,
        [PRICE_LTE]: null,
        artist: null,
        [QUERY_PARAM_GENRES]: null,
        [QUERY_PARAM_MEDIA_TYPE]: null,
        [QUERY_PARAM_PLATFORM]: null,
        [QUERY_PARAM_ESRB_RATING]: null,
        [QUERY_PARAM_MPAA_RATING]: null,
        [QUERY_PARAM_FORMAT]: null,
        [QUERY_PARAM_CATEGORIES]: null
      }
    });
  }

  /**
   * Handle submit Advanced Search Form
   * @param data
   */
  handleFormSubmit(data) {
    let queryParams = {};
    Object.assign(queryParams, data);

    // For that could apply filter by both conditions
    queryParams[PRICE_NEW_GTE] = data[PRICE_GTE];
    queryParams[PRICE_USED_GTE] = data[PRICE_GTE];
    queryParams[PRICE_NEW_LTE] = data[PRICE_LTE];
    queryParams[PRICE_USED_LTE] = data[PRICE_LTE];
    // Remove price__gte and price__lte properties for that don't send by query params
    delete queryParams[PRICE_GTE];
    delete queryParams[PRICE_LTE];

    updateQueryParams(queryParams, '/store/products');
    this.props.receiveAdvancedSearch(data);
  }

  render() {
    const {queryParams} = this.state;

    return (
        <div>
          <section className="title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>Advanced Search</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="page-content">
            <div className="container">
              <AdvancedSearchReduxContainer
                  initialValues={queryParams}
                  onSubmit={this.handleFormSubmit}
                  resetInitialValues={this.resetInitialValues}/>
            </div>
          </section>
          <NewsletterSubscribeContainer/>
          <SEO url='advanced-search'/>
        </div>
    )
  }
};

export default AdvancedSearch;