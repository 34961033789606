import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import "./styles/SingleCreditCard.scss";
import CreditCardInfo from "components/CreditCardInfo/index";

class SingleCreditCard extends Component {
  constructor() {
    super();

    this.handleEditButtonClick = this.handleEditButtonClick.bind(this)
  }

  handleEditButtonClick() {
    const {creditCard} = this.props;
    this.props.openCreditCardModal();
    this.props.selectCreditCard(creditCard);
  }

  render () {
    const {creditCard, withBillingInfo} = this.props;

    return (
        <div className={classNames({
          "single-credit-card": true,
          "single-credit-card--billing": withBillingInfo
        })}>
          <p className="single-credit-card__full-name">
            <span className="bold">{creditCard.full_name}</span>
          </p>
          <p className="single-credit-card__info">
            <CreditCardInfo creditCard={creditCard}/>
          </p>
          {
            (withBillingInfo && creditCard.billingAddress) &&
            <p className="single-credit-card__billing-address">
              {creditCard.billingAddress.address_1}{creditCard.billingAddress.address_2 && `, ${creditCard.billingAddress.address_2}`}
              <br/>
              {creditCard.billingAddress.city_name}, {creditCard.billingAddress.region_name} {creditCard.billingAddress.zip_code}
            </p>
          }
          {
            withBillingInfo &&
            <button
                className="single-credit-card__edit-button"
                onClick={this.handleEditButtonClick}>
              <i className="fa fa-pencil"/> Edit
            </button>
          }
        </div>
    )
  }
}

SingleCreditCard.defaultProps = {
  withBillingInfo: false
};

SingleCreditCard.propTypes = {
  creditCard: PropTypes.object.isRequired,
  withBillingInfo: PropTypes.bool
};

export default SingleCreditCard
