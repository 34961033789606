import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import WishlistContainer from "modules/MyAccount/scenes/Wishlist/container/index";

const WishlistShare = () => {
    return (
        <div>
          <section className="page-content">
            <div className="container">
              <div className="tab-content">
                <WishlistContainer/>
              </div>
            </div>
          </section>
          <NewsletterSubscribeContainer/>
          </div>
    )
};

export default WishlistShare;