import React, {Component} from 'react';
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import SEO from "components/SEO/index";
import vintageAxios from "services/api/index";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS,
      errors: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Handles the submit form
   * @param {Object} data
   */
  handleFormSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      const promise = vintageAxios.post("/auth/forgot-password/", data);
      promise.then(() => {
        this.setState({
          status: SUCCESSFUL_STATUS
        }, () => {
          setTimeout(() => {
            this.setState({
              status: INITIAL_STATUS,
              errors: []
            });
          }, 2000);
        });
      });
      promise.catch((error) => {
        this.setState({
          status: INITIAL_STATUS,
          errors: error.response ? error.response.data.email : []
        });
      });
    });
  }

  render() {
    const {status, errors} = this.state;

    return (
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-wrapper">
                  <div className="lightbox">
                    <div className="brand">
                      <Link to="/">
                        <span className="logo"/>
                      </Link>
                    </div>
                    <div className="wrapper">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="forgot-bg"/>
                        </div>
                        <div className="col-md-6">
                          <h1>Forgot your Password?</h1>
                          <p>Please enter your email address below to reset:</p>
                          <ForgotPasswordForm
                              onSubmit={this.handleFormSubmit}
                              status={status}
                              errors={errors}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SEO url='auth/forgot-password'/>
        </div>
    )
  }
}

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    forgotPasswordPage: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      errors: PropTypes.array.isRequired
    })
  })
}
export default ForgotPassword;