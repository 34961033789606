import React, {useContext} from "react"
import PropTypes from "prop-types"
import {Modal} from "react-bootstrap"
import ProductContext from '@bit/vintagestock.components.product-context'
import {NEW, USED} from "@bit/vintagestock.constants.conditions"
// TODO find the way to do this without having to import from /dist
import "./styles/StoreAvailabilityModal.scss"
import SearchBasedAvailability
  from "modules/store/scenes/ProductDetails/components/SingleProductStoreAvailability/components/StoreAvailabilityModal/components/SearchBasedAvailability"
import StoreFinder from "modules/Locations/components/StoreFinder"
import PriceLabel from "modules/store/components/PriceTable/components/PriceLabel"

export default function StoreAvailabilityModal({show, onHide}) {
  const {product, condition} = useContext(ProductContext)

  let stores = []

  // For list view

  if (product.stores && product.stores.length > 0)
    stores = product.stores

  // For detailed product view, the api comes with a different response

  if (condition === NEW && product.stock_by_stores_new && product.stock_by_stores_new.length > 0)
    stores = product.stock_by_stores_new.map(data => data.store.id)

  if (condition === USED && product.stock_by_stores_used && product.stock_by_stores_used.length > 0)
    stores = product.stock_by_stores_used.map(data => data.store.id)

  return (
      <Modal
          show={show}
          onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Save on Shipping! Pickup in store.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="small-product-card">
            <div className="small-product-card__image">
              <img src={product.cart_image_thumbnail} alt={product.name}/>
            </div>
            <div className="small-product-card__name">
              <p>
                {product.name}
              </p>
              <PriceLabel
                  label={condition === NEW ? "new" : "used"}
                  price={condition === NEW ? product.price_new : product.price_used}
                  color={condition === NEW ? "green" : "blue"}
                  hasStock
                  align="left"/>
            </div>
          </div>
          <StoreFinder
              product={product}
              condition={condition}>
            {
              (locations, isLoading) =>
                  <SearchBasedAvailability
                      locations={locations}
                      isLoading={isLoading}
                      stores={stores}/>
            }
          </StoreFinder>
        </Modal.Body>
      </Modal>
  )
}

StoreAvailabilityModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}