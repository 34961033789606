import React from "react"
import PropTypes from "prop-types"
import "./styles/PriceLabel.scss"

export default function PriceLabel({label, hasStock, price, color, align}) {
  return (
      <div className={`price-label price-label--${align}`}>
        <span>{label}</span>
        {
          hasStock
              ?
              <span className={color}>${price}</span>
              :
              <span className="gray">N/A</span>
        }
      </div>
  )
}

PriceLabel.propTypes = {
  label: PropTypes.string.isRequired,
  hasStock: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["blue", "green"]).isRequired,
  align: PropTypes.oneOf(["left", "center"]),
}

PriceLabel.defaultProps = {
  align: "center"
}