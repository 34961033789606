import {NEW, USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions"

/**
 * For some reason as the time happened we ended up with the API receiving condition
 * param as a string but retrieving it as an integer.
 * This function pretends to be an interface to always treat condition as an integer,
 * as it was supposed to be forever.
 * @param {String|Number|undefined|null} condition
 */
export function parseCondition(condition) {
  let parsedCondition

  switch (condition) {
    case 0:
      parsedCondition = NEW
      break
    case 1:
      parsedCondition = USED
      break
    case "new":
      parsedCondition = NEW
      break
    case "used":
      parsedCondition = USED
      break
    case "0":
      parsedCondition = NEW
      break
    case "1":
      parsedCondition = USED
      break
    case undefined:
      parsedCondition = null
      break
    case null:
      parsedCondition = null
      break
    default:
      parsedCondition = null
      break
  }

  return parsedCondition
}
