import React, {Component} from 'react';
import {logout as authLogout} from "services/auth";
import {Redirect} from "react-router-dom";

class Logout extends Component {
  componentDidMount() {
    this.logout();
  }

  logout() {
    this.props.logout();
    this.props.emptyCart();
    this.props.emptyAccountInformation();
    authLogout();
  }

  render() {
    return (
        <Redirect to="/"/>
    );
  }
}

export default Logout;