import React, {Component} from 'react';
import {DropdownButton, MenuItem} from "react-bootstrap";
import {Link} from "react-router-dom";
import vintageAxios from "services/api/index";
import {QUERY_PARAM_CATEGORIES} from "modules/store/scenes/ProductsList/constants/query-params";
import "./styles/HeaderCategoriesDropdown.scss";
import {ACCESSORY, BOOK, OTHER} from "components/Header/components/HeaderCategoriesDropdown/constants/constants";
import {GIFT_CARDS, PRODUCT_CATEGORIES} from "modules/store/scenes/ProductsList/constants/product-categories";

class HeaderCategoriesDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoriesToShow: [
        {
          name: "Movie",
          mainCategoryName: "Movies",
          image: null
        },
        {
          name: "Gaming",
          mainCategoryName: "Games",
          image: null
        },
        {
          name: "Toy",
          mainCategoryName: "Toys",
          image: null
        },
        {
          name: "Poster",
          mainCategoryName: "Posters",
          image: null
        },
        {
          name: "Accessory",
          mainCategoryName: "Consoles & Hardware",
          image: null
        },
        {
          name: "Music",
          mainCategoryName: "Music",
          image: null
        }
      ]
    };
  }

  componentDidMount() {
    this.fetchTypes();
    this.fetchCategories();
  }

  /**
   * Fetch types from the API.
   * @returns {AxiosPromise}
   */
  fetchTypes() {
    const promise = vintageAxios.get('/products/types/');
    promise.then(response => {
      const types = response.data;
      this.props.receiveTypes(types);

      Object.keys(types).map(key => {
        return types[key] !== OTHER && types[key] !== BOOK && types[key] !== ACCESSORY;
      });
    });

    return promise;
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const promise = vintageAxios.get('/products/categories/');
    promise.then(response => {
      this.props.receiveCategories(response.data);
    });
    return promise;
  }

  /**
   * Retrieves a filtered list of categories by type.
   * @param {String} type
   */
  getCategoriesByType(type) {
    const typeAsInt = parseInt(type, 10);
    const categories = this.props.headerCategoriesReducer.categories
        .filter(category => category.product_type === typeAsInt);
    return categories;
  }

  /**
   * Retrieve category by name
   * @param {String} name
   * @return {String} image
   */
  getImageOfCategoryByName(name) {
    const {categoriesToShow} = this.state;
    const category = categoriesToShow.find(category => category.name === name);

    const mainCategory = this.props.headerCategoriesReducer.categories
        .find(mainCategory => mainCategory.name === category.mainCategoryName);
    return mainCategory.image;
  }

  render() {
    const {categories, types} = this.props.headerCategoriesReducer;
    const giftCardCategory = PRODUCT_CATEGORIES.find(category => category.name === GIFT_CARDS);

    return (
        <DropdownButton
            id="HeaderCategoriesDropdown"
            title="PRODUCTS">
          {
            (types && categories) &&
            Object.keys(types).map(key =>
                (types[key] !== OTHER && types[key] !== BOOK && types[key] !== ACCESSORY) &&
                <MenuItem disabled={true}
                          key={`headerType${key}`}
                          componentClass="span"
                          className="dropdown-submenu">
                  {types[key]}
                  <ul
                      className={`dropdown-menu ${types[key].toLowerCase()}`}>
                    <div className="sub-categories"
                         style={{
                           backgroundImage: `url(${this.getImageOfCategoryByName(types[key])})`
                         }}>
                      <h6>{types[key]}</h6>
                      <ul>
                        {
                          this.getCategoriesByType(key).map(category =>
                              <li
                                  key={`category${category.id}`}>
                                <Link
                                    to={`/store/products?${QUERY_PARAM_CATEGORIES}=${category.id}`}>
                                  {category.name}
                                </Link>
                              </li>
                          )
                        }
                      </ul>
                    </div>
                  </ul>
                </MenuItem>
            )
          }
          <MenuItem disabled={true}
                    componentClass="span"
                    className="dropdown-submenu gift-cards">
            <Link
                to={giftCardCategory.to}>
              {giftCardCategory.name}
            </Link>
          </MenuItem>
        </DropdownButton>
    )
  }
}

export default HeaderCategoriesDropdown
