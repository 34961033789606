import React from 'react';

import "./styles/SpamWarning.scss";

const SpamWarning = () => {
  return (
    <div className="container">
      <div className="checkout-success-alert">
        <h4>
          <span><i className="icon-mail"/></span>
          Please check spam filters and add <a href="mailto:no-reply@vintagestock.com">no-reply@vintagestock.com</a> to your address book for the order confirmation/tracking info emails.
        </h4>
      </div>
    </div>
  );
};

export default SpamWarning;
