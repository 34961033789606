import { connect } from 'react-redux';
import AdvancedSearchReduxForm from "modules/store/scenes/AdvancedSearch/components/AdvancedSearchForm/index";
import {change} from "redux-form";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: (field, value) => dispatch(
        change('vintageAdvancedSearchForm', field, value)
    )
  };
};

const AdvancedSearchReduxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearchReduxForm);

export default AdvancedSearchReduxContainer;