import {
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_COMING_SOON,
  QUERY_PARAM_MOST_VIEWED,
  QUERY_PARAM_NEW_THIS_WEEK, QUERY_PARAM_SEARCH,
  VIEW_TYPE,
} from "modules/store/scenes/ProductsList/constants/query-params";

export const NEW_THIS_WEEK = 'New Releases';
export const NEW_THIS_WEEK_SLUG = 'new-this-week';
export const MOST_VIEWED = 'Most Viewed';
export const MOST_VIEWED_SLUG = 'most-viewed';
export const COMING_SOON = 'Coming Soon';
export const COMING_SOON_SLUG = 'coming-soon';
export const ALL = 'All';
export const MOVIES = 'Movies';
export const GAMES = 'Games';
export const POSTERS = 'Posters';
export const TOYS = 'Toys';
export const MUSIC = 'Music';
export const GIFT_CARDS = 'Gift Cards';
export const RECENTLY_VIEWED = 'Recently Viewed';

export const PRODUCT_CATEGORIES = [
  {
    id: NEW_THIS_WEEK_SLUG,
    name: NEW_THIS_WEEK,
    slug: VIEW_TYPE,
    reactQueryParams: {
      [VIEW_TYPE]: QUERY_PARAM_NEW_THIS_WEEK,
      [QUERY_PARAM_CATEGORIES]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    customHeaderHtml: null
  },
  // {
  //   id: MOST_VIEWED_SLUG,
  //   name: MOST_VIEWED,
  //   slug: VIEW_TYPE,
  //   reactQueryParams: {
  //     [VIEW_TYPE]: QUERY_PARAM_MOST_VIEWED,
  //     [QUERY_PARAM_CATEGORIES]: null,
  //     [QUERY_PARAM_SEARCH]: null
  //   },
  //   customHeaderHtml: null
  // },
  // {
  //   id: COMING_SOON_SLUG,
  //   name: COMING_SOON,
  //   slug: VIEW_TYPE,
  //   reactQueryParams: {
  //     [VIEW_TYPE]: QUERY_PARAM_COMING_SOON,
  //     [QUERY_PARAM_CATEGORIES]: null,
  //     [QUERY_PARAM_SEARCH]: null
  //   },
  //   customHeaderHtml: null
  // },
  // {
  //   id: null,
  //   name: ALL,
  //   slug: ALL,
  //   to: '/store/products',
  //   reactQueryParams: {
  //     [ALL]: null
  //   },,
  //   [QUERY_PARAM_SEARCH]: null
  //   customHeaderHtml: null
  // },
  {
    id: 23,
    name: MOVIES,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/movies/',
    reactQueryParams: {
      [QUERY_PARAM_CATEGORIES]: 23,
      [VIEW_TYPE]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    customHeaderHtml: null
  },
  {
    id: 32,
    name: GAMES,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/games/',
    reactQueryParams: {
      [QUERY_PARAM_CATEGORIES]: 32,
      [VIEW_TYPE]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    items: [
      {
        name: 'Console',
        [QUERY_PARAM_CATEGORIES]: 26,
        [VIEW_TYPE]: null
      },
      {
        name: 'Pc',
        [QUERY_PARAM_CATEGORIES]: 27,
        [VIEW_TYPE]: null
      },
      {
        name: 'Handheld Devices',
        [QUERY_PARAM_CATEGORIES]: 30,
        [VIEW_TYPE]: null
      }
    ],
    customHeaderHtml: null
  },
  {
    id: 34,
    name: POSTERS,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/posters/',
    reactQueryParams: {
      [QUERY_PARAM_CATEGORIES]: 34,
      [VIEW_TYPE]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    items: [
      {
        name: 'Console',
        [QUERY_PARAM_CATEGORIES]: 26,
        [VIEW_TYPE]: null
      },
      {
        name: 'Pc',
        [QUERY_PARAM_CATEGORIES]: 27,
        [VIEW_TYPE]: null
      },
      {
        name: 'Handheld Devices',
        [QUERY_PARAM_CATEGORIES]: 30,
        [VIEW_TYPE]: null
      }
    ],
    customHeaderHtml: null
  },
  {
    id: 33,
    name: TOYS,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/toys/',
    reactQueryParams: {
      [QUERY_PARAM_CATEGORIES]: 33,
      [VIEW_TYPE]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    customHeaderHtml: null
  },
  {
    id: 49,
    name: MUSIC,
    slug: QUERY_PARAM_CATEGORIES,
    counter: 0,
    apiUrl: '/products/music',
    reactQueryParams: {
      [QUERY_PARAM_CATEGORIES]: 49,
      [VIEW_TYPE]: null,
      [QUERY_PARAM_SEARCH]: null
    },
    customHeaderHtml: null
  },
  {
    id: 'gift-cards',
    name: GIFT_CARDS,
    slug: 'gift-cards',
    to: '/gift-cards',
    customHeaderHtml: null
  },
];
