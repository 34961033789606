import {connect} from "react-redux";
import CheckoutPaymentMethod from "../index";
import {change} from "redux-form";
import {
  createAddress,
  createAddressFailed,
  createCreditCard,
  editCreditCard,
  selectBillingAddress,
  setCreditCardAsDefault
} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetAddressForm: () => dispatch(
        change('accountCreditCardForm', 'address', {})
    ),
    createAddressFailed: (data, response) => dispatch(createAddressFailed(data, response)),
    createAddress: data => dispatch(createAddress(data)),
    createCreditCard: (data) => dispatch(createCreditCard(data)),
    editCreditCard: (data) => dispatch(editCreditCard(data)),
    selectBillingAddress: address => dispatch(selectBillingAddress(address)),
    setCreditCardAsDefault: address => dispatch(setCreditCardAsDefault(address)),
  };
};

const CheckoutPaymentMethodContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPaymentMethod);

export default CheckoutPaymentMethodContainer;