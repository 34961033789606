import React from 'react';
import PropTypes from 'prop-types';
import "./styles/ProductIcon.scss";
import {
  ALL_ICONS,
  DEFAULT_ICON
} from "./constants/icons";

export default function ProductIcon({icon, withoutWrapper}) {
  if (ALL_ICONS.indexOf(icon) === -1)
    icon = DEFAULT_ICON;

  if (withoutWrapper)
    return <span className={`product-icon product-icon--without-wrapper ${icon}`}/>;

  return (
      <div className="product-icon-wrapper">
        <span className={`product-icon ${icon}`}/>
      </div>
  );
};

ProductIcon.propTypes = {
  icon: PropTypes.string.isRequired
};