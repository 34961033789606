import {connect} from "react-redux";
import CategoryTitle from "../index";
import {withRouter} from "react-router";

const mapStateToProps = (state) => {
  return {
    headerCategoriesReducer: state.headerCategoriesReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const CategoryTitleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryTitle);

export default withRouter(CategoryTitleContainer);