import { connect } from 'react-redux';
import PaginationFilter from "../";
import {updatePageInProductsList} from "modules/store/scenes/ProductsList/actions";
import {createSelector} from "reselect";

const getStore = state => state.store;
const getProductsCount = createSelector(
    [getStore],
    store => store.productsCount
);

const mapStateToProps = (state) => {
  return {
    productsCount: getProductsCount(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePageInProductsList: page => dispatch(updatePageInProductsList(page))
  };
};

const PaginationFilterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaginationFilter);

export default PaginationFilterContainer;