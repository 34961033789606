import React, {PureComponent} from 'react';
import {Panel, PanelGroup} from "react-bootstrap";
import {FREQUENTLY_ASKED_QUESTIONS} from './constants/faqs';
import SEO from "components/SEO/index";
import "./styles/FAQ.scss";

const PanelComponent = ({header, eventKey, nodeHtml, id}) => (
    <div
        className="about-faq__wrapper-panel-content"
        id={id}>
      <Panel
          header={header}
          eventKey={eventKey} collapsible>
        <span dangerouslySetInnerHTML={{__html: nodeHtml}}/>
      </Panel>
    </div>
);

class FAQ extends PureComponent {
  render() {
    return (
        <div className="about-faq">
          <PanelGroup
              accordion
              id="faq"
              defaultActiveKey="1">
            {
              FREQUENTLY_ASKED_QUESTIONS.map((group, index) => (
                  <div className="faq-group" key={index}>
                    <h3 className="faq-group__title">{group.title}</h3>
                    {
                      group.faqs.map((item, index) =>
                          <PanelComponent
                              key={index}
                              id={item.anchor ? item.anchor : `faq-${index}`}
                              header={item.header}
                              eventKey={(index + 1).toString()}
                              nodeHtml={item.nodeHtml}/>
                      )
                    }
                  </div>
              ))
            }
          </PanelGroup>
          <SEO url='faq'/>
        </div>
    )
  }
}

export default FAQ;
