import React from 'react';
import PropTypes from 'prop-types';
import "./styles/VintageAlert.scss";

const VintageAlert = ({children}) => (
    <div className="vintage-alert">
      {children}
    </div>
);

VintageAlert.propTypes = {
  children: PropTypes.node.isRequired
};

export default VintageAlert;
