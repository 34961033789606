import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles/SelectedAddres.scss";

class SelectedAddress extends Component {
  render() {
    const {address} = this.props;

    return (
        <div className="select-address">
          <div className="select-address__icon">
            <i className="icon-Pin"/>
          </div>
          <div className="select-address__info">
            <p>
              <span className="bold text-uppercase">
                {address.first_name} {address.last_name}
              </span>
              <br/>
              {address.address_1}
              <br/>
              {address.city_name}, {address.region_name} {address.zip_code}
            </p>
          </div>
          <div className="clearfix"/>
        </div>
    )
  }
}

SelectedAddress.propTypes = {
  address: PropTypes.object.isRequired
};

export default SelectedAddress
