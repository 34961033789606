import React, {useState, useContext} from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./styles/CouponDetails.scss";
import {cleanDiscountFromCart} from "services/store";
import CartCouponBoxContext from "modules/store/scenes/Cart/components/CartCouponBox/contexts/CartCouponBoxContext";

export default function CouponDetails({couponData, receiveDiscountCode}) {
  const [loading, setLoading] = useState(false);
  const couponBoxStyle = useContext(CartCouponBoxContext);

  /**
   * Handles click on the delete button
   * @param event
   */
  function handleDeleteButtonClick(event) {
    setLoading(true);
    const promise = cleanDiscountFromCart();
    promise.then(response => {
      setLoading(false);
      receiveDiscountCode(null);
    });
  }

  return (
      <div className={classNames({
        "coupon-details": true,
        "coupon-details--large": couponBoxStyle === "large",
        "coupon-details--compact": couponBoxStyle === "compact"
      })}>
        <div className="row">
          <div className="col-sm-8">
            <span className="coupon-name">
              <i className="icon-circle-check"/>&nbsp;
              Coupon <strong>{couponData.code}</strong>
            </span>
          </div>
          <div className="col-sm-4 text-right">
            <button
                type="button"
                disabled={loading}
                className="coupon-delete-button"
                onClick={handleDeleteButtonClick}>
              {
                loading
                    ?
                    <i className="fa fa-spinner fa-spin"/>
                    :
                    <i className="icon-rubbish"/>
              }
            </button>
          </div>
        </div>
      </div>
  );
}

CouponDetails.propTypes = {
  couponData: PropTypes.shape({
    code: PropTypes.string,
    discount_type: PropTypes.number,
    expiration_date: PropTypes.string,
    id: PropTypes.number,
    limit: PropTypes.number,
    maximum_total: PropTypes.string,
    minimum_total: PropTypes.string,
    promotion: PropTypes.string,
    start_date: PropTypes.string,
    used_times: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  receiveDiscountCode: PropTypes.func
};
