import React, { Component } from 'react';
import {apiGet} from "services/api";
import MyProfileFormContainer from "./components/MyProfileForm/container";
import {toastr} from 'react-redux-toastr';
import {saveUser} from "services/auth";
import diskette from "assets/img/diskette.png";
import ToastContent from "utils/components/ToastContent/index";
import SEO from "components/SEO/index";

class MyProfile extends Component {
  constructor(props){
    super(props);

    this.updateCustomer = this.updateCustomer.bind(this);
  }

  fetchCustomer(){
    // TODO get right id
    let customerPromise = apiGet({resourceName: 'customer'});
    customerPromise.then((response) => {
      this.props.receiveCustomer(response);
    });

    return customerPromise;
  }

  componentDidMount(){
    this.fetchCustomer();
  }

  updateCustomer(data){
    let promise = this.props.updateCustomer(data);
    promise.then(action => {
      saveUser(action.response.data.user);
      toastr.success('', '', {
        icon: (<span className="icon-circle-check"/>),
        component: (
            <ToastContent
                image={diskette}
                text="Saved"/>
        )
      });
    })
  }

  render(){
    const {account} = this.props;

    return(
        <div>
          <div className="wrapper">
            <h2><span className="icon icon-user"/><span className="text">My Profile</span></h2>
            <MyProfileFormContainer
                onSubmit={this.updateCustomer}
                isLoading={account.pages.myAccount.loading}
                errors={account.pages.myAccount.errors}/>
          </div>
          <SEO url='my-account/profile' />
        </div>
    )
  }
}

export default MyProfile