import { connect } from 'react-redux';
import ChangePassword from "../";
import {updatePassword} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data) => dispatch(
        updatePassword(data)
    )
  };
};

const ChangePasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);

export default ChangePasswordContainer;