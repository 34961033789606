import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import "./styles/AddressForm.scss";
import AddressFieldsContainer from "modules/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/container/index";

export class AddressForm extends Component {
  render() {
    const {errors, isLoading, handleSubmit, initialValues, removeOptions, withCancelButton} = this.props;

    return (
        <form
            className="address-form"
            onSubmit={handleSubmit}>
          <AddressFieldsContainer
              removeOptions={removeOptions}
              changeFieldValue={this.props.changeFieldValue}
              initialValues={initialValues}
              errors={errors}/>
          <div className="align-right">
            <div className="form-group">
              {
                withCancelButton &&
                <button
                    type="button"
                    className="cancel"
                    onClick={this.props.closeAddressModal}>CANCEL
                </button>
              }
              {
                isLoading ? (
                    <input
                        type="submit"
                        value="SAVING..."
                        disabled={true}/>
                ) : (
                    <input type="submit" value="SAVE"/>
                )
              }
            </div>
          </div>
        </form>
    )
  }
}

AddressForm.defaultProps = {
  removeOptions: false,
  withCancelButton: false
};

AddressForm = reduxForm({
  form: 'accountAddressForm',
  enabledReinitialize: true
})(AddressForm);

export default AddressForm
