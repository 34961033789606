import * as types from '../actions/types';
import * as accountTypes from 'modules/MyAccount/actions/types';
import {isAuthenticated, getUser} from "services/auth";

export const initialState = {
  loginPage: {
    loading: false,
    errors: []
  },
  signUpPage: {
    loading: false,
    errors: []
  },
  forgotPasswordPage: {
    loading: false,
    errors: []
  },
  user: getUser(),
  isAuthenticated: isAuthenticated()
};

export const auth = (state = initialState, action) => {
  switch (action.type){
    case types.AUTH_LOGIN_REQUEST:
      return {...state, loginPage: {
        ...state.loginPage,
        loading: true
      }};
    case types.AUTH_LOGIN_SUCCESS:
      return {...state,
        user: action.response.data,
        isAuthenticated: true,
        loginPage: {
          loading: false,
          errors: []
        }};
    case types.AUTH_LOGIN_ERROR:
      return {...state,
        user: null,
        isAuthenticated: false,
        loginPage: {
          loading: false,
          // TODO this could fail if response.data or response.data.non_field_errors are undefined
          errors: action.messages
        }};
    case types.AUTH_SIGN_UP_REQUEST:
      return {...state, signUpPage: {
        ...state.signUpPage,
        loading: true
      }};
    case types.AUTH_SIGN_UP_SUCCESS:
      return {...state,
        user: action.user,
        isAuthenticated: true,
        signUpPage: {
          loading: false,
          errors: []
        }};
    case types.AUTH_SIGN_UP_ERROR:
      return {...state,
        user: null,
        isAuthenticated: false,
        signUpPage: {
          loading: false,
          // TODO this could fail if response.data or response.data.non_field_errors are undefined
          errors: action.messages
        }};
    case types.AUTH_RESET_PASSWORD_REQUEST:
      return {...state, forgotPasswordPage: {
        ...state.forgotPasswordPage,
        loading: true
      }};
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {...state, forgotPasswordPage: {
        ...state.forgotPasswordPage,
        loading: false
      }};
    case types.AUTH_RESET_PASSWORD_ERROR:
      return {...state, forgotPasswordPage: {
        ...state.forgotPasswordPage,
        loading: false,
        // TODO this could fail if response.data or response.data.non_field_errors are undefined
        errors: action.messages
      }};
    case types.AUTH_LOGOUT:
      return {...state,
        user: null,
        isAuthenticated: false
      };
    case types.USER_RECEIVE:
      return {...state,
        user: action.user,
      };
    case accountTypes.CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.response.data.user
      };
    default:
      return state;
  }
};