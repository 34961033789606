import React, {Component} from 'react';
import {Link} from "react-router-dom";

class EmptyCart extends Component {
  render() {
    return (
        <div className="empty-message">
          <h1>Looks like you haven’t added any items yet.</h1>
          <div className="footer">
            <div className="bg cart"/>
            <p>
              <Link to="/store/products">
                <span className="btn-custom">GO SHOPPING</span>
              </Link>
            </p>
          </div>
        </div>
    )
  }
}

EmptyCart.propTypes = {};

export default EmptyCart