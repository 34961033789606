import vintageAxios from 'services/api';
import {LOCAL_STORAGE_WISH_LIST_ID_KEY} from "./constants";

export const saveWishList = (id) => {
  localStorage.setItem(LOCAL_STORAGE_WISH_LIST_ID_KEY, id);
};

export const removeWishList = (id) => {
  localStorage.removeItem(LOCAL_STORAGE_WISH_LIST_ID_KEY);
};

export const createWishList = () => {
  let wishListId = getWishListId();
  let promise = new Promise((resolve) => { resolve(); });
  if( !wishListId ){
    // Create a new wish list if it does not exist yet...
    promise = vintageAxios.post('/management/wish-lists/');
    promise.then(response => {
      saveWishList(response.data.id);
    });
  }
  return promise;
};

export const getWishListId = () => {
  return localStorage.getItem(LOCAL_STORAGE_WISH_LIST_ID_KEY);
};

export const addToWishList = ({productId, contentType, condition=0}) => {
  // condition: 0 for new, 1 for used
  return createWishList().then(response => {
    // get session again after promise finished
    let wishListId = getWishListId();
    let data = {
      "wish_list": wishListId,
      "content_type": contentType,
      "object_id": productId,
      "condition": condition
      // TODO api is asking for wish_list on this end-point, which should not be required...
    };
    return vintageAxios.post(`/management/wish-lists/${wishListId}/items/`, data);
  })
};

export const getWishListItems = () => {
  return createWishList().then(response => {
    // get session again after promise finished
    let wishListId = getWishListId();
    return vintageAxios.get(`/management/wish-lists/${wishListId}/items/`);
  })
};

export const removeFromWishList = (wishListItem) => {
  let wishListId = getWishListId();
  return vintageAxios.delete(`/management/wish-lists/${wishListId}/items/${wishListItem.id}/`);
};
