export const NOT_NEEDED = 'not-needed';
export const MADE_ERROR = 'made-error';
export const ITEM_DEFECTIVE = 'item-defective';
export const INCORRECT_INFO = 'incorrect-info';
export const CHEAPER_OTHER = 'cheaper-other';

export const ORDER_ITEM_RETURN_REASONS = [
  [NOT_NEEDED, 'I don\'t need the item anymore'],
  [MADE_ERROR, 'I made a mistake'],
  [ITEM_DEFECTIVE, 'The item came broken, defective or malfunctioning'],
  [INCORRECT_INFO, 'The description or photos were not accurate'],
  [CHEAPER_OTHER, 'I found it cheaper somewhere else'],
];

export const ORDER_ITEM_CANCELLATION_REASONS = [
  [NOT_NEEDED, 'I don\'t need the item anymore'],
  [MADE_ERROR, 'I bought the item by mistake'],
  [INCORRECT_INFO, 'The item description was not accurate'],
  [CHEAPER_OTHER, 'I found it cheaper somewhere else'],
];