import React, {Component} from 'react';
import "./styles/WishlistShareButton.scss";
import WishlistShareModal from "modules/MyAccount/scenes/Wishlist/components/WishlistShareModal/index";

class WishListShareButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wishlistShareModalIsOpen: false
    };

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  /**
   * Open wishlist share modal
   */
  onOpen() {
    this.setState({wishlistShareModalIsOpen: true});
  }

  /**
   * Close wishlist share modal
   */
  onClose() {
    this.setState({wishlistShareModalIsOpen: false});
  }

  render() {
    const {wishlistShareModalIsOpen} = this.state;

    return (
        <div className="wishlist-share-button">
          <button
              onClick={this.onOpen}
              className="btn-custom wishlist-share-button__button-share">
            <span className="fa fa-share"/><span>SHARE</span>
          </button>
          <WishlistShareModal
              onClose={this.onClose}
              wishlistShareModalIsOpen={wishlistShareModalIsOpen}/>
        </div>
    )
  }
}

export default WishListShareButton
