import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {isAuthenticated} from "services/auth/index";
import SEO from "components/SEO/index";
import {apiGet} from "services/api/index";
import SpamWarning from "modules/store/scenes/CheckoutSuccess/components/SpamWarning/index";

class CheckoutSuccess extends Component {
  constructor() {
    super();

    this.state = {
      order: null
    }
  }

  // componentDidMount() {
  //   this.fetchOrder().then(response => {
  //     const {data} = response;
  //     if (!data.notified_analytics) {
  //       dataLayer.push({
  //         'event': 'transactionEvent',
  //         "ecommerce": {
  //           "purchase": {
  //             "actionField": {
  //               "id": data.id,
  //               "affiliation": process.env.REACT_APP_SITE_NAME,
  //               "revenue": data.total, //total including tax and shipping
  //               "tax": data.sale_tax,
  //               "shipping": data.shipping_fee,
  //               "coupon": "" //if a coupon code was used for this order
  //             },
  //             products: [
  //               ...data.items.normal.map(item => ({
  //                 'sku': item.order_item_data.product_sku,
  //                 'name': item.order_item_data.product_name,
  //                 'category': item.order_item_data.product_type,
  //                 'price': item.order_item_data.price,
  //                 'quantity': item.order_item_data.quantity
  //               })),
  //               ...data.items.shipment.map(item => ({
  //                 'sku': item.order_item_data.product_sku,
  //                 'name': item.order_item_data.product_name,
  //                 'category': item.order_item_data.product_type,
  //                 'price': item.order_item_data.price,
  //                 'quantity': item.order_item_data.quantity
  //               }))
  //             ]
  //           }
  //         }
  //       });
  //       this.sentToAnalytics()
  //     }
  //   });
  // }

  fetchOrder() {
    let promise = apiGet({resourceName: 'orders', id: this.props.match.params.orderUUID});
    promise.then(response => {
      this.setState({
        order: response.data
      });
    });
    return promise;
  }

  // sentToAnalytics() {
  //   const {match} = this.props;
  //   const promise = vintageAxios.post(`management/orders/${match.params.orderUUID}/already-sent-to-analytics/`);
  //   promise.then(response => {});
  //   return promise;
  // }

  render() {
    const {orderUUID} = this.props.match.params;

    return (
        <div>
          <section className="page-content">
            <SpamWarning/>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="empty-message thanks">
                    <h1>Thank you, your order has been placed.</h1>
                    <p>An email confirmation has been sent to you.</p>
                    <div className="footer">
                      <div className="bg"/>
                      <p>
                        <Link to={!isAuthenticated()
                            ? `/store/order/${orderUUID}`
                            : '/my-account/order-history'}>
                          <span className="btn-custom">GO TO ORDER</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SEO url='checkout/cart'/>
        </div>
    )
  }
}

export default CheckoutSuccess;