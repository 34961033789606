import React, {useState} from "react";
import vintageAxios from "services/api"

export default function ValidateEmailButton({label, loadingLabel, successLabel}) {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  function handleBtnClick(event) {
    setLoading(true)
    const promise = vintageAxios.post("/auth/request-email-validation-token/");
    promise.then(response => {
      setLoading(false)
      setSent(true)
    });
    promise.catch(error => {
      setLoading(false)
    });
  }

  return (
      <button
          disabled={loading || sent}
          onClick={handleBtnClick}>
        {
          sent
              ?
              successLabel
              :
              (
                  loading
                      ?
                      loadingLabel
                      :
                      label
              )
        }
      </button>
  )
}