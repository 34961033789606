import React, {Component} from 'react';
import {DEFAULT_BY_SHIPPING} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";
import LoadingCassette from "components/LoadingCassette/index";
import AddressFormContainer from "modules/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/container/index";
import SelectedAddress from "modules/store/scenes/CheckoutPage/components/CheckoutShippingAddress/components/SelectedAddress/index";
import CheckoutAddressListContainer from "modules/store/scenes/CheckoutPage/components/CheckoutShippingAddress/CheckoutAddressList/container/index";

class CheckoutShippingAddress extends Component {
  constructor() {
    super();

    this.state = {
      addressListIsOpen: false
    };

    this.handleAddressFormSubmit = this.handleAddressFormSubmit.bind(this);
    this.toggleAddressListIsOpen = this.toggleAddressListIsOpen.bind(this);
  }

  componentDidMount() {
    const {addresses} = this.props.account;
    if (addresses)
      this.findDefaultShippingAddress();
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.account.addresses || prevProps.account.addresses.length === 0) && this.props.account.addresses)
      this.findDefaultShippingAddress()
  }

  /**
   * Selected shipping address by default
   */
  findDefaultShippingAddress() {
    const {addresses} = this.props.account;

    if (addresses.length > 0) {
      const selectedShippingAddress = addresses.filter(address => address.default && address.address_type === DEFAULT_BY_SHIPPING)[0] ||
          // otherwise select the first one
          addresses[0];

      // Set shipping and billing in store
      this.props.selectShippingAddress(selectedShippingAddress);
    }
  }

  /**
   * Handles AddressForm submit to dispatch createAddress action.
   * createAddress action calls selectShippingAddress after creation
   * @param data
   */
  handleAddressFormSubmit(data) {
    if (!data.id)  // create mode
      this.props.createAddress({
        ...data,
        byDefaultAsShipping: true
      });
  }

  /**
   * Toggles addressListIsOpen state
   */
  toggleAddressListIsOpen() {
    this.setState({addressListIsOpen: !this.state.addressListIsOpen})
  }

  render() {
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    const {pages} = this.props.account;
    const {addresses} = this.props.account;
    const {addressListIsOpen} = this.state;

    return (
        <div className="checkout-step checkout-step--shipping-address">
          <div className="checkout-step__header">
            {
              (selectedShippingAddress && !addressListIsOpen) &&
              <div className="options">
                <button
                    onClick={this.toggleAddressListIsOpen}
                    className="btn-custom">
                  <span>CHANGE ADDRESS</span>
                </button>
              </div>
            }
            <h2>
              <span className="circle">1</span><span>Shipping Address</span>
            </h2>
          </div>
          <div className="checkout-step__body">
            {
              (!selectedShippingAddress && !addresses) ?
                  <LoadingCassette/>
                  :
                  <div id="form_address_shipping">
                    {
                      (selectedShippingAddress && !addressListIsOpen) &&
                      <SelectedAddress
                          address={selectedShippingAddress}/>
                    }
                    {
                      (addresses && addresses.length === 0 && !addressListIsOpen) &&
                      <AddressFormContainer
                          onSubmit={this.handleAddressFormSubmit}
                          initialValues={{
                            byDefaultAsShipping: true,
                            byDefaultAsBilling: null
                          }}
                          isLoading={pages.addressBook.loading}
                          errors={pages.addressBook.errors}
                          removeOptions
                          withCancelButton={false}/>
                    }
                    {
                      (addresses && addresses.length > 0 && addressListIsOpen) &&
                      <CheckoutAddressListContainer
                          onAfterSelectAddress={this.toggleAddressListIsOpen}/>
                    }
                  </div>
            }
          </div>
        </div>
    )
  }
}

CheckoutShippingAddress.propTypes = {};

export default CheckoutShippingAddress