import {connect} from "react-redux";
import CheckoutAddressList from "../index";
import {selectShippingAddress, openAddressModal, selectAddress} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectShippingAddress: address => dispatch(selectShippingAddress(address)),
    openAddressModal: () => dispatch(openAddressModal()),
    selectAddress: address => dispatch(selectAddress(address))
  };
};

const CheckoutAddressListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutAddressList);

export default CheckoutAddressListContainer;