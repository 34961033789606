/* global dataLayer */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {addToCart} from "services/store";
import {toastr} from 'react-redux-toastr'
import ToastContent from "utils/components/ToastContent/index";
import classNames from 'classnames/bind';
import "./styles/AddToCartButton.scss";

export default function AddToCartButton({contentTypeId, productId, quantity, condition, productData, customLabel, hideLabel, hideIcon, look, size, receiveCartSingleItem}) {
  const [loading, setLoading] = useState(false);

  /**
   * Makes the request to add product to cart
   */
  function handleAddToCart() {
    setLoading(true);

    const promise = addToCart({
      contentTypeId: contentTypeId,
      productId: productId,
      quantity: quantity,
      condition: condition
    });
    promise.then(response => {
      // TODO TOASTR check if all notifications will have this same look and feel. If so, let's make this global
      toastr.success('', '', {
        icon: (<span className="icon-circle-check"/>),
        progressBar: false,
        component: (
            <ToastContent
                image={response.data.profile_picture}
                text="Added to cart"/>
        )
      });
      setLoading(false);
      receiveCartSingleItem(response.data);

      if (productData) {
        dataLayer.push({
          "event": "productAddedToCart",
          "ecommerce": {
            "currencyCode": "USD",
            "add": {
              "products": [{
                "id": productData.sku,
                "name": productData.name,
                "quantity": quantity
              }]
            }
          }
        });
      }
    });
    promise.catch(error => {
      setLoading(false);
      const {response: {data}} = error;
      if (data && data.non_field_errors)
        toastr.error(data.non_field_errors[0]);
    });
    return promise;
  }

  /**
   * Returns the label button should render
   * @returns {Object|null} label
   */
  const BtnLabel = () => {
    if (hideLabel)
      return null

    return (
        <span>
          {loading ? 'Adding...' : customLabel || "Add to cart"}
        </span>
    )
  }

  return (
      <button
          type="button"
          className={classNames({
            "add-to-cart-button": true,
            [`add-to-cart-button--style-${look}`]: true,
            [`add-to-cart-button--size-${size}`]: true,
            "add-to-cart-button--disabled": loading,
          })}
          disabled={loading}
          onClick={handleAddToCart}>
        {
          !hideIcon &&
          <span className="add-to-cart-button__icon">
            {
              loading
                  ?
                  <i className="fa fa-spinner fa-spin"/>
                  :
                  <span className="icon icon-Cart"/>
            }
            &nbsp;
          </span>
        }
        <span className="add-to-cart-button__text">
          <BtnLabel/>
        </span>
      </button>
  )
}

AddToCartButton.propTypes = {
  contentTypeId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  condition: PropTypes.number.isRequired,
  productData: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  customLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideIcon: PropTypes.bool,
  look: PropTypes.oneOf([
    "button",
    "link"
  ]),
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large"
  ])
};

AddToCartButton.defaultProps = {
  productData: null,
  hideLabel: false,
  hideIcon: false,
  look: "button",
  size: "medium"
};
