import React from 'react';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles/Recaptcha.scss";

const Recaptcha = ({afterCheckedRecaptcha, onExpiredRecaptcha}) => {
  return (
      <div className="recaptcha">
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={afterCheckedRecaptcha}
            onExpired={onExpiredRecaptcha}/>
      </div>
  )
};

Recaptcha.propTypes = {
  afterCheckedRecaptcha: PropTypes.func.isRequired, // Function executed when successful recaptcha
  onExpiredRecaptcha: PropTypes.func.isRequired, // Function executed when expire recaptcha
};

export default Recaptcha;
