import * as types from './types';
import {apiCreate} from "services/api";

export const createContactMessageRequest = (data) => ({
  type: types.CONTACT_MESSAGE_CREATE_REQUEST,
  data
});

export const createContactMessageSuccess = (data, response) => ({
  type: types.CONTACT_MESSAGE_CREATE_SUCCESS,
  data,
  response
});

export const createContactMessageFailed = (data, response) => ({
  type: types.CONTACT_MESSAGE_CREATE_ERROR,
  data,
  response
});

export function createContactMessage(data){
  return (dispatch) => {
    dispatch(createContactMessageRequest(data));
    return apiCreate('contactMessage', data)
        .then((response) => dispatch(createContactMessageSuccess(response, data)))
        .catch((error) => dispatch(createContactMessageFailed(error.response, data)));
  };
}