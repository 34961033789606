import React from 'react';
import PropTypes from 'prop-types';
import ProductAdditionalInfo from "./components/ProductAdditionalInfo";
import NavTabs from "utils/components/NavTabs";

export default function ProductTabs({product}) {
  return (
      <div className="bottom">
        <NavTabs tabs={[
          {
            title: "DESCRIPTION",
            content: (
                <p dangerouslySetInnerHTML={{__html: product.description}}/>
            )
          },
          {
            title: "ADDITIONAL INFO",
            content: (
                <ProductAdditionalInfo
                    product={product}/>
            )
          }
        ]}/>
      </div>
  );
}

ProductAdditionalInfo.propTypes = {
  product: PropTypes.object.isRequired
};