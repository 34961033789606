const DEFAULT_TEXT = 'Vintage Stock / Movie Trading Co. - Music, Movies, Video Games and More!';
const DEFAULT_DESCRIPTION = 'Shop for thousand of new & used movies & video games, & posters & toys, too!';

const Meta = [

  // specify the default tags for all the routes here.
  // this will be used if the tags for given url is not specified here.
  {
    url: 'default',
    title: 'Vintage Stock - Welcome to our Online Store.',
    description: DEFAULT_DESCRIPTION
  },
  {
    url: 'my-account/profile',
    title: `Account information | ${DEFAULT_TEXT}`,
    description: 'Account information'
  },
  {
    url: 'my-account/password',
    title: `Change password | ${DEFAULT_TEXT}`,
    description: 'Change password',
    keywords: 'account, password'
  },
  {
    url: 'my-account/order-history',
    title: `My orders | ${DEFAULT_TEXT}`,
    description: 'Order history'
  },
  {
    url: 'my-account/address-book',
    title: `Add New Address | ${DEFAULT_TEXT}`,
    description: 'Add new address'
  },
  {
    url: 'my-account/credit-cards-info',
    title: `Add Credit Card | ${DEFAULT_TEXT}`,
    description: 'Add credit card'
  },
  {
    url: 'my-account/wishlist',
    title: `My Wishlist | ${DEFAULT_TEXT}`,
    description: 'Wishlist'
  },
  {
    url: '',
    title: 'Vintage Stock Online & In Store: Shop Popular New and Used Video Games and Movies',
    description: 'Best prices on a huge selection of new and used movies, video games, and more. Easy to shop online or find store locations.'
  },
  {
    url: 'about',
    title: `About us - About | ${DEFAULT_TEXT}`,
    description: 'General information about Vintage stock.'
  },
  {
    url: 'our-history',
    title: `Our history - About | ${DEFAULT_TEXT}`,
    description: 'General information about Vintage stock.'
  },
  {
    url: 'faq',
    title: `faq - About | ${DEFAULT_TEXT}`,
    description: 'General information about Vintage stock.'
  },
  {
    url: 'career-opportunities',
    title: `Career Opportunities - About | ${DEFAULT_TEXT}`,
    description: 'General information about Vintage stock.'
  },
  {
    url: 'auth/login',
    title: `Customer Login | ${DEFAULT_TEXT}`,
    description: 'Log in'
  },
  {
    url: 'auth/forgot-password',
    title: `Forgot Your Password | ${DEFAULT_TEXT}`,
    description: 'password recovery'
  },
  {
    url: 'auth/signup',
    title: `Create New Customer Account | ${DEFAULT_TEXT}`,
    description: 'Sign up'
  },
  {
    url: 'contact',
    title: `Contact Us | ${DEFAULT_TEXT}`,
    description: 'Contact information'
  },
  {
    url: 'store',
    title: 'Store | Vintage Stock',
    description: 'All our products available to you!.'
  },
  {
    url: 'store/products',
    title: `| ${DEFAULT_TEXT}`,
    description: 'All products'
  },
  {
    url: 'store/products/productSlug',
    title: `Products | ${DEFAULT_TEXT}`,
    description: 'Product details'
  },
  {
    url: 'gift-cards',
    title: `Gift Cards | ${DEFAULT_TEXT}`,
    description: 'Gift card can only be used for purchases at a physical location. Not valid for website purchases.'
  },
  {
    url: 'cart',
    title: `Shopping Cart | ${DEFAULT_TEXT}`,
    description: 'Shopping cart'
  },
  {
    url: 'checkout/cart',
    title: `Checkout | ${DEFAULT_TEXT}`,
    description: 'Checkout'
  },
  {
    url: 'advanced-search',
    title: `Advanced Search | ${DEFAULT_TEXT}`,
    description: 'Advanced search form'
  },
  {
    url: 'locations',
    title: 'Come visit one of our stores in 11 states throughout the midwest!',
    description: 'We\'ve got a huge variety of games, movies, music, books, toys and more!  Find the location nearest you.'
  },
];

export default Meta;