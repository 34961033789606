import { connect } from 'react-redux';
import MyAccount from "../";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const MyAccountContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccount);

export default MyAccountContainer;