/* global dataLayer */

import React, {Component} from 'react';
import CartItemContainer from "./components/CartItem/container";
import LoadingCassette from "components/LoadingCassette";
import {Link} from "react-router-dom";
import CartTotalsContainer from "./components/CartTotals/container";
import {CART} from "./components/CartTotals/constants/contexts";
import {CART_ITEM_IN_CART} from "./components/CartItem/constants/contexts";
import "./styles/Cart.scss";
import CartCouponBoxContainer from "./components/CartCouponBox/container";
import SEO from "components/SEO/index";
import EmptyCart from "modules/store/scenes/Cart/components/EmptyCart/index";
import TrustElements from "utils/components/TrustElements";
import CartCouponBoxContext from "modules/store/scenes/Cart/components/CartCouponBox/contexts/CartCouponBoxContext";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zipCode: '',
      dataLayerReady: false
    };

    this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {cart} = this.props.store;
    const {dataLayerReady} = this.state;

    if (cart.cart_items.length > 0 && !dataLayerReady) {
      this.setState({dataLayerReady: true});
      dataLayer.push({
        "event": "checkoutEvent",
        "ecommerce": {
          "checkout": {
            "actionField": {"step": 1},
            "products": cart.cart_items.map(item => ({
              "sku": item.product_sku,
              "name": item.product_name,
              "quantity": item.quantity
            }))
          }
        }
      });
    }
  }

  /**
   * Handles changes on zip code input.
   * @param {Object} event
   */
  handleZipCodeChange(event) {
    const value = event.target.value;
    this.setState({
      zipCode: value
    });
  }

  /**
   * Handles submits in zip code form.
   */
  static handleZipCodeFormSubmit(event) {
    event.preventDefault();
  }

  // Fetching cart items in this component is NOT required because these are being fetched in
  // the HeaderCart component.
  render() {
    const {store} = this.props;
    const {zipCode} = this.state;

    return (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="subtitle">
                  <h1>My Cart</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="alert alert-warning" role="alert" style={{textTransform: "none", marginBottom: 0}}>
              If purchasing multiple items, you might expect separate deliveries.
            </div>
          </div>
          <section className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="shopping-cart">
                    {
                      store.loadingCart ? (
                          <LoadingCassette/>
                      ) : (
                          store.cart.cart_items.length > 0 ? (
                              store.cart.cart_items.map(item => (
                                  <CartItemContainer
                                      context={CART_ITEM_IN_CART}
                                      key={`cartItem${item.id}`}
                                      item={item}/>
                              ))
                          ) : (
                              <EmptyCart/>
                          )
                      )
                    }
                  </div>
                </div>
                <div className="clearfix"/>
                {
                  (store.cart !== null && store.cart.cart_items.length > 0) && (
                      <div>
                        <div className="col-md-8 col-sm-8">
                          <CartCouponBoxContext.Provider value="large">
                            <CartCouponBoxContainer/>
                          </CartCouponBoxContext.Provider>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="shopping-cart">
                            <div className="summary">
                              <form onSubmit={Cart.handleZipCodeFormSubmit}>
                                <div className="form-group">
                                  <label htmlFor="zipCode">Calculate taxes & Shipping</label>
                                  <div className="input-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="zipCode"
                                        value={zipCode}
                                        onChange={this.handleZipCodeChange}
                                        placeholder="Enter your ZIP code"/>
                                    <span className="input-group-btn">
                                      <button
                                          className="btn btn-default"
                                          type="submit">
                                        <i className="fa fa-arrow-right"/>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </form>
                              <hr/>
                              <div className="order-sumary">
                                <h4>ORDER SUMMARY</h4>
                                <CartTotalsContainer
                                    context={CART}
                                    zipCode={zipCode}/>
                                <Link to="/store/checkout">
                                  <span className="btn-custom btn-block text-center">GO TO CHECKOUT</span>
                                </Link>
                              </div>
                              <br/>
                              <hr/>
                              <br/>
                              <div className="cart-trust-elements">
                                <TrustElements direction="column"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                }
              </div>
            </div>
          </section>
          <SEO url='cart'/>
        </div>
    )
  }
}

export default Cart;
