import React, {Component} from 'react';

const withRecaptcha = (WrappedComponent) => {
  return (
      class extends Component {
        constructor(props) {
          super(props);

          this.state = {
            checkedRecaptcha: false
          };

          this.afterCheckedRecaptcha = this.afterCheckedRecaptcha.bind(this);
          this.onExpiredRecaptcha = this.onExpiredRecaptcha.bind(this);
        }

        /**
         * Is marked the recaptcha as checked
         */
        afterCheckedRecaptcha() {
          this.setState({
            checkedRecaptcha: true
          })
        }

        /**
         * Is marked the recaptcha as unchecked
         */
        onExpiredRecaptcha() {
          this.setState({
            checkedRecaptcha: false
          })
        }

        render() {
          const {checkedRecaptcha} = this.state;

          return <WrappedComponent
              checkedRecaptcha={checkedRecaptcha}
              afterCheckedRecaptcha={this.afterCheckedRecaptcha}
              onExpiredRecaptcha={this.onExpiredRecaptcha}
              {...this.props} />;
        }
      }
  )
};

export default withRecaptcha;
