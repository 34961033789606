import React from "react"
import {useQuery} from "react-query"
import vintageAxios from "services/api"

export default function useLocations() {
  const {loading, error, data: locations} = useQuery(
      `locations`, () =>
          vintageAxios.get('/misc/locations/', {
            params: {
              'page_size': 100  // all of them
            }
          })
              .then(response => response.data.results)
  )

  return {loading, error, locations}
}