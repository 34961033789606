import React from 'react';
import PropTypes from 'prop-types';
import slugify from "utils/slugify";
import "./styles/CreditCardInfo.scss";

const CreditCardInfo = ({creditCard}) => {
  return (
      <span className="credit-card-info">
        {
          creditCard ? (
              <span>
                <span className={`icon ${slugify(creditCard.credit_card_type)}`}/>
                <span className="number">
                  {creditCard.credit_card_type} ending in {creditCard.safe_num.replace(/\*/g, '')}
                </span>
              </span>
          ) : (
              <span className="icon paypal"/>
          )
        }
    </span>
  );
};

CreditCardInfo.propTypes = {
  creditCard: PropTypes.shape({
    credit_card_type: PropTypes.string.isRequired,
    safe_num: PropTypes.string.isRequired
  })
};

export default CreditCardInfo;
