import React from "react";
import PropTypes from "prop-types";
import "./styles/StoreInfoCard.scss";

export default function StoreInfoCard({store, distance}) {
  return (
      <div className="store-info-card">
        <p className="store-info-card__name">
          {store.name}&nbsp;&nbsp;
          {distance && <small>({distance} miles)</small>}
        </p>
        <p className="store-info-card__address">{store.address_1}{store.address_2 && `, ${store.address_2}`} {store.city_name} {store.region_name} {store.zip_code}</p>
        <p className="store-info-card__call-us">Call us <strong>{store.phone_number_1}</strong>, to
          pick up or ship from this store. <span className="ship-limit-message">Ship from store maximum order is $100.</span>
        </p>
      </div>
  );
}

StoreInfoCard.propTypes = {
  store: PropTypes.object.isRequired,
  distance: PropTypes.number.isRequired
};