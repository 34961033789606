import { connect } from 'react-redux';
import CartCouponBox from "../";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const CartCouponBoxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartCouponBox);

export default CartCouponBoxContainer;
