import { connect } from 'react-redux';
import AddToCartButton from "../";
import {receiveCartSingleItem} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCartSingleItem: item => dispatch(receiveCartSingleItem(item)),
  };
};

const AddToCartButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToCartButton);

export default AddToCartButtonContainer;