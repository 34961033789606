import {connect} from "react-redux";
import CheckoutPage from "../index";
import {receiveAddresses, receiveCreditCards} from "modules/MyAccount/actions/index";
import {emptyCart, emptyCartItemStock} from "modules/store/actions/index";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    store: state.store,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAddresses: response => dispatch(receiveAddresses(response)),
    receiveCreditCards: response => dispatch(receiveCreditCards(response)),
    emptyCart: () => dispatch(emptyCart()),
    emptyCartItemStock: item => dispatch(emptyCartItemStock(item))
  };
};

const CheckoutPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPage);

export default CheckoutPageContainer;