import {connect} from "react-redux";
import AddressFields from "../index";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const AddressFieldsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressFields);

export default AddressFieldsContainer;