import {connect} from "react-redux";
import CouponDetails from "../index";
import {receiveDiscountCode} from "modules/store/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveDiscountCode: discountCode => dispatch(receiveDiscountCode(discountCode))
  };
};

const CouponDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponDetails);

export default CouponDetailsContainer;
