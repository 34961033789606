import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import AddressFormContainer from "./components/AddressForm/container";

class AddAddressModal extends Component {
  constructor(props) {
    super(props);

    this.saveAddress = this.saveAddress.bind(this);
  }

  /**
   * Handle the Address form submit to dispatch editAddress or createAddress actions
   * @param data
   */
  saveAddress(data) {
    const {editAddress, createAddress} = this.props;

    let promise;
    if (data.id)  // edit mode
      promise = editAddress(data);
    else  // create mode
      promise = createAddress(data);

    promise.then(() => {
      if (typeof this.props.onAfterCreated === 'function')
        this.props.onAfterCreated();
    });
  }

  render() {
    const {account, closeAddressModal, selectedAddress} = this.props;

    return (
        <Modal
            show={account.pages.addressBook.addressModalIsOpen}
            onHide={closeAddressModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                selectedAddress && selectedAddress.id ? "Edit Address"
                    : "Add Address"
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressFormContainer
                removeOptions
                onSubmit={this.saveAddress}
                isLoading={account.pages.addressBook.loading}
                errors={account.pages.addressBook.errors}/>
          </Modal.Body>
        </Modal>
    )
  }
}

AddAddressModal.propTypes = {
  onAfterCreated: PropTypes.func
};

export default AddAddressModal