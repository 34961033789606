import { connect } from 'react-redux';
import MyProfile from "../";
import {receiveCustomer, updateCustomer} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCustomer: data => dispatch(receiveCustomer(data)),
    updateCustomer: data => dispatch(updateCustomer(data))
  };
};

const MyProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfile);
export default MyProfileContainer;
