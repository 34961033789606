import React, {useState} from "react"
import "./styles/SidebarAvailability.scss"
import ChooseYourStoresModal
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/ChooseYourStoresModal"
import AvailabilityCheckboxes
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/AvailabilityCheckboxes"
import AvailabilityStores
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/AvailabilityStores"

export default function SidebarAvailability() {
  const [showModal, setShowModal] = useState(false)

  /**
   * Toggles the modal
   */
  const toggleModal = () => setShowModal(!showModal)

  return (
      <>
        <div className="sidebar-availability">
          <h4>In Store / Online items</h4>
          <AvailabilityCheckboxes/>
          <AvailabilityStores
              toggleModal={toggleModal}/>
        </div>
        <ChooseYourStoresModal
            show={showModal}
            toggle={toggleModal}/>
      </>
  )
}