import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import vintageAxios from "services/api";
import {applyDiscountToCart} from "services/store";
import "./styles/CouponForm.scss";
import CartCouponBoxContext from "modules/store/scenes/Cart/components/CartCouponBox/contexts/CartCouponBoxContext";

export default function CouponForm({total, receiveDiscountCode}) {
  const [coupon, setCoupon] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const couponBoxStyle = useContext(CartCouponBoxContext);

  /**
   * Handles input change to update state with new coupon code introduced by the user
   * @param {Object} event
   */
  function handleInputChange(event) {
    setCoupon(event.target.value);
  }

  /**
   * Verifies the coupon code through the API
   * @param event
   */
  function verify(event) {
    event.preventDefault();

    if (!coupon)
      return false;

    setLoading(true);

    const promise = vintageAxios.get(`/management/discount-codes/${coupon}/`);
    promise.then(response => {
      setLoading(false);

      const {data} = response;
      if (parseFloat(total) < data.minimum_total)
        setErrorMessage(`The minimum total of your cart to use this coupon is $${data.minimum_total}`);
      else if (parseFloat(total) > data.maximum_total)
        setErrorMessage(`The maximum total of your cart to use this coupon is $${data.maximum_total}`);
      else {
        applyDiscountToCart(response.data);
        receiveDiscountCode(response.data);
      }
    });
    promise.catch(({response}) => {
      setLoading(false);

      if (response && response.data && response.data.non_field_errors)
        setErrorMessage(response.data.non_field_errors[0]);
      else
        setErrorMessage(`This coupon "${coupon}" is not valid.`);
    });
  }

  return (
      <div className={classNames({
        "coupon-form-container": true,
        "coupon-form-container--large": couponBoxStyle === "large",
        "coupon-form-container--compact": couponBoxStyle === "compact"
      })}>
        <h6 className="coupon-form-container__title">Have a Coupon?</h6>
        <form
            className="form"
            onSubmit={verify}>
          <div className="clearfix">
            <div className={classNames({
              "form-group": true,
              "has-error": errorMessage !== null
            })}>
              <label className="sr-only" htmlFor="coupon">Coupon code</label>
              <input
                  type="text"
                  className="form-control"
                  id="coupon"
                  value={coupon}
                  onChange={handleInputChange}
                  placeholder="Enter your coupon here"/>
              {
                errorMessage &&
                <i className="icon-alert"/>
              }
              {
                (errorMessage && couponBoxStyle === "large") &&
                <span className="help-block">
                  {errorMessage}
                </span>
              }
            </div>
            <div className="form-group text-right">
              <input
                  type="submit"
                  disabled={loading || !coupon}
                  value={loading ? "APPLY..." : "APPLY"}/>
            </div>
          </div>
          {
            (errorMessage && couponBoxStyle === "compact") &&
            <span className="help-block">
              {errorMessage}
            </span>
          }
        </form>
      </div>
  );
}

CouponForm.propTypes = {
  total: PropTypes.string,
  receiveDiscountCode: PropTypes.func
};
