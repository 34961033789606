/* global ga */

import vintageAxios from 'services/api';
import {LOCAL_STORAGE_CART_SESSION_KEY} from "./constants";
import {isAuthenticated} from "services/auth/index";

/**
 * Saves the cart session in local storage
 * @param {String} session
 */
export const saveCartSession = (session) => {
  localStorage.setItem(LOCAL_STORAGE_CART_SESSION_KEY, session);
};

/**
 * Retrieves the cart session from local storage
 * @return {String} session - The cart session key
 */
export const getCartSession = () => {
  return localStorage.getItem(LOCAL_STORAGE_CART_SESSION_KEY);
};

/**
 * Removes the cart session from local storage
 */
export const removeCartSession = () => {
  localStorage.removeItem(LOCAL_STORAGE_CART_SESSION_KEY);
};

/**
 * Creates a new cart for a user
 * @returns {AxiosPromise} cartPromise
 */
export const createCart = () => {
  let promise = vintageAxios.post('/management/shopping-carts/');
  promise.then(response => {
    saveCartSession(response.data.session);
  });
  return promise;
};

// TODO refactor this function
export const addToCart = ({contentTypeId, productId, quantity=1, condition=0}) => {
  // condition: 0 for new, 1 for used
  let session = getCartSession();
  let promise = new Promise((resolve) => { resolve(); });
  if( !session ){
    // Create a new cart if it does not exist yet...
    promise = createCart();
  }
  return promise.then(response => {
    // get session again after promise finished
    session = getCartSession();
    let data = {
      "content_type": contentTypeId,
      "object_id": productId,
      "quantity": quantity,
      "condition": condition
    };
    return vintageAxios.post(`/management/shopping-carts/${session}/items/`, data);
  })
};

/**
 * Retrieves the right promise to get a cart from the API. Depending if the user is authenticated or not.
 * @returns {Promise}
 */
const getCartPromise = () => {
  let session = getCartSession();
  if( session )
    return vintageAxios.get(`/management/shopping-carts/${session}/`);
  else if( isAuthenticated() )
    return vintageAxios.get(`/management/shopping-carts/`);
  else
    return Promise.resolve({data: {
      "cart_items": [],
      "session": null
    }});  // simulate empty cart
};

/**
 * Gets the cart of a user and returns the promise.
 * @returns {Promise}
 */
export const getCart = () => {
  const promise = getCartPromise();
  promise.then(response => {
    const {session} = response.data;
    if( session ) saveCartSession(session);
  });
  return promise;
};

/**
 *
 * @param {Object} cartItem
 * @returns {AxiosPromise}
 */
export const removeFromCart = (cartItem) => {
  let session = getCartSession();
  return vintageAxios.delete(`/management/shopping-carts/${session}/items/${cartItem.id}/`);
};

/**
 * Updates cart item with PUT or PATCH depending on data length
 * @param {Object} cartItem
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateCartItem = (cartItem, data) => {
  let session = getCartSession();
  let url = `/management/shopping-carts/${session}/items/${cartItem.id}/`;
  let promise;
  if( Object.keys(data).length === 1 ){
    promise = vintageAxios.patch(url, data);
  } else {
    promise = vintageAxios.put(url, data);
  }
  return promise;
};

/**
 * Updates the owner of a cart from anon user to authenticated one
 * @returns {AxiosPromise}
 */
export const updateCartOwner = session => {
  if( !session ) return Promise.resolve({data: {cart_items: []}});
  const promise = vintageAxios.post(`/management/shopping-carts/${session}/merge/`);
  promise.then(response => {
    // This end-point returns a brand new cart
    saveCartSession(response.data.session);
  });
  return promise;
};

/**
 * Updates quantity for an item in a cart
 * @param {Object} cartItem
 * @param {Number} newQuantity
 * @returns {AxiosPromise}
 */
export const updateCartItemQuantity = (cartItem, newQuantity) => {
  return updateCartItem(cartItem, {
    'quantity': newQuantity
  });
};

// export const updateCartItemCondition = (cartItem, newCondition) => {
//   return updateCartItem(cartItem, {
//     'condition': newCondition
//   });
// };

/**
 * Applies a discount to a cart
 * @param {Object} discount
 * @returns {AxiosPromise}
 */
export const applyDiscountToCart = discount => {
  const session = getCartSession();
  return vintageAxios.put(`/management/shopping-carts/${session}/`, {
    discount_code: discount.id
  });
};

/**
 * Applies a discount to a cart
 * @param {Object} discount
 * @returns {AxiosPromise}
 */
export const cleanDiscountFromCart = discount => {
  const session = getCartSession();
  return vintageAxios.put(`/management/shopping-carts/${session}/`, {
    discount_code: null
  });
};

/**
 * Converts a cart in a order and deletes the cart
 * @param {Number} billingAddressId
 * @param {Number} shippingAddressId
 * @param {Number} creditCardId
 * @param {Number} cvv
 * @returns {AxiosPromise}
 */
export const placeOrder = ({billingAddressId, shippingAddressId, creditCardId, cvv}) => {
  let session = getCartSession();
  let clientId;
  try {
    clientId = ga.getAll()[0].get('clientId');
  } catch (e) {
    clientId = null;
  }
  const data = {
    "billing_address": billingAddressId,
    "shipping_address": shippingAddressId,
    "credit_card": creditCardId,
    "cvv": cvv,
  };
  if (clientId)
    data["ga_client_id"] = ga.getAll()[0].get('clientId');
  const promise = vintageAxios.post(`/management/shopping-carts/${session}/checkout/`, data);
  promise.then(response => {
    removeCartSession();
  });
  return promise;
};
