import React from 'react';
import NewsletterSubscribeContainer from "components/NewsletterSubscribe/container";
import {Link, NavLink, Route} from "react-router-dom";
import {Redirect} from "react-router";
import MyProfileContainer from "./scenes/MyProfile/container";
import AddressBookContainer from "./scenes/AddressBook/container";
import CreditCardsInfoContainer from "./scenes/CreditCardsInfo/container";
import ChangePasswordContainer from "./scenes/ChangePassword/container";
import OrderHistoryContainer from "./scenes/OrderHistory/container";
import WishlistContainer from "./scenes/Wishlist/container";
import PropTypes from 'prop-types';
import VerifyEmailAlert from "modules/MyAccount/components/VerifyEmailAlert"

const MyAccount = ({match, auth}) => {
  if(auth.isAuthenticated === false){
    return (
        <Redirect to="/auth/signup"/>
    );
  }

  return(
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subtitle">
                <h1>My Account</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="page-content">
          <div className="container">
            <VerifyEmailAlert/>
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <ul id="sidebar" className="nav nav-pills nav-stacked icons">
                  <li role="presentation">
                    <NavLink to={match.url + '/profile'}
                             activeClassName="active"
                             className="icon-user">
                      <span>My Profile</span>
                    </NavLink>
                  </li>
                  <li role="presentation">
                    <NavLink to={match.url + '/password'}
                             activeClassName="active"
                             className="icon-padlock">
                      <span>Password</span>
                    </NavLink>
                  </li>
                  <li role="presentation">
                    <NavLink to={match.url + '/order-history'}
                             activeClassName="active"
                             className="icon-shopping-bag">
                      <span>Order History</span>
                    </NavLink>
                  </li>
                  <li role="presentation">
                    <NavLink to={match.url + '/address-book'}
                             activeClassName="active"
                             className="icon-address-book">
                      <span>Address Book</span>
                    </NavLink>
                  </li>
                  <li role="presentation">
                    <NavLink to={match.url + '/credit-cards-info'}
                             activeClassName="active"
                             className="icon-card">
                      <span>Credit Card Info</span>
                    </NavLink>
                  </li>
                  <li role="presentation">
                    <NavLink to={match.url + '/wishlist'}
                             activeClassName="active"
                             className="icon-wishlist-book">
                      <span>Wish List</span>
                    </NavLink>
                  </li>
                  <li className="bottom" role="presentation">
                    <Link to="/auth/logout" className="icon-logout">
                      <span>Sign Out</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-9 col-sm-9">
                <div className="tab-content">
                  <Route path={match.url + '/profile'} component={MyProfileContainer}/>
                  <Route path={match.url + '/password'} component={ChangePasswordContainer}/>
                  <Route path={match.url + '/order-history'} component={OrderHistoryContainer}/>
                  <Route path={match.url + '/address-book'} component={AddressBookContainer}/>
                  <Route path={match.url + '/credit-cards-info'} component={CreditCardsInfoContainer}/>
                  <Route path={match.url + '/wishlist'} component={WishlistContainer}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <NewsletterSubscribeContainer/>
        </div>
  );
};

MyAccount.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired
  })
};

export default MyAccount;