import React from 'react';
import SEO from "components/SEO/index";

const OurHistory = () => (
    <div>
      <h1>Our History</h1>
      <p>Founded in 1980, in Joplin, Missouri, Vintage Stock was originally called Book Barn and sold used books.
        As time progressed and the company grew, adding stores in Springfield, MO, and Bartlesville, OK., we also
        diversified the product line to include other pre-owned products, including music, movies, video games, and
        new and used sports collectibles, trading cards, games, comics and toys. In 1994 the first Vintage Stock
        branded store opened in Tulsa, OK.</p>
      <p>In 2002, Vintage Stock was totally acquired by a group led by Rodney Spriggs, Steve Wilcox, and Ken Caviness.
        Soon after, Vintage Stock opened more new stores across the Midwest, expanding into brand-new markets. In 2006,
        Vintage Stock acquired the Dallas-based Movie Trading Co. locations from Blockbuster, adding over a million titles to
        choose from in new Movies, Music & Video Games.</p>
      <p>Starting in 2013, EntertainMART locations in Springfield, MO & Colorado Springs, CO, were added, and, in late 2016, former
        Hastings locations in central Texas, New Mexico, Utah & Idaho.</p>
      <p>Also in late 2016, the company was purchased by Live Ventures Incorporated.</p>
      <p>Today, there are 73 locations in 14 states to serve you, and we just keep growing, thanks to loyal customers like you.</p>
      <SEO url='our-history'/>
    </div>
);

export default OurHistory;