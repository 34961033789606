import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import "./styles/QuantityInput.scss";
import {MINUS, PLUS} from "utils/components/QuantityInput/constants/control-names";
import {toastr} from 'react-redux-toastr';
import debounce from 'lodash/debounce';
import {NEW} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";

class QuantityInput extends Component {
  constructor(props) {
    super(props);

    this.handleQuantityInputChange = this.handleQuantityInputChange.bind(this);
    this.handleControlClick = controlName => this._handleControlClick.bind(this, controlName);
  }

  /**
   * Handles direct user typing in the quantity input
   * @param {Object} event
   */
  handleQuantityInputChange(event) {
    const value = event.target.value;
    this.fireOnQuantityChangesCallback(value);
  }

  /**
   * Validates the selected quantity is available in stock.
   */
  validateQuantity() {
    const {condition, quantity, isPreOrder} = this.props;
    // pre-order items should skip validation because of course these are not available
    const maxQuantity = isPreOrder ? 9999999 : condition === NEW ? this.props.stockNew : this.props.stockUsed;

    if (!quantity || quantity === 0) {
      this.props.onQuantityChanges(1);
    } else if (quantity > maxQuantity) {
      this.props.onQuantityChanges(maxQuantity);
      toastr.error(`Only ${maxQuantity} ${condition === NEW ? 'new' : 'used'} available in stock.`);
    }
  }

  debounceValidateQuality = debounce(this.validateQuantity, 500);

  /**
   * Calls the on quantity changes callback (required in props) and debounces validate quantity function.
   * @param {Number} quantity
   */
  fireOnQuantityChangesCallback(quantity) {
    this.props.onQuantityChanges(quantity);
    this.debounceValidateQuality();
  }

  /**
   * Handles changes through the + and - controls.
   * @param {String} controlName
   * @private
   */
  _handleControlClick(controlName) {
    let {quantity} = this.props;
    quantity = parseInt(quantity, 10);
    const newQuantity = controlName === PLUS ? quantity + 1 : quantity - 1;
    if (newQuantity > 0)
      this.fireOnQuantityChangesCallback(newQuantity);
  }

  render() {
    const {quantity, fullWidth, size, disabled} = this.props;

    return (
        <span className={classNames({
          "quantity-input": true,
          "quantity-input--full-width": fullWidth,
          [`quantity-input--size-${size}`]: true
        })}>
          <input
              type="number"
              className="quantity-input__form-control form-control"
              value={quantity}
              onChange={this.handleQuantityInputChange}
              disabled={disabled}/>
          <span
              className={`quantity-input__control quantity-input__control--${PLUS}`}
              onClick={this.handleControlClick(PLUS)}>
            +
          </span>
          <span
              className={`quantity-input__control quantity-input__control--${MINUS}`}
              onClick={this.handleControlClick(MINUS)}>
            -
          </span>
        </span>
    )
  }
}

QuantityInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  condition: PropTypes.oneOf([0, 1]).isRequired,  // TODO validate this should be one of NEW or USED constants
  onQuantityChanges: PropTypes.func.isRequired,
  stockNew: PropTypes.number.isRequired,
  stockUsed: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool
};

QuantityInput.defaultProps = {
  fullWidth: false,
  size: "lg",
  disabled: false,
  // TODO set this prop everywhere this component is instantiated, currently only being done in CartItem component
  isPreOrder: false
};

export default QuantityInput
