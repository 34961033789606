import {connect} from "react-redux";
import FetchWishList from "../index";
import {receiveWishListItems} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveWishListItems: wishListItems => dispatch(receiveWishListItems(wishListItems))
  };
};

const FetchWishListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FetchWishList);

export default FetchWishListContainer;