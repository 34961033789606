import {connect} from "react-redux";
import HeaderCartDropdown from "../index";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

const HeaderCartDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCartDropdown);

export default HeaderCartDropdownContainer;