import {connect} from "react-redux";
import CheckoutShippingMethod from "../index";

const mapStateToProps = (state) => {
  return {
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const CheckoutShippingMethodContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutShippingMethod);

export default CheckoutShippingMethodContainer;