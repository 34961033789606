import React, {Component} from 'react';
import SignUpForm from "./components/SignUpForm";
import {Redirect} from "react-router";
import {AUTH_SIGN_UP_SUCCESS} from "modules/auth/actions/types";
import {updateCartOwner} from "services/store";
import {getCartSession} from "services/store/index";
import SEO from "components/SEO/index";
import {Link} from "react-router-dom";

class SignUp extends Component {
  constructor(props){
    super(props);

    this.state = {
      cartSession: getCartSession()
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAfterSocialSubmit = this.handleAfterSocialSubmit.bind(this);
  }

  convertCart(){
    const promise = updateCartOwner(this.state.cartSession);
    promise.then(response => this.props.fetchCartItemsSuccess(response.data));
  }

  handleFormSubmit(data){
    const promise = this.props.onSubmit(data);
    promise.then(action => {
      if( action && action.type === AUTH_SIGN_UP_SUCCESS )
        this.convertCart();
    });
  }

  handleAfterSocialSubmit(response) {
    this.convertCart();
    this.props.loginSuccess({}, response);
  }

  render(){
    const {auth} = this.props;

    if(auth.isAuthenticated) {
      return (
          <Redirect to="/my-account/profile"/>
      );
    }

    return(
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="login-wrapper">
                  <div className="lightbox">
                    <div className="brand">
                      <Link to="/">
                        <span className="logo"/>
                      </Link></div>
                    <div className="wrapper">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="login-bg"/>
                        </div>
                        <div className="col-md-6">
                          <h1>Create your new Account</h1>
                          {/*<AuthSocialButtons
                              verb="SignUp with "
                              onAfterSubmit={this.handleAfterSocialSubmit}/><br/>*/}
                          <p>Please enter your information below to create an account.</p>
                          <SignUpForm
                              onSubmit={this.handleFormSubmit}
                              isLoading={auth.signUpPage.loading}
                              errors={auth.signUpPage.errors}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SEO url='auth/signup'/>
        </div>
    )
  }
}

export default SignUp;
