import { connect } from 'react-redux';
import CreditCard from "../";
import {
  deleteCreditCard, selectCreditCard, openCreditCardModal
} from "modules/MyAccount/actions";
import {setCreditCardAsDefault} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCreditCard: creditCard => dispatch(deleteCreditCard(creditCard)),
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard)),
    openCreditCardModal: () => dispatch(openCreditCardModal()),
    setCreditCardAsDefault: creditCard => dispatch(setCreditCardAsDefault(creditCard))
  };
};

const CreditCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCard);

export default CreditCardContainer;