export const PRODUCTS_RECEIVE_REQUEST = 'PRODUCTS_RECEIVE_REQUEST';
export const PRODUCTS_RECEIVE = 'PRODUCTS_RECEIVE';
export const PRODUCTS_COUNT_SET = 'PRODUCTS_COUNT_SET';
export const CART_ITEMS_FETCH_REQUEST = 'CART_ITEMS_FETCH_REQUEST';
export const CART_ITEMS_FETCH_SUCCESS = 'CART_ITEMS_FETCH_SUCCESS';
export const CART_ITEMS_FETCH_ERROR = 'CART_ITEMS_FETCH_ERROR';
export const CART_SINGLE_ITEM_RECEIVE = 'CART_SINGLE_ITEM_RECEIVE';
export const CART_ITEM_UPDATE_QUANTITY = 'CART_ITEM_UPDATE_QUANTITY';
export const CART_ITEM_DELETE_REQUEST = 'CART_ITEM_DELETE_REQUEST';
export const CART_ITEM_DELETE_SUCCESS = 'CART_ITEM_DELETE_SUCCESS';
export const CART_ITEM_DELETE_ERROR = 'CART_ITEM_DELETE_ERROR';
export const CART_TOTALS_RECEIVE = 'CART_TOTALS_RECEIVE';
export const CART_ITEM_EMPTY_STOCK = 'CART_ITEM_EMPTY_STOCK';
export const CART_EMPTY = 'CART_EMPTY';
export const DISCOUNT_CODE_RECEIVE = 'DISCOUNT_CODE_RECEIVE';
export const ADVANCED_SEARCH_SELECT = 'ADVANCED_SEARCH_SELECT';
export const PRODUCTS_SEARCH_BY_CATEGORY = 'PRODUCTS_SEARCH_BY_CATEGORY';