import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import _ from 'lodash';
import Meta from "components/SEO/constants/meta";
import PropTypes from "prop-types";
import {OTHER_CONTEXT, PRODUCTS_DETAILS_CONTEXT, PRODUCTS_LIST_CONTEXT} from "components/SEO/constants/contexts";
import {withRouter} from "react-router-dom";

class SEO extends Component {
  render() {
    const {ogImage, ogImageWidth, ogImageHeight, context} = this.props;

    let content = {};
    let defaultText;

    switch (context) {
      case PRODUCTS_DETAILS_CONTEXT:
        defaultText = _.find(Meta, {url: this.props.url});
        content.title = `${this.props.information.title} - ${defaultText.title}`;
        break;

      case PRODUCTS_LIST_CONTEXT:
        content = Object.assign({}, _.find(Meta, {url: this.props.url}));
        const defaultTitle = _.find(Meta, {url: this.props.url}).title;
        content.title = `${this.props.information.title} ${defaultTitle}`;
        break;

      default:
        content = this.props.information;
        content = _.find(Meta, {url: this.props.url});
        const {title, description} = this.props;

        if (!content)
          content = _.find(Meta, {url: 'default'});

        if (title)
          content.title = title;

        if (description)
          content.description = description;
        break;
    }

    return (
        <Helmet>
          <title>{content.title}</title>
          <meta name="description" content={content.description}/>
          <meta property="og:locale" content="es"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={content.title}/>
          <meta property="og:description" content={content.description}/>
          <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}${this.props.location.pathname}`}/>
          <meta property="og:site_name" content={`${process.env.REACT_APP_SITE_NAME}`}/>
          <meta property="og:image" content={ogImage}/>
          <meta property="og:image:width" content={ogImageWidth}/>
          <meta property="og:image:height" content={ogImageHeight}/>
          <meta property="og:image:secure_url" content={ogImage}/>
          <meta property="fb:admins" content={`${process.env.REACT_APP_FB_ADMINS}`}/>
          <meta property="fb:app_id" content={`${process.env.REACT_APP_FB_APP_ID}`}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content={content.title}/>
          <meta name="twitter:description" content={content.description}/>
          <meta name="twitter:site" content={`${process.env.REACT_APP_TWITTER_SITE}`}/>
          <meta name="twitter:image" content={ogImage}/>
          <meta name="twitter:creator" content={`${process.env.REACT_APP_TWITTER_SITE}`}/>
        </Helmet>
    );
  }
}


SEO.propTypes = {
  information: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  url: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.number,
  ogImageHeight: PropTypes.number
};

SEO.defaultProps = {
  context: OTHER_CONTEXT,
  ogImage: "http://www.aboutbluesprings.com/image.axd?picture=VintageStock.jpg",
  ogImageWidth: 2048,
  ogImageHeight: 1536
};

SEO = withRouter(SEO);

export default SEO;