import {connect} from "react-redux";
import WishlistHeartBtn from "../index";
import {addWishListItem, removeWishListItem} from "modules/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWishListItem: wishListItem => dispatch(addWishListItem(wishListItem)),
    removeWishListItem: wishListItem => dispatch(removeWishListItem(wishListItem))
  };
};

const WishlistHeartBtnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WishlistHeartBtn);

export default WishlistHeartBtnContainer;