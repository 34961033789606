import * as types from './types';
import vintageAxios from "services/api";

export const newsletterSubscribeRequest = data => ({
  type: types.NEWSLETTER_SUBSCRIBE_REQUEST,
  data
});

export const newsletterSubscribeSuccess = (data, response) => ({
  type: types.NEWSLETTER_SUBSCRIBE_SUCCESS,
  data,
  response
});

export const newsletterSubscribeFailed = (data, response) => ({
  type: types.NEWSLETTER_SUBSCRIBE_ERROR,
  data,
  response
});

export function newsletterSubscribe(data){
  return (dispatch) => {
    dispatch(newsletterSubscribeRequest(data));
    return vintageAxios.post('/misc/newsletter/subscribe/', data)
        .then((response) => dispatch(newsletterSubscribeSuccess(data, response.data)))
        .catch((error) => dispatch(newsletterSubscribeFailed(data, error.response.data)));
  };
}

export const openNewsLetterModal = () => ({
  type: types.NEWSLETTER_MODAL_OPEN
});

export const closeNewsLetterModal = () => ({
  type: types.NEWSLETTER_MODAL_CLOSE
});

export const resetNewsLetter = () => ({
  type: types.NEWSLETTER_RESET
});