import {connect} from "react-redux";
import CreditCardList from "../index";
import {setCreditCardAsDefault, openCreditCardModal} from "modules/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCreditCardAsDefault: creditCard => dispatch(setCreditCardAsDefault(creditCard)),
    openCreditCardModal: () => dispatch(openCreditCardModal())
  };
};

const CreditCardListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardList);

export default CreditCardListContainer;