import { connect } from 'react-redux';
import TrackingModal from "../";

const mapStateToProps = (state) => {
  return {
    orderHistory: state.orderHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const TrackingModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrackingModal);

export default TrackingModalContainer;