import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles/CheckoutPaymentMethod.scss";
import {
  CREDIT_CARD_PAYMENT_METHOD,
  PAYPAL_PAYMENT_METHOD
} from "modules/store/scenes/CheckoutPage/contants/payment-methods";
import CreditCardListContainer from "modules/store/scenes/CheckoutPage/components/CheckoutPaymentMethod/components/CreditCardList/container/index";
import SingleCreditCardContainer from "modules/store/scenes/CheckoutPage/components/CheckoutPaymentMethod/components/SingleCreditCard/container/index";
import CreditCard from "modules/MyAccount/scenes/CreditCardsInfo/components/CreditCard/index";
import CreditCardFormContainer from "modules/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/components/CreditCardForm/container/index";
import {DEFAULT_BY_BILLING} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";
import Address from "modules/MyAccount/scenes/AddressBook/components/Address/index";
import AddCreditCardModalContainer from "modules/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/container/index";

class CheckoutPaymentMethod extends Component {
  constructor() {
    super();

    this.state = {
      selectedCreditCard: null,
      creditCardListIsOpen: false,
      addressFormIsOpen: false
    };

    this.handlePaymentMethodChange = paymentMethod => this._handlePaymentMethodChange.bind(this, paymentMethod);
    this.toggleCreditCardListIsOpen = this.toggleCreditCardListIsOpen.bind(this);
    this.handleUseThisCreditCardButtonClick = this.handleUseThisCreditCardButtonClick.bind(this);
    this.handleCreditCardFormSubmit = this.handleCreditCardFormSubmit.bind(this);
    this.handleOpenAddressForm = this.handleOpenAddressForm.bind(this);
    this.handleCloseAddressForm = this.handleCloseAddressForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {creditCards} = this.props.account;
    const {selectedCreditCard} = this.state;

    let currentDefault = null;
    let prevDefault = null;
    if (creditCards) {
      // Find the default credit card...
      currentDefault = creditCards.find(creditCard => creditCard.default);
      if (!currentDefault) {
        // otherwise select the first one
        currentDefault = creditCards[0]
      }
    }

    if (prevProps.account.creditCards)
      prevDefault = prevProps.account.creditCards.find(creditCard => creditCard.default);

    if ((!prevDefault && currentDefault)
        || (selectedCreditCard && selectedCreditCard.id !== currentDefault.id)
        || (prevDefault && prevDefault.id === currentDefault.id && !selectedCreditCard)
        //  If the billing address is changed
        || (prevDefault && currentDefault && prevDefault.billing_address !== currentDefault.billing_address))
      this.setDefaultCreditCard(currentDefault, prevDefault)
  }

  setDefaultCreditCard(creditCard, prevDefault) {
    const {addresses} = this.props.account;
    const creditCardWithAddress = {
      ...creditCard,
      billingAddress: creditCard.billing_address && addresses
          ? addresses.find(address => address.id === creditCard.billing_address)
          : null
    };
    //  If don't have a default creditCard.
    if (!prevDefault)
      this.props.setCreditCardAsDefault(creditCardWithAddress);

    if (this.props.account.creditCards.length > 0) {
      this.setState({
        selectedCreditCard: creditCardWithAddress
      })
    }
  }

  _handlePaymentMethodChange(paymentMethod) {
    this.props.onChangePaymentMethod(paymentMethod)
  }

  toggleCreditCardListIsOpen() {
    this.setState({creditCardListIsOpen: !this.state.creditCardListIsOpen})
  }

  handleUseThisCreditCardButtonClick() {
    const {selectedCreditCard} = this.state;
    CreditCard.setCreditCardAsDefault(selectedCreditCard);
    this.toggleCreditCardListIsOpen()
  }

  /**
   * Open address form
   */
  handleOpenAddressForm() {
    this.setState({
      addressFormIsOpen: true
    }, () => {
      this.reinitializeAddressForm();
    });
  }

  /**
   * Close address form
   */
  handleCloseAddressForm() {
    this.setState({
      addressFormIsOpen: false
    });
  }

  /**
   * Reinitialize address form
   */
  reinitializeAddressForm() {
    // Reset address form
    this.props.resetAddressForm();
    // Clear errors of address form
    this.props.createAddressFailed([], {data: []});
  }

  handleCreditCardFormSubmit(data) {
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    const {addressFormIsOpen} = this.state;
    const {createAddress} = this.props;
    let creditCard = Object.assign({}, data);
    delete creditCard["address"];

    // The billing address is created. Is added when modal is open
    if (addressFormIsOpen && data["address"]) {
      let promiseAddress;
      promiseAddress = createAddress(data["address"]);
      promiseAddress.then((response) => {
        this.setState({
          addressFormIsOpen: false, // Hide address form why was added billing address
        });
        creditCard.billing_address = response.data.id;
        this.saveCreditCard(creditCard, response.data);
      }).catch(err => {
        console.log(err);
      });
    } else {
      creditCard.billing_address = selectedShippingAddress.id;
      this.saveCreditCard(creditCard, selectedShippingAddress);
    }
    this.props.onCvvCodeInputChange({target: {value: data.verification_value}})
  }

  /**
   * Save credit card information
   * @param {Object} data
   * @param {Object} billingAddress
   */
  saveCreditCard(data, billingAddress) {
    const {editCreditCard, createCreditCard} = this.props;
    let promiseCreditCard;

    if (data.id)  // edit mode
      promiseCreditCard = editCreditCard(data);
    else  // create mode
      promiseCreditCard = createCreditCard({
        ...data,
        byDefault: true
      });

    promiseCreditCard.then(() => {
      // TODO: this must be get from credit Card info
      this.props.selectBillingAddress(billingAddress);
      Address.setAddressAsDefault(billingAddress, DEFAULT_BY_BILLING);
    });
  }

  render() {
    const {paymentMethod, cvvCode} = this.props;
    const {selectedCreditCard, creditCardListIsOpen, addressFormIsOpen} = this.state;
    const {creditCards, pages} = this.props.account;

    return (
        <div className="checkout-step checkout-step--payment-method">
          <div className="checkout-step__header">
            {
              (selectedCreditCard && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !creditCardListIsOpen) &&
              <div className="options">
                <button
                    onClick={this.toggleCreditCardListIsOpen}
                    className="btn-custom">
                  <span>CHANGE CREDIT CARD</span>
                </button>
              </div>
            }
            <h2>
              <span className="circle">3</span><span>Payment Method</span>
            </h2>
          </div>
          <div className="checkout-step__body">
            <div className="payment-method__paypal">
              <div className="radio">
                <label>
                  <input
                      type="radio"
                      name="selectedCreditCard"
                      form="form_credit_card"
                      value={0}
                      onChange={this.handlePaymentMethodChange(PAYPAL_PAYMENT_METHOD)}
                      checked={paymentMethod === PAYPAL_PAYMENT_METHOD}/>
                  <span className="bold">
                    Paypal
                  </span>
                  <span className="paypal-info">
                    <span className="icon paypal-logo"/>
                  </span>
                </label>
              </div>
            </div>
            <hr/>
            <div className="payment-method__credit-card">
              <div
                  className="radio">
                <label>
                  <input
                      type="radio"
                      name="selectedCreditCard"
                      value={0}
                      onChange={this.handlePaymentMethodChange(CREDIT_CARD_PAYMENT_METHOD)}
                      checked={paymentMethod === CREDIT_CARD_PAYMENT_METHOD}/>
                  <span className="bold">
                    Credit Card
                  </span>
                  <span className="credit-card--info">
                    <span className="icon credit-card-logo__amex"/>
                    <span className="icon credit-card-logo__visa"/>
                    <span className="icon credit-card-logo__master-card"/>
                    <span className="icon credit-card-logo__discover"/>
                  </span>
                </label>
              </div>
              {
                paymentMethod === CREDIT_CARD_PAYMENT_METHOD &&
                <div>
                  {
                    (selectedCreditCard && !creditCardListIsOpen) &&
                    <div className="row payment-method__credit-card--selected">
                      <div className="col-md-12">
                        <SingleCreditCardContainer
                            withBillingInfo
                            creditCard={selectedCreditCard}/>
                      </div>
                      <div className="col-md-6">
                        <form>
                          <div className="form-group">
                            <div className="form-field">
                              <label htmlFor="ccv_code">*CVV code</label>
                              <input
                                  name="ccv_code"
                                  type="password"
                                  value={cvvCode}
                                  onChange={this.props.onCvvCodeInputChange}
                                  placeholder="Type the CVV code"
                                  className="form-control"/>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  }
                  {
                    (creditCards && creditCards.length > 0 && creditCardListIsOpen) &&
                    <CreditCardListContainer
                        onUseThisCreditCardButtonClick={this.handleUseThisCreditCardButtonClick}
                        creditCards={creditCards}
                        selectedCreditCard={selectedCreditCard}/>

                  }
                  {
                    (creditCards && creditCards.length === 0 && !creditCardListIsOpen) &&
                    <CreditCardFormContainer
                        onSubmit={this.handleCreditCardFormSubmit}
                        isLoading={pages.creditCards.loading}
                        errors={pages.creditCards.errors}
                        addressErrors={pages.addressBook.errors}
                        addressFormIsOpen={addressFormIsOpen}
                        handleOpenAddressForm={this.handleOpenAddressForm}
                        handleCloseAddressForm={this.handleCloseAddressForm}
                        withCancelButton={false}
                        withBillingInfo={false}/>
                  }
                </div>
              }
            </div>
          </div>
          <AddCreditCardModalContainer/>
        </div>
    )
  }
}

CheckoutPaymentMethod.propTypes = {
  paymentMethod: PropTypes.oneOf([PAYPAL_PAYMENT_METHOD, CREDIT_CARD_PAYMENT_METHOD]),
  onChangePaymentMethod: PropTypes.func.isRequired,
  onCvvCodeInputChange: PropTypes.func.isRequired,
  cvvCode: PropTypes.string.isRequired
};

export default CheckoutPaymentMethod
