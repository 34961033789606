import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import "./styles/InvoiceModal.scss";
import InvoiceModalAddress from "./components/InvoiceModalAddress";
import InvoiceModalItem from "./components/InvoiceModalItem";
import CreditCardInfo from "components/CreditCardInfo";
import {Link} from "react-router-dom";
import OrderTotals from "modules/MyAccount/scenes/OrderHistory/components/Order/components/OrderTotals/index";
import OrderModalHeading from "modules/MyAccount/scenes/OrderHistory/components/OrderModalHeading/index";
import {
  CANCELLED,
  NORMAL,
  RETURNED
} from "modules/MyAccount/scenes/OrderHistory/components/ReturnItemsModal/components/ReturnItemsForm/constants/order-item-type";

class InvoiceModal extends Component {
  render(){
    const {show, onClose} = this.props;
    const order = this.props.orderHistory.selectedOrder;

    return(
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="invoice">
          <Modal.Header closeButton>
            <Modal.Title>Invoice No. <span id="inv-number">{order.id}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <OrderModalHeading
                  uuid={order.uuid}
                  date={order.created_at}/>
              <hr/>
              <div className="row">
                <div className="col-md-12">
                  <div className="field">
                    <h6>
                      Payment Method:
                      {/* TODO icon is not showing up here... */}
                      <CreditCardInfo creditCard={order.credit_card_data}/>
                    </h6>
                  </div>
                  <div className="field">
                    <h6>Shipping Method: <span>Best Way ­Standard</span></h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 address">
                  <h6>Shipping Address</h6>
                  <InvoiceModalAddress
                      address={order.shipping_address_data}/>
                </div>
                <div className="col-md-6 address">
                  <h6>Billing Address</h6>
                  <InvoiceModalAddress
                      address={order.billing_address_data}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h6 className="centered">INVOICE SUMMARY</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="wrapper">
                    <h6>DESCRIPTION</h6>
                    <h6 className="right">AMOUNT</h6>
                  </div>
                  {
                    order.items.normal.map(orderItem => (
                        <InvoiceModalItem
                            item={orderItem.order_item_data}
                            type={NORMAL}
                            key={`accountInvoiceOrderItem${orderItem.order_item_data.id}`}/>
                    ))
                  }
                  {
                    order.items.returned.map(orderItem => (
                        <InvoiceModalItem
                            item={orderItem.order_item_data}
                            type={RETURNED}
                            key={`accountInvoiceOrderItem${orderItem.order_item_data.id}`}/>
                    ))
                  }
                  {
                    order.items.cancelled.map(orderItem => (
                        <InvoiceModalItem
                            item={orderItem.order_item_data}
                            type={CANCELLED}
                            key={`accountInvoiceOrderItem${orderItem.order_item_data.id}`}/>
                    ))
                  }
                </div>
              </div>
              <div className="row order-values">
                <div className="col-md-6 col-md-offset-6">
                  <OrderTotals
                      order={order}/>
                </div>
              </div>
              <div className="text-right modal-footer">
                <button className="btn-custom cancel"
                        type="button" onClick={onClose}>
                  CLOSE
                </button>
                <Link
                    to={`/plain-invoices/${order.uuid}`}
                    className="print-button"
                    target="_blank">
                  PRINT
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    )
  }
}

InvoiceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InvoiceModal
