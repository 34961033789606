import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from "react-router-dom";
import VintageAlert from "utils/components/VintageAlert/index";
import {FROM} from "modules/store/scenes/ProductsList/constants/query-params";
import CartTotalsContainer from "modules/store/scenes/Cart/components/CartTotals/container/index";
import {CART} from "modules/store/scenes/Cart/components/CartTotals/constants/contexts";
import CartItemContainer from "modules/store/scenes/Cart/components/CartItem/container";
import {CART_ITEM_IN_CHECKOUT} from "modules/store/scenes/Cart/components/CartItem/constants/contexts";
import TrustElements from "utils/components/TrustElements";
import CartCouponBoxContainer from "modules/store/scenes/Cart/components/CartCouponBox/container";
import "./styles/CheckoutSummary.scss";
import CartCouponBoxContext from "modules/store/scenes/Cart/components/CartCouponBox/contexts/CartCouponBoxContext";

class CheckoutSummary extends Component {
  render() {
    const {checkoutReady} = this.props;
    const {cart} = this.props.store;
    const {isAuthenticated} = this.props.auth;
    const {selectedShippingAddress} = this.props.account.pages.addressBook;

    return (
        <div className="summary checkout-summary">
          <h4>
            ORDER SUMMARY<Link to="/store/cart">Edit</Link>
          </h4>
          <hr/>
          {
            cart && cart.cart_items.map(cartItem =>
                <CartItemContainer
                    key={cartItem.id}
                    item={cartItem}
                    context={CART_ITEM_IN_CHECKOUT}/>
            )
          }
          <div className="checkout-summary__coupon-box">
            <CartCouponBoxContext.Provider value="compact">
              <CartCouponBoxContainer/>
            </CartCouponBoxContext.Provider>
          </div>
          {
            (cart !== null && cart.cart_items.length > 0) &&
            <CartTotalsContainer
                context={CART}
                shippingAddress={selectedShippingAddress}/>

          }
          {
            (!checkoutReady || !isAuthenticated) &&
            <VintageAlert>
              {
                !checkoutReady &&
                <p>
                  Please make sure you input your shipping and billing address as well as your credit card
                  information before being able to complete checkout process.
                </p>
              }
              {
                !isAuthenticated &&
                <p>
                  If you already have an account, please <Link to={{
                  pathname: '/auth/login',
                  search: `?${FROM}=${this.props.match.path}`
                }}>login here</Link>.
                </p>
              }
            </VintageAlert>
          }
          <br/>
          <hr/>
          <br/>
          <TrustElements direction="column"/>
        </div>
    )
  }
}

CheckoutSummary.propTypes = {
  checkoutReady: PropTypes.bool.isRequired
};

export default withRouter(CheckoutSummary)
