import {connect} from 'react-redux';
import AddCreditCardModal from "../";
import {
  closeCreditCardModal,
  createAddress,
  createAddressFailed,
  createCreditCard,
  editCreditCard,
  receiveAddresses
} from "modules/MyAccount/actions/index";
import {change} from "redux-form";
import {reset} from 'redux-form';

const mapStateToProps = (state) => {
  return {
    account: state.account,
    selectedCreditCard: state.account.pages.creditCards.selectedCreditCard
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeCreditCardModal: () => dispatch(closeCreditCardModal()),
    createCreditCard: (data) => dispatch(createCreditCard(data)),
    editCreditCard: (data) => dispatch(editCreditCard(data)),
    receiveAddresses: data => dispatch(receiveAddresses(data)),
    createAddress: data => dispatch(createAddress(data)),
    changeFieldValue: (field, value) => {
      dispatch(
          change('accountCreditCardForm', field, value)
      );
    },
    resetAddressForm: () => dispatch(
        change('accountCreditCardForm', 'address', {})
    ),
    resetCreditCardForm: () => dispatch(reset('accountCreditCardForm')),
    createAddressFailed: (data, response) => dispatch(createAddressFailed(data, response))
  };
};

const AddCreditCardModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCreditCardModal);

export default AddCreditCardModalContainer;