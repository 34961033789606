import { connect } from 'react-redux';
import ForgotPassword from "../";
import {resetPassword} from "modules/auth/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => {
      dispatch(
          resetPassword(data)
      )
    }
  };
};

const ForgotPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordContainer;