import React from "react";
import alert from "./img/alert.svg"
import "./styles/ValidateEmailExpired.scss"
import ValidateEmailButton from "modules/auth/scenes/ValidateEmailExpired/components/ValidateEmailButton"

export default function ValidateEmailExpired() {
  return (
      <div className="expired-link">
        <div className="text-center">
          <img src={alert} alt=""/>
          <h2>Expired Link</h2>
          <p>
            We’re sorry but the link has already expired.
            <br/>
            Click the button below to send a new link.
          </p>
          <ValidateEmailButton
              label="SEND EMAIL"
              loadingLabel="SENDING..."
              successLabel="EMAIL SENT!"/>
        </div>
      </div>
  )
}