import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {GIFT_CARDS} from "modules/store/scenes/ProductsList/constants/product-categories";
import "./styles/FooterCategories.scss";

export default function FooterCategories({categories}) {
  return (
      <ul className="footer-categories">
        {
          categories.map((productCategory, index) => (
              <li
                  key={`footerProductCategory${productCategory.slug}${index}`}>
                {
                  productCategory.name === GIFT_CARDS ? (
                      <Link
                          to={productCategory.to}
                          className="li-category">
                        {productCategory.name}
                      </Link>
                  ) : (
                      <Link
                          to={`/store/products?${productCategory.slug}=${productCategory.id}`}
                          className="li-category">
                        {productCategory.name}
                      </Link>
                  )
                }
              </li>
          ))
        }
      </ul>
  )
}

FooterCategories.propTypes = {
  categories: PropTypes.array.isRequired
}