import * as types from './types';
import {removeFromCart, getCart, removeCartSession} from "services/store";

export const receiveProductsRequest = () => ({
  type: types.PRODUCTS_RECEIVE_REQUEST
});

export const receiveProducts = (products) => ({
  type: types.PRODUCTS_RECEIVE,
  products
});

export const setProductsCount = (productsCount) => ({
  type: types.PRODUCTS_COUNT_SET,
  productsCount
});

export const receiveAdvancedSearch = (advancedSearch) => ({
  type: types.ADVANCED_SEARCH_SELECT,
  advancedSearch
});

export const fetchCartItemsRequest = () => ({
  type: types.CART_ITEMS_FETCH_REQUEST
});

export const fetchCartItemsSuccess = cart => ({
  type: types.CART_ITEMS_FETCH_SUCCESS,
  cart
});

export const fetchCartItemsFailed = error => ({
  type: types.CART_ITEMS_FETCH_ERROR,
  error
});

export function fetchCartItems(){
  return (dispatch) => {
    dispatch(fetchCartItemsRequest());
    return getCart()
        .then(response => dispatch(fetchCartItemsSuccess(response.data)))
        .catch(error => {
          removeCartSession();
          dispatch(fetchCartItemsFailed(error))
        });
  };
}

export const receiveCartSingleItem = (item) => ({
  type: types.CART_SINGLE_ITEM_RECEIVE,
  item
});

export const updateItemQuantity = (item, newQuantity) => ({
  type: types.CART_ITEM_UPDATE_QUANTITY,
  item,
  newQuantity
});

export const emptyCartItemStock = item => ({
  type: types.CART_ITEM_EMPTY_STOCK,
  item
});

export const deleteCartItemRequest = (cartItem) => ({
  type: types.CART_ITEM_DELETE_REQUEST,
  cartItem
});

export const deleteCartItemSuccess = (cartItem, response) => ({
  type: types.CART_ITEM_DELETE_SUCCESS,
  cartItem,
  response
});

export const deleteCartItemFailed = (cartItem, error) => ({
  type: types.CART_ITEM_DELETE_ERROR,
  cartItem,
  error
});

export const receiveCartTotals = totals => ({
  type: types.CART_TOTALS_RECEIVE,
  totals
});

export const receiveSearchByCategory = category => ({
  type: types.PRODUCTS_SEARCH_BY_CATEGORY,
  category
});

export function deleteCartItem(cartItem){
  return (dispatch) => {
    dispatch(deleteCartItemRequest());
    return removeFromCart(cartItem)
        .then(response => dispatch(deleteCartItemSuccess(cartItem, response)))
        .catch(error => dispatch(deleteCartItemFailed(cartItem, error)));
  };
}

export const receiveDiscountCode = discountCode => ({
  type: types.DISCOUNT_CODE_RECEIVE,
  discountCode
});

export const emptyCart = () => ({
  type: types.CART_EMPTY
});