import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import SocialButton from "./components/SocialButton";
import "./styles/AuthSocialButtons.scss";
import vintageAxios from "services/api/index";
import {saveUserInformation} from "services/auth";

class AuthSocialButtons extends Component {
  constructor() {
    super();

    this.state = {
      providers: [
        {
          provider: "facebook",
          appId: process.env.REACT_APP_FB_APP_ID,
          text: `Facebook`
        },
        {
          provider: "google",
          appId: process.env.REACT_APP_GOOGLE_APP_ID,
          text: `Google`
        }
      ]
    };

    this.handleSocialLogin = provider => this._handleSocialLogin.bind(this, provider);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
  }

  _handleSocialLogin(provider, user) {
    switch (provider) {
      case "google":
        provider = "google-oauth2";
        break;
      default:
    }

    this.socialLoginRequest(provider, user.token.accessToken).then(response => {
      this.props.onAfterSubmit(response)
    });
  };

  socialLoginRequest(provider, accessToken) {
    const url = `/auth/login/social/token_user/${provider}/`;
    const promise = vintageAxios.post(
        url,
        {
          code: accessToken,
          access_token: accessToken,
          provider
        }
    );
    promise.then(response => saveUserInformation(response));
    return promise
  }

  handleSocialLoginFailure(err) {
    console.error(err);
  };

  render() {
    const {providers} = this.state;
    const {verb} = this.props;

    return (
        <div className={classNames({
          "auth-social-buttons": true
        })}>
          <div className="row gutters-small">
            {
              providers.map((provider, index) => (
                  <div
                      className="col-md-6"
                      key={`socialButton${index}`}>
                    <SocialButton
                        provider={provider.provider}
                        className={`btn btn-block btn-rounded social-button social-button--${provider.provider}`}
                        appId={provider.appId}
                        onLoginSuccess={this.handleSocialLogin(provider.provider)}
                        onLoginFailure={this.handleSocialLoginFailure}>
                      <i className={`fa fa-${provider.provider} fa-fw`}/> {`${verb}${provider.text}`}
                    </SocialButton>
                  </div>
              ))
            }
          </div>
        </div>
    )
  }
}

AuthSocialButtons.propTypes = {
  verb: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func.isRequired
};

AuthSocialButtons.defaultProps = {
  verb: 'Login with '
};

export default AuthSocialButtons;
