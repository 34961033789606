import React, {useState} from "react"
import PropTypes from "prop-types"
import {useQuery} from "react-query"
import vintageAxios from "services/api"
import QueryInput from "modules/Locations/components/StoreFinder/components/QueryInput"
import "./styles/StoreFinder.scss"
import {NEW, USED} from "@bit/vintagestock.constants.conditions/conditions"

export default function StoreFinder({product, condition, children}) {
  const [query, setQuery] = useState('')
  // If I don't send miles, query won't work. Weird but that's how the API works...
  const [miles, setMiles] = useState(500)
  const params = {
    'page_size': 100  // all of them
  }
  if (query) params['search'] = query
  if (miles) params['miles'] = miles
  const url = product ? `/products/all/${product.slug}/` : '/misc/locations/'
  const searchKey = `${url}${miles}${query}`
  const {isLoading, data: locations} = useQuery(
      `locations${searchKey}`, () =>
          vintageAxios.get(url, {
            params
          })
              .then(response => {
                if (product && condition === NEW)
                  return response.data.stock_by_stores_new.length > 0 && response.data.stock_by_stores_new.map(({store}) => store)
                if (product && condition === USED)
                  return response.data.stock_by_stores_used.length > 0 && response.data.stock_by_stores_used.map(({store}) => store)
                return response.data.results
              })
  )

  /**
   * Handle form submit to avoid unexpected page reload
   * @param e {Object}
   */
  const handleSubmit = e => {
    e.preventDefault()
  }

  /**
   * Handles input change event
   * @param query {String}
   */
  const handleChange = query => setQuery(query)

  /**
   * Handles change of the miles <select/>
   * @param e {Object}
   */
  const handleMilesChange = e => setMiles(e.target.value)

  return (
      <div className="finder">
        <form
            className="store-search-form form form-inline"
            onSubmit={handleSubmit}>
          <p><strong>Enter your Address or Zipcode</strong></p>
          <div className="store-search-form__input">
            <QueryInput
                onChange={handleChange}/>
            <button
                disabled={isLoading}
                type="submit">
              {
                isLoading
                    ?
                    <i className="fa fa-spinner fa-spin"/>
                    :
                    <span className="icon-loup"/>
              }
            </button>
          </div>
          {/*<div className="row location__wrapper-button">
            <div className="col-md-7">
              <div className="form-group">
                <div className="form-field">
                  <select
                      value={miles}
                      name="location_mile"
                      className="form-control location-select-mile"
                      onChange={handleMilesChange}>
                    <option>
                      Select radius
                    </option>
                    {
                      LOCATIONS_MILE.map(mile => (
                          <option
                              key={`location_mile${mile}`}
                              value={mile}>
                            {mile} mile{mile > 1 ? 's' : ''}
                          </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group">
                <button
                    type="submit"
                    className="btn-custom">
                  SEARCH
                </button>
              </div>
            </div>
          </div>*/}
        </form>
        {
          children(locations, isLoading)
        }
      </div>
  )

}

StoreFinder.propTypes = {
  children: PropTypes.func.isRequired
}