import React from 'react';
import { Modal } from "react-bootstrap";
import NewsletterSubscribeForm from "components/NewsletterSubscribe/components/NewsletterSubscribeModal/components/NewsletterSubscribeForm";
import PropTypes from "prop-types";

const NewsletterSubscribeModal = ({newsletter, newsletterSubscribe, closeNewsLetterModal}) => (
    <Modal
        show={newsletter.modalIsOpen}
        onHide={closeNewsLetterModal}>
      <Modal.Header closeButton>
        <Modal.Title>Sign Up For Our Newsletter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewsletterSubscribeForm
            onSubmit={newsletterSubscribe}
            isLoading={newsletter.loading}
            errors={newsletter.errors}
            closeNewsLetterModal={closeNewsLetterModal}/>
      </Modal.Body>
    </Modal>
);

NewsletterSubscribeModal.propTypes = {
  newsletter: PropTypes.shape({
    modalIsOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
  }),
  newsletterSubscribe: PropTypes.func.isRequired,
  closeNewsLetterModal: PropTypes.func.isRequired
};

export default NewsletterSubscribeModal;