import React from 'react';
import PropTypes from 'prop-types';
import {QUERY_PARAM_CATEGORIES} from "modules/store/scenes/ProductsList/constants/query-params";
import {Link} from "react-router-dom";

import "./styles/FeaturedCategoryItem.scss";

const FeaturedCategoryItem = ({category, containerClassNames, iconClassNames}) => {
  return (
      <div className={`featured-category ${containerClassNames}`}>
        <Link
            to={`/store/products/?${QUERY_PARAM_CATEGORIES}=${category.id}`}>
          <div className="featured-category__icon-container">
            <span className={`featured-category__icon ${iconClassNames}`}/>
          </div>
          <h6 className="featured-category__title text-uppercase">{category.name}</h6>
        </Link>
      </div>
  );
};

FeaturedCategoryItem.propTypes = {
  category: PropTypes.object,
  containerClassNames: PropTypes.string.isRequired,
  iconClassNames: PropTypes.string.isRequired,
};

export default FeaturedCategoryItem;
