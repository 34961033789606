import * as types from "./types";

export const toggleProductsViewAs = viewAs => ({
  type: types.PRODUCTS_VIEW_AS_TOGGLE,
  viewAs
});

export const updatePageInProductsList = page => ({
  type: types.PRODUCTS_LIST_UPDATE_PAGE,
  page
});

export const receivePlatforms = platforms => ({
  type: types.RECEIVE_PLATFORMS,
  platforms
});

export const receivePrice = price => ({
  type: types.RECEIVE_PRICE,
  price
});

export const receivePageSize = pageSize => ({
  type: types.RECEIVE_PAGE_SIZE,
  pageSize
});