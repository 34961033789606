import * as types from "./types";

export const receiveOrders = orders => ({
  type: types.ORDERS_RECEIVE,
  orders
});

export const clearOrders = () => ({
  type: types.ORDERS_CLEAR
});

export const openModal = (name, order) => ({
  type: types.MODAL_OPEN,
  name,
  order
});

export const closeModal = name => ({
  type: types.MODAL_CLOSE,
  name
});

export const toggleOrderItemWillBeReturned = item => ({
  type: types.ORDER_ITEM_TOGGLE_RETURN_FLAG,
  item
});

export const updateSelectedOrder = order => ({
  type: types.SELECTED_ORDER_UPDATE,
  order
});