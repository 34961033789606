import {useCallback, useEffect, useState} from "react";
import {getWishListItems} from "services/store/wishlist";

/**
 * This component's main purpose is only to fetch the wishlist so it's accessible from the
 * redux store. This way, I can always check if a product is wish listed or not in the listings.
 * @param account {Object}
 * @param receiveWishListItems {Function}
 * @returns {null}
 * @constructor
 */
export default function FetchWishList({auth: {isAuthenticated}, account: {wishListItems}, receiveWishListItems}) {
  const [loading, setLoading] = useState(false)
  const memoizedFetch = useCallback(fetchWishListItems, [])

  useEffect(() => {
    if (isAuthenticated && wishListItems === null && !loading)
      memoizedFetch()
  }, [loading, isAuthenticated, wishListItems, memoizedFetch])

  /**
   * Fetch wishlist items from the api and set them in redux store.
   */
  function fetchWishListItems() {
    setLoading(true);
    const promise = getWishListItems();
    promise.then(response => {
      receiveWishListItems(response.data);
      setLoading(false);
    });
    promise.catch(() => setLoading(false));
  }

  return null;
}