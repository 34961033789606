import { connect } from 'react-redux';
import NewsletterSubscribe from "../";
import {openNewsLetterModal, resetNewsLetter} from "components/NewsletterSubscribe/actions";

const mapStateToProps = (state) => {
  return {
    newsletter: state.newsletter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openNewsLetterModal: () => dispatch(openNewsLetterModal()),
    resetNewsLetter: () => dispatch(resetNewsLetter())
  };
};

const NewsletterSubscribeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterSubscribe);

export default NewsletterSubscribeContainer;