import * as types from '../actions/types';

export const initialState = {
  loading: false,
  success: null,
  errors: []
};

export const contactUs = (state = initialState, action) => {
  switch(action.type){
    case types.CONTACT_MESSAGE_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CONTACT_MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case types.CONTACT_MESSAGE_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.response.data
      };
    default:
      return state;
  }
};