import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreditCardInfo from "components/CreditCardInfo";
import vintageAxios from "services/api/index";
import classNames from 'classnames/bind';

class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingAsDefault: false
    };

    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
    this.editCreditCard = this.editCreditCard.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
  }

  /**
   * Handale edit credit card
   */
  editCreditCard() {
    const {creditCard, openCreditCardModal, selectCreditCard} = this.props;
    openCreditCardModal();
    selectCreditCard(creditCard);
  }

  /**
   * Handle delete credit card
   */
  deleteCreditCard() {
    const {creditCard} = this.props;
    this.props.deleteCreditCard(creditCard);
  }

  /**
   * Set credit card as default in the API and update state.
   * @returns {AxiosPromise}
   */
  setAsDefault() {
    const {creditCard} = this.props;
    const promise = CreditCard.setCreditCardAsDefault(creditCard);
    promise.then(response => {
      this.props.setCreditCardAsDefault(response.data);
      this.setState({
        settingAsDefault: false
      });
    });
    return promise;
  }

  /**
   * Set credit card as default in the API.
   * @param creditCard
   * @returns {AxiosPromise<any>}
   */
  static setCreditCardAsDefault(creditCard) {
    const promise = vintageAxios.patch(`/management/customers/credit-cards/${creditCard.id}/`, {
      default: true
    });
    return promise;
  }

  /**
   * Handles clicks on the set as default button.
   */
  handleSetAsDefaultClick() {
    if (!this.state.settingAsDefault)
      this.setState({
        settingAsDefault: true
      }, () => {
        this.setAsDefault();
      })
  }

  render() {
    const {creditCard} = this.props;
    const {settingAsDefault} = this.state;

    return (
        <div className={classNames({
          "payment-item": true,
          "payment-item--default": !!creditCard.default
        })}>
          <div className="payment-item__credit-card">
            <p className="payment-item__credit-card--full-name">
              <span className="number">{creditCard.full_name}</span>
            </p>
            <p className="payment-item__credit-card--info">
              <CreditCardInfo creditCard={creditCard}/>
            </p>
            <p className="payment-item__credit-card--expires">
              <span className="exp">Expires:</span>
              <span>{creditCard.exp_mo}/{creditCard.exp_yr}</span>
            </p>
          </div>
          <hr/>
          <div className="payment-item__actions">
            <div className="payment-item__actions__edit payment-item__actions__hover">
              <span onClick={this.editCreditCard} >
                <i className="fa fa-pencil"/>Edit
              </span>
            </div>
            <div className="payment-item__actions__delete payment-item__actions__hover">
              <span onClick={this.deleteCreditCard}>
                <i className="fa fa-trash"/>Delete
              </span>
            </div>
            <div className="payment-item__actions__status">
              {
                creditCard.default ? (
                    <span className="payment-item__actions__status__default">Default</span>
                ) : (
                    settingAsDefault ? (
                        <span
                            className="payment-item__actions__status__setting">Setting...</span>
                    ) : (
                        <span
                            className="payment-item__actions__hover payment-item__actions__status__set-as-default"
                            onClick={this.handleSetAsDefaultClick}>
                          <i className="fa fa-check-square"/>Set as default
                        </span>
                    )
                )
              }
            </div>
          </div>
        </div>
    )
  }
}

CreditCard.propTypes = {
  creditCard: PropTypes.object.isRequired
};

export default CreditCard