import { connect } from 'react-redux';
import Header from "../";
import {receiveUser} from "modules/auth/actions";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    store: state.store
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveUser: user => dispatch(receiveUser(user))
  };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default HeaderContainer;