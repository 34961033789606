const validateResetPasswordForm = values => {
  const errors = {};

  if (!values.new_password)
    errors.new_password = 'This field is Required';

  if (!values.re_new_password)
    errors.re_new_password = 'This field is Required';
  else if (values.re_new_password !== values.new_password)
    errors.re_new_password = 'The two password fields didn\'t match.';

  return errors;
};

export default validateResetPasswordForm;