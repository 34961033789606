import React from 'react';

const GiftCardsPublicity = () => (
    <div className="gift-cards-publicity">
      <div className="gift-cards-publicity__content">
        <h1>GIFT CARDS</h1>
        <h2>A perfect gift for everyone!</h2>
      </div>
    </div>
);

export default GiftCardsPublicity;