import * as actionTypes from './types';

export const receiveCategories = (categories) => ({
  type: actionTypes.CATEGORIES_RECEIVE,
  categories
});

export const receiveTypes = (types) => ({
  type: actionTypes.TYPES_RECEIVE,
  types
});