import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import "./styles/TrackingModal.scss";
import OrderModalHeading from "modules/MyAccount/scenes/OrderHistory/components/OrderModalHeading/index";
import {
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_SHIPPED,
  SHIPMENT_STATUSES
} from "modules/MyAccount/scenes/OrderHistory/components/TrackingModal/constants/status";
import slugify from "utils/slugify";
import Moment from "react-moment";

class TrackingModal extends Component {
  /**
   * Get display value given a status.
   * @param {Number} status
   * @returns {String} status
   */
  static getStatusDisplay(status){
    for( let [key, value] of SHIPMENT_STATUSES )
      if( key === status ) return value;
  }

  render(){
    const {show, onClose} = this.props;
    const order = this.props.orderHistory.selectedOrder;
    const shipment = order.shipments.length > 0 ? order.shipments[0] : null;
    const shipmentSteps = [
      {
        name: "Shipped",
        current: shipment.status === SHIPMENT_STATUS_SHIPPED
      },
      {
        name: "In Transit",
        current: shipment.status === SHIPMENT_STATUS_IN_TRANSIT
      },
      {
        name: "Delivered",
        current: shipment.status === SHIPMENT_STATUS_DELIVERED
      },
    ];

    return(
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="invoice tracking-items">
          <Modal.Header closeButton>
            <Modal.Title>
              Packet tracking
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <OrderModalHeading
                  uuid={order.uuid}
                  date={order.created_at}/>
              <hr/>
              {
                shipment &&
                <div className="row">
                  <div className="col-md-12">
                    <div
                        className={`status ${slugify(TrackingModal.getStatusDisplay(shipment.status))}`}>
                      <span className="circle"/>
                      <span className="text">
                        {TrackingModal.getStatusDisplay(shipment.status)}
                      </span>
                    </div>
                    <p>
                      Your Package was delivered on
                      &nbsp;
                      <Moment format="MMMM D, YYYY h:mm a">
                        {shipment.created_at}
                      </Moment>
                    </p>
                    <div className="row status-line">
                      {
                        shipmentSteps.map((step, index) =>
                            <div
                                key={`shipmentStep${index}`}
                                className="col-md-4 column">
                              {
                                ((shipmentSteps[1].current && (index === 0 || index === 1)) ||
                                (shipmentSteps[2].current)) &&
                                <span className={classNames({
                                  "line": true,
                                  "line--middle-with": shipmentSteps[1].current && index === 1
                                })}/>
                              }
                              <div className="point">
                                <span className="circle">
                                  {
                                    step.current &&
                                    <span className="icon icon-check"/>
                                  }
                                </span>
                                <span className="text">{step.name}</span>
                              </div>
                            </div>
                        )
                      }
                    </div>
                    {
                      (shipment.url && shipment.url !== "") &&
                      <div>
                        <hr/>
                        <h6>Tracking Detail</h6>
                        <p>Please <a href={shipment.url} target="_blank" rel="noopener noreferrer">click here</a> to track your packet.</p>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </Modal.Body>
        </Modal>
    )
  }
}

TrackingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TrackingModal
