import React, {Component} from 'react';
import WishListItemContainer from "./components/WishListItem/container";
import {Link} from "react-router-dom";
import LoadingCassette from "components/LoadingCassette";
import {getWishListItems} from "services/store/wishlist";
import "./styles/Wishlist.scss";
import SEO from "components/SEO/index";
import WishListShareButton from "modules/MyAccount/scenes/Wishlist/components/WishlistShareButton/index";
import {withRouter} from "react-router";
import vintageAxios from "services/api/index";

export class Wishlist extends Component {
  componentDidMount() {
    this.fetchWishListItems();
  }

  fetchWishListItems() {
    const {location, match} = this.props;
    let promise;
    if (location.pathname === "/my-account/wishlist") {
      // Using wishlist service instead of vintage axios here for consistency
      promise = getWishListItems();
    } else
      promise = vintageAxios.get(`/management/wish-lists/${match.params.wishListId}/`);

    promise.then(response => {
      this.props.receiveWishListItems(location.pathname === "/my-account/wishlist"
          ? response.data
          : response.data.wish_list_items
      );
    });
  }

  render() {
    const {account, location} = this.props;

    return (
        <div>
          <div className="wrapper wishlist">
            <div className="wishlist__header">
              <h2 className="wishlist__header__title">
                <span className="icon icon-wishlist-book"/>
                <span className="text">Wish List</span>
              </h2>
              <WishListShareButton/>
            </div>
            {
              account.wishListItems === null ? (
                  <LoadingCassette/>
              ) : (
                  account.wishListItems.length > 0 ? (
                      account.wishListItems.map(function (item, index) {
                        let arr = [
                          <WishListItemContainer
                              readOnly={location.pathname !== "/my-account/wishlist"}
                              item={item}
                              key={`wishLists${item.id}`}/>
                        ];
                        if (index % 4 === 0) {
                          arr.splice(0, 0, <div className="clearfix" key={`separator${index}`}/>);
                        }
                        return arr;
                      })
                  ) : (
                      <div className="empty-message">
                        <h1>Your Wish List is Empty.</h1>
                        <p>Looks like you haven’t add any product to your Wish List yet.</p>
                        <div className="footer">
                          <div className="bg wish"/>
                          <p>
                            <Link to="/store/products">
                              <span className="btn-custom">GO TO STORE</span>
                            </Link>
                          </p>
                        </div>
                      </div>
                  )
              )
            }
          </div>
          <SEO url='my-account/wishlist'/>
        </div>
    )
  }
}

export default withRouter(Wishlist)
