import React, {Component} from 'react';
import NewsletterSubscribeModalContainer
  from "components/NewsletterSubscribe/components/NewsletterSubscribeModal/container";
import SubscribedModal from "components/NewsletterSubscribe/components/SubscribedModal/index";

import "./styles/NewsletterSubscribe.scss";

class NewsletterSubscribe extends Component {
  constructor() {
    super();

    this.state = {
      subscribedModalIsOpen: false,
      subscribedSuccess: null
    };

    this.closeSubscribedModal = this.closeSubscribedModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {modalIsOpen, success, errors} = this.props.newsletter;
    if (prevProps.newsletter.modalIsOpen && !modalIsOpen && (success || (errors.email && errors.email.length > 0))) {
      this.setState({
        subscribedModalIsOpen: true,
        subscribedSuccess: success
      });
      this.props.resetNewsLetter()
    }
  }

  closeSubscribedModal() {
    this.setState({subscribedModalIsOpen: false});
  }

  render() {
    const {openNewsLetterModal} = this.props;
    const {subscribedModalIsOpen, subscribedSuccess} = this.state;
    return (
        [
          <section key="newsletterSubscribeContainer"
                   className="subscribe-to-newsletter">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <h3 className="subscribe-to-newsletter__title">Sign Up For Free Special Saving Offers!</h3>
                  <p className="subscribe-to-newsletter__subtitle">Get exclusive savings offers, along with new product
                    releases!</p>
                  <div className="subscribe-to-newsletter__button_container">
                    <button onClick={openNewsLetterModal} className="subscribe-to-newsletter__button">SIGN ME UP!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>,
          <NewsletterSubscribeModalContainer
              key="newsletterSubscribeModal"/>,
          <SubscribedModal
              key="SubscribedModal"
              show={subscribedModalIsOpen}
              success={subscribedSuccess}
              onHide={this.closeSubscribedModal}/>
        ]
    )
  }
}

export default NewsletterSubscribe;
