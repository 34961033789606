import React from "react";
import _ from "lodash";
import {QUERY_PARAM_CATEGORIES, VIEW_TYPE} from "modules/store/scenes/ProductsList/constants/query-params";
import {
  COMING_SOON,
  COMING_SOON_SLUG,
  MOST_VIEWED,
  MOST_VIEWED_SLUG,
  NEW_THIS_WEEK,
  NEW_THIS_WEEK_SLUG
} from "modules/store/scenes/ProductsList/constants/product-categories";
import {Link, useLocation} from "react-router-dom";
import "./styles/CategoryTitle.scss";
import SEO from "components/SEO";
import {PRODUCTS_LIST_CONTEXT} from "components/SEO/constants/contexts";
import QueryParams from "utils/QueryParams";

export default function CategoryTitle({headerCategoriesReducer}) {
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const {categories} = headerCategoriesReducer;

  let title = null;
  const viewType = queryParams.find(VIEW_TYPE);
  const categoryId = queryParams.find(QUERY_PARAM_CATEGORIES);

  // View type possibilities
  if (!_.isUndefined(viewType))
    switch (viewType) {
      case NEW_THIS_WEEK_SLUG:
        title = NEW_THIS_WEEK;
        break;
      case MOST_VIEWED_SLUG:
        title = MOST_VIEWED;
        break;
      case COMING_SOON_SLUG:
        title = COMING_SOON;
        break;
      default:
        title = ''
    }

  // Category possibilities (it overrides view type, just in case)
  if (!_.isUndefined(categoryId) && !_.isUndefined(categories)) {
    const category = _.find(categories, {
      id: parseInt(categoryId)
    });
    if (category) {
      title = category.name;
    }
  }

  return (
      <React.Fragment>
        <div className="category-breadcrumb">
          <ol className="breadcrumb">
            {
              title
                  ?
                  <li>
                    <Link to="/store/products">Products</Link>
                  </li>
                  :
                  <li className="active">
                    Products
                  </li>
            }
            {
              title &&
              <li className="active">{title}</li>
            }
          </ol>
        </div>
        <div className="category-title">
          <h1>
            {title || "Products List"}
          </h1>
        </div>
        <SEO url='store/products'
             context={PRODUCTS_LIST_CONTEXT}
             information={{
               title: title ? `${title} | Products` : "Products List"
             }}/>
      </React.Fragment>
  );
}