import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import NotifyMeWhenAvailableFormContainer from "utils/components/NotifyMeWhenAvailableButton/components/NotifyMeWhenAvailableForm/container/index";
import vintageAxios from "services/api/index";

class NotifyMeWhenAvailableModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Handle submit notification form via email
   * @param {Object} data
   */
  handleSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      let subscribeToProduct = data;
      subscribeToProduct.condition = this.props.condition;
      subscribeToProduct.product = this.props.productId;
      this._requestForSubscribe(subscribeToProduct);
    });
  }

  /**
   * Subscribe for notification available product via email
   * @param {Object} data
   * @return {AxiosPromise<any>}
   * @private
   */
  _requestForSubscribe(data) {
    const promise = vintageAxios.post('/management/notify-product-stock/', data);
    promise.then(() => {
      this.setState({
        status: SUCCESSFUL_STATUS
      }, () => {
        setTimeout(() => {
          this.setState({
            status: INITIAL_STATUS,
            errors: [],
          });
          this.props.closeNotifyMeWhenAvailableModal();
        }, 2000);
      });
    });
    return promise;
  }

  render() {
    const {status} = this.state;
    const {notifyMeWhenAvailableModalIsOpen, closeNotifyMeWhenAvailableModal} = this.props;

    return (
        <Modal
            show={notifyMeWhenAvailableModalIsOpen}
            onHide={closeNotifyMeWhenAvailableModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Notify me via email when available
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NotifyMeWhenAvailableFormContainer
                status={status}
                onSubmit={this.handleSubmit}/>
          </Modal.Body>
        </Modal>
    )
  }
}

NotifyMeWhenAvailableModal.propTypes = {
  notifyMeWhenAvailableModalIsOpen: PropTypes.bool.isRequired,
  closeNotifyMeWhenAvailableModal: PropTypes.func.isRequired,
  condition: PropTypes.oneOf([0, 1]), // 0: new, 1: used
  productId: PropTypes.number.isRequired // Product to subscribe
};

export default NotifyMeWhenAvailableModal;